/*
 * File: Checkout.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Cart, SchemaScript
} from '../../../components';

// Step Components
import { Confirmation } from './Confirmation';
import { PaymentDetails } from './PaymentDetails';
import { PersonalDetails } from './PersonalDetails';
import { Review } from './Review';
import { ShippingDetails } from './ShippingDetails';

// Styles
import * as S from './Checkout.styles';


/**
 * Create Stripe Element
 */

let stripePromise = null;


/**
 * Component
 */

const Checkout = ({ meta, locale, merchant }) => {

	// Use hooks
	const navigate = useNavigate();
	const location = useLocation();

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Get current check out flow
	const currentFlow = useSelector((state) => state.checkout.value);

	// Create reference for step components
	const [confirmationStepHeight, setConfirmationStepHeight] = useState(0);
	const [paymentDetailsStepHeight, setPaymentDetailsStepHeight] = useState(0);
	const [personalDetailsStepHeight, setPersonalDetailsStepHeight] = useState(0);
	const [reviewStepHeight, setReviewStepHeight] = useState(0);
	const [shippingDetailsStepHeight, setShippingDetailsStepHeight] = useState(0);
	const [allowEmptyCart, setAllowEmptyCart] = useState(false);
	const [staticCart] = useState(cart);
	const confirmationStepRef = useRef();
	const paymentDetailsStepRef = useRef();
	const personalDetailsStepRef = useRef();
	const reviewStepRef = useRef();
	const shippingDetailsStepRef = useRef();

	// Get state handlers
	const [currentStep, setCurrentStep] = useState(currentFlow.currentStep || 1);
	const [shouldUpdateHeight, setShouldUpdateHeight] = useState(true);

	// Handle update step
	const updateStep = (step) => {

		// Push new history step if necesssary
		if (step > currentStep) {
			navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: step } });
		} else {
			navigate(-1);
		}
	};

	// Handle actions on component load
	useEffect(() => {
		if (shouldUpdateHeight) {

			// Set component heights
			setConfirmationStepHeight(confirmationStepRef.current.clientHeight);
			setPaymentDetailsStepHeight(paymentDetailsStepRef.current.clientHeight);
			setPersonalDetailsStepHeight(personalDetailsStepRef.current.clientHeight);
			setReviewStepHeight(reviewStepRef.current.clientHeight);
			setShippingDetailsStepHeight(shippingDetailsStepRef.current.clientHeight);

			// Update state
			setShouldUpdateHeight(false);
		}
	}, [shouldUpdateHeight]);

	// Handle actions on window resize
	useWindowResize(() => {

		// Set component heights
		setConfirmationStepHeight(confirmationStepRef.current.clientHeight);
		setPaymentDetailsStepHeight(paymentDetailsStepRef.current.clientHeight);
		setPersonalDetailsStepHeight(personalDetailsStepRef.current.clientHeight);
		setReviewStepHeight(reviewStepRef.current.clientHeight);
		setShippingDetailsStepHeight(shippingDetailsStepRef.current.clientHeight);
	});

	// Handle actions on component load
	useEffect(() => {

		// Update current step
		setCurrentStep(location?.state?.currentStep);

	}, [location]);

	// Handle actions on component load
	useEffect(() => {

		// Load Stripe
		stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY, {
			stripeAccount: stateMerchant.stripe.connectAccountId
		});

		// Get current step
		const step = currentFlow.currentStep || 1;

		// Set initialize history entry
		navigate(`${locale.localePath}/checkout`, { replace: true, state: { currentStep: 1 } });

		// Add history for current step
		if (step > 1) {
			switch (step) {
				case 2:
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 2 } });
					break;
				case 3:
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 2 } });
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 3 } });
					break;
				case 4:
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 2 } });
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 3 } });
					navigate(`${locale.localePath}/checkout`, { replace: false, state: { currentStep: 4 } });
					break;
				default:
					break;
			}
		}
	}, []);

	// Get step height for step component
	const getStepHeight = () => {
		if (shouldUpdateHeight) return 0;
		switch (currentStep) {
			case 1:
				return personalDetailsStepHeight;
			case 2:
				return shippingDetailsStepHeight;
			case 3:
				return paymentDetailsStepHeight;
			case 4:
				return reviewStepHeight;
			case 5:
				return confirmationStepHeight;
			default:
				return 0;
		}
	};

	// Handle allow empty cart
	const allowEmptyCartHandler = () => {
		setAllowEmptyCart(true);
	};

	// Redirect to cart if necessary
	if (!allowEmptyCart && (cart == null || cart.products.length === 0)) {
		navigate(`${locale.localePath}/cart`);
	}

	// Create merchant domain
	let merchantDomain = `https://${stateMerchant.domain}`;
	if (stateMerchant.redirectToSubdomain) {
		merchantDomain = `https://${stateMerchant.redirectToSubdomain}.${stateMerchant.domain}`;
	}

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org/',
				'@type': 'CheckoutPage',
				'@id': `${merchantDomain}/checkout/#WebPage`,
				name: `${stateMerchant.name} Wholesale Checkout`,
				description: meta.description,
				url: `${merchantDomain}/checkout`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${merchantDomain}/#WebSite`
				},
				publisher: {
					'@id': `${stateMerchant.website}/#Organization`,
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation merchant={stateMerchant} isLarge showCart={false}>
				<S.Wrapper>
					<S.StepContainer className="animate" stepHeight={getStepHeight()}>
						<S.Step className={currentStep === 1 ? 'animate show' : 'animate'}>
							<PersonalDetails
								ref={personalDetailsStepRef}
								updateStep={updateStep}
								updateHeight={setShouldUpdateHeight}
								isVisible={currentStep === 1}
								merchant={stateMerchant}
							/>
						</S.Step>
						<S.Step className={currentStep === 2 ? 'animate show' : 'animate'}>
							<ShippingDetails
								ref={shippingDetailsStepRef}
								updateStep={updateStep}
								updateHeight={setShouldUpdateHeight}
								isVisible={currentStep === 2}
								merchant={stateMerchant}
							/>
						</S.Step>
						<S.Step className={currentStep === 3 ? 'animate show' : 'animate'}>
							<Elements
								stripe={stripePromise}
								options={{
									fonts: [{
										cssSrc: 'https://fonts.googleapis.com/css?family=Lexend:wght@200;300;400;500;600;700;800;900&display=swap'
									}]
								}}
							>
								<PaymentDetails
									ref={paymentDetailsStepRef}
									updateStep={updateStep}
									updateHeight={setShouldUpdateHeight}
									isVisible={currentStep === 3}
									merchant={stateMerchant}
								/>
							</Elements>
						</S.Step>
						<S.Step className={currentStep === 4 ? 'animate show' : 'animate'}>
							<Review
								ref={reviewStepRef}
								updateStep={updateStep}
								updateHeight={setShouldUpdateHeight}
								allowEmptyCart={allowEmptyCartHandler}
								isVisible={currentStep === 4}
								merchant={stateMerchant}
							/>
						</S.Step>
						<S.Step className={currentStep === 5 ? 'animate show' : 'animate'}>
							<Confirmation
								ref={confirmationStepRef}
								updateStep={updateStep}
								updateHeight={setShouldUpdateHeight}
								isVisible={currentStep === 5}
								merchant={stateMerchant}
							/>
						</S.Step>
					</S.StepContainer>
					<S.CartContainer>
						<S.CartInner>
							<Cart data={{ cart: staticCart }} isSmall showNotices={false} showTitle={false} allowActions={false} merchant={stateMerchant} showAdditionalCosts />
						</S.CartInner>
					</S.CartContainer>
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Checkout.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Checkout.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Checkout;
