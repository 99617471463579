/*
 * File: MarketingNavigation.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 24, 2022 at 11:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Padding } from '../Padding';

// Constants
import { AppNavSidebarWidth, AppNavTopbarHeight } from '../../styles/constants';


/**
 * Styles
 */

export const Navigation = styled.div`
	display: flex;
	height: auto !important;
	min-height: 100%;
	flex-direction: column;
	margin-top: ${AppNavTopbarHeight}px;
`;

export const Wrapper = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	flex-grow: 1;
`;

export const Content = styled.div`
	flex: 1;
  	display: flex;
	overflow: auto;
	overflow-x: hidden;
	padding-top: 0px;
	flex-direction: column;
`;

export const ContentPadding = styled(Padding)`
	display: flex;
	flex-grow: 1;
`;

export const NavigationOverlay = styled.div`
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 99999;
	overflow: hidden;
	pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};

	.leftSidebar {
		transition: left 0.6s;
		-webkit-transition: left 0.6s;
		-moz-transition: left 0.6s;
		-o-transition: left 0.6s;
		left: ${({ isOpen }) => (isOpen ? '0px' : `-${AppNavSidebarWidth}px`)};
		position: fixed;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const Overlay = styled.div`
	background-color: black;
	opacity: ${({ isOpen }) => (isOpen ? 0.6 : 0)};
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
`;
