/*
 * File: NotificationSettings.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 27, 2022 at 7:54 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Toggle as ToggleBase } from '../Toggle';

// Constants
import { mediumBreakpoint } from '../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div``;

export const NotificationSection = styled.div`
	display: flex;
	width: 100%;
	gap: 30px;
	flex-direction: column;
	padding: 0px 0px 10px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
	&:not(:first-child) {
		border-top: 1px solid ${({ theme }) => theme.layoutBorder};
		padding-top: 45px;
	}
`;

export const TitleSection = styled.div`
	flex-grow: 1;

	h4 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ToggleSection = styled.div`
	flex-shrink: 0;
`;

export const OptionRow = styled.div`
	display: flex;
	width: 100%;
	margin-top: 8px;
	margin-bottom: 30px;
	gap: 15px;
`;

export const Description = styled.div`
	* {
		text-align: left;
		margin: 0px !important;
	}
	p {
		color: ${({ theme }) => theme.primaryText};
	}
	.subtitle {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Toggle = styled(ToggleBase)`
	position: relative;
	margin-top: 2px;
`;
