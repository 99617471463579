/*
 * File: Home.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';
import Confetti from 'react-confetti';
import { useWindowResize } from 'beautiful-react-hooks';
import { useNavigate } from 'react-router-dom';

// Utilities
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { getCDNURL } from '../../../../utilities/utilities';
import { createStateLocale } from '../../../utilities/locale';

// Services
import { submitInquiry } from '../../../services/inquiry';

// Components
import {
	Meta, MarketingNavigation, Typography, TextInput, TextArea, Button, Emoji
} from '../../../components';

// Styles
import * as S from './Home.styles';


/**
 * Component
 */

const Home = ({ meta, locale, merchant }) => {

	// Use hooks
	const navigate = useNavigate();

	// Get CDN URL
	const CDNURL = getCDNURL(merchant);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);
	const [runConfetti, setRunConfetti] = useState(false);
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [companyError, setCompanyError] = useState(null);
	const [productDescriptionError, setProductDescriptionError] = useState(null);
	const [inputValues, setInputValues] = useState({});

	// Initialize window parameters
	const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 500;
	const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 500;

	// Set state
	const [width, setWidth] = useState(windowWidth);
	const [height, setHeight] = useState(windowHeight);

	// Handle submit action
	const handleSubmitInquiry = async () => {

		// Get parameters
		const {
			name, email, company, productDescription
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email' });
			return;
		}
		if (!company || validator.isEmpty(company, { ignore_whitespace: true })) {
			setCompanyError({ message: 'Please enter your company name' });
			return;
		}
		if (!productDescription || validator.isEmpty(productDescription, { ignore_whitespace: true })) {
			setProductDescriptionError({ message: 'Please tell us a little about your products' });
			return;
		}

		// Update submission state
		setIsSubmitting(true);

		// Submit inquiry
		try {

			// Perform inquiry submission
			await submitInquiry({
				name, email, company, productDescription
			}, stateMerchant);

			// Update submission state
			setIsSubmitting(false);

			// Clear form
			setInputValues({});

			// Show confetti
			setShowConfetti(true);
			setRunConfetti(true);

			// Set timer to hide confetti
			setTimeout(() => {
				setRunConfetti(false);
			}, 6000);

			// Show confirmation message
			toastSuccess(uiMode, stateMerchant, 'Thanks for checking out Seesaw Marketplace! We\'ve received your message and one our team members will be in touch.', { duration: 8000 });

		} catch (e) {

			// Update submission state
			setIsSubmitting(false);

			// Show error message
			toastError(uiMode, stateMerchant, 'We\'re having trouble submitting your contact info. Give it another go.');
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle actions on window resize
	useWindowResize(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	});

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation merchant={stateMerchant}>
				<S.Wrapper>

					{/* Hero Section */}
					<S.HeroSection>

						{/* Content */}
						<S.ContentContainer>
							<Typography tag="h1" weight="bold">Sell Your Wholesale Products In Person With Seesaw Marketplace</Typography>
							<Typography tag="p" weight="light">Seesaw Marketplace is the easiest way to take in-person wholesale orders from your customers at markets, trade shows, and more.</Typography>
							<Button
								size="large"
								onClick={() => {
									navigate('/#contact');
								}}
							>
								Get started
							</Button>
						</S.ContentContainer>

						{/* Device Holder */}
						<div className="devicesHolder devicesHolderHero">
							<div className="tabletLandscape">
								<img alt="Seesaw Marketplace Tablet" src={`${CDNURL}/public/assets/mockups/tablet-landscape-hero.png`} width="420px" height="338px" />
							</div>
							<div className="phoneSmall">
								<img alt="Seesaw Marketplace Mobile" src={`${CDNURL}/public/assets/mockups/phone-small-hero.png`} width="211px" height="375px" />
							</div>
							<div className="tabletPortrait">
								<img alt="Seesaw Marketplace Tablet" src={`${CDNURL}/public/assets/mockups/tablet-portrait-hero.png`} width="436px" height="583" />
							</div>
						</div>

					</S.HeroSection>

					{/* Feature Section */}
					<S.FeatureSection id="features">

						{/* Content */}
						<S.ContentContainer>
							<Typography tag="h4">Features</Typography>
							<Typography tag="h2" weight="bold">Your One-Stop Shop</Typography>
							<Typography tag="p" weight="light">Complete customer orders on any device, in just a few seconds. Capture shipping information, scan your customer&apos;s credit card, and even send an email invoice with just a few taps.</Typography>
						</S.ContentContainer>

						{/* Features */}
						<S.FeatureContainer>
							<S.Feature>
								<Emoji symbol="👀" label="look" size={2.5} />
								<Typography tag="p" weight="light">Create a virtual lookbook. Available on any device.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="💳" label="card" size={2.5} />
								<Typography tag="p" weight="light">Scan credit cards. No hardware required.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="📨" label="email" size={2.5} />
								<Typography tag="p" weight="light">Send email invoices with just one tap.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="🛒" label="cart" size={2.5} />
								<Typography tag="p" weight="light">Manage a cart for each customer.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="🛍" label="shopping" size={2.5} />
								<Typography tag="p" weight="light">Create market exclusives for each event.</Typography>
							</S.Feature>
							<S.Feature>
								<Emoji symbol="📦" label="shipping" size={2.5} />
								<Typography tag="p" weight="light">Create custom shipping rates for your products.</Typography>
							</S.Feature>
						</S.FeatureContainer>
					</S.FeatureSection>

					{/* Cost Section */}
					<S.FeatureSection id="pricing">

						{/* Content */}
						<S.FeatureContentContainer>

							{/* Title */}
							<Typography tag="h4">Pricing</Typography>

							{/* Pricing */}
							<S.HighlightContainer>
								<S.PricingContainer>
									<Typography className="percentage" weight="extralight">5%</Typography>
									<Typography>commission</Typography>
								</S.PricingContainer>
								<Typography tag="h3">On orders. That&apos;s it.</Typography>
								<Typography tag="p" weight="light">It&apos;s completely free to get started. We&apos;ll even help you set up your virtual storefront.</Typography>
							</S.HighlightContainer>
						</S.FeatureContentContainer>
					</S.FeatureSection>

					{/* Demo Section */}
					<S.FeatureSection className="marketBackground" id="about">

						{/* Content */}
						<S.FeatureContentContainer>
							<Typography tag="h4">In Action</Typography>
							<Typography tag="h2" weight="bold">Everything you need to complete orders. In one place.</Typography>
							<Typography tag="p" weight="light">Explore this short demo of our checkout flow.</Typography>
						</S.FeatureContentContainer>

						{/* Demo */}
						<S.MarketBackground CDNURL={CDNURL}>
							<div className="devicesHolder">
								<div className="tabletPortrait centered">
									<S.Overlay />
									<S.Video
										playsInline
										autoPlay
										loop
										muted
									>
										<source src="https://d3903jqmkgenm7.cloudfront.net/video/seesaw-marketplace-demo.mp4" type="video/mp4" />
									</S.Video>
								</div>
							</div>
						</S.MarketBackground>
					</S.FeatureSection>

					{/* Get Started Section */}
					<S.FeatureSection id="contact">

						{/* Content */}
						<S.FeatureContentContainer>
							<Typography tag="h2" weight="bold">Interested in a closer look?</Typography>
							<Typography tag="p" weight="light">We just need a bit of information to get started. Fill out the form below and we&apos;ll be in touch.</Typography>
						</S.FeatureContentContainer>

						{/* Contact Form */}
						<S.FormContainer>
							<S.Form>

								{/* Inputs */}
								<TextInput
									label="Your name"
									placeholder="e.g. Han Solo"
									name="name"
									type="text"
									error={nameError}
									value={inputValues.name || ''}
									containerClassName="formInput"
									onFocus={() => { setNameError(null); }}
									onKeyUp={() => { setNameError(null); }}
									onBlur={() => { setNameError(null); }}
									onChange={handleOnChange}
									autocomplete="off"
									autocorrect="off"
								/>
								<TextInput
									label="Your email"
									name="email"
									type="email"
									placeholder="e.g. hello@acme.com"
									error={emailError}
									value={inputValues.email || ''}
									containerClassName="formInput"
									onFocus={() => { setEmailError(null); }}
									onKeyUp={() => { setEmailError(null); }}
									onBlur={() => { setEmailError(null); }}
									onChange={handleOnChange}
									autocorrect="off"
									autocapitalize="off"
									spellcheck="false"
								/>
								<TextInput
									label="Your company name"
									placeholder="e.g. ACME Inc"
									name="company"
									type="text"
									error={companyError}
									value={inputValues.company || ''}
									containerClassName="formInput"
									onFocus={() => { setCompanyError(null); }}
									onKeyUp={() => { setCompanyError(null); }}
									onBlur={() => { setCompanyError(null); }}
									onChange={handleOnChange}
									autocomplete="off"
									autocorrect="off"
								/>
								<TextArea
									label="What do you sell?"
									placeholder="Tell us a little about your products."
									name="productDescription"
									type="text"
									rows={6}
									error={productDescriptionError}
									value={inputValues.productDescription || ''}
									containerClassName="formInput"
									onFocus={() => { setProductDescriptionError(null); }}
									onKeyUp={() => { setProductDescriptionError(null); }}
									onBlur={() => { setProductDescriptionError(null); }}
									onChange={handleOnChange}
								/>

								{/* Actions */}
								<S.Button size="large" onClick={handleSubmitInquiry} merchant={merchant} disabled={isSubmitting}>
									<Typography variation="button-small" weight="medium">SUBMIT</Typography>
								</S.Button>
							</S.Form>
						</S.FormContainer>

						{/* Confetti */}
						{showConfetti && (
							<Confetti
								style={{
									zIndex: 9999999,
									position: 'fixed',
									width: '100%',
									height: '100%'
								}}
								width={width}
								height={height}
								gravity={0.05}
								numberOfPieces={runConfetti ? 200 : 0}
							/>
						)}

					</S.FeatureSection>
				</S.Wrapper>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

Home.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Home.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Home;
