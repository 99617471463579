/*
 * File: Checkout.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
		gap: 40px;
	}
`;

export const StepContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: ${({ stepHeight }) => (stepHeight != null && stepHeight > 0 ? `${stepHeight}px` : 'auto')};

	.formInput {
		margin: 25px 0 0;
	}
`;

export const Step = styled.div`
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 0px;
	margin: 0 auto;
	pointer-events: none;
	width: 100%;
	z-index: 1;

	&.show {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.3s;
		position: relative;
		z-index: 2;
		pointer-events: initial;
	}
	.stepSpacing {
		padding-top: 35px;
		padding-bottom: 35px;
	}
`;

export const CartContainer = styled.div`
	display: block;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: block;
		border-left: ${({ theme }) => `1px solid ${theme.layoutBorder}`};
		padding-left: 40px;
	}
`;

export const CartInner = styled.div`
	margin: 50px 0 0 0;
`;

export const Spacer = styled.div`
	height: 35px;
`;
