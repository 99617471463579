/*
 * File: PageHeader.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 26, 2022 at 9:16 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	padding: ${({ isSmall }) => (!isSmall ? '50px 0 20px' : '10px 0 20px')};

	* {
		text-align: left;
	}
	h4,
	h5 {
		color: ${({ theme }) => theme.secondaryText};
		opacity: 0.6;
	}
`;

export const Icon = styled.div``;
