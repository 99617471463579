/*
 * File: ContactPreferences.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:58 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 27, 2022 at 7:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const Form = styled.form`
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;

	@media only screen {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@media only screen and (min-width: 40em) {
		padding-top: 80px;
		padding-bottom: 50px;
	}
	h2 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		margin: 10px 0px 10px;
	}
	p {
		text-align: left;
		color: ${({ theme }) => theme.secondaryText};
   		margin-bottom: 20px;
		max-width: 600px;
		width: 100%;
	}
	button {
		width: 100%;
	}
`;

export const SettingsWrapper = styled.div`
	margin-top: 40px;
	padding-top: 40px;
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};
`;
