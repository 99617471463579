/*
 * File: Modal.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 21, 2022 at 12:37 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 4:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Modal.styles';
import { Padding } from '../Padding';


/**
 * Component
 */

export const Modal = ({
	isOpen, variant, showOverlay, handleClose, showClose, className, children, useWrapper
}) => (
	<S.ModalContainer isOpen={isOpen} showOverlay={showOverlay} className={isOpen ? 'show-modal' : undefined}>
		<S.Modal className={className} variant={variant}>

			{/* Close Button */}
			{showOverlay && showClose && <S.CloseButton icon={['fa', 'times']} size={1.1} onClick={() => { handleClose(); }} />}

			{/* Modal Content */}
			{useWrapper ? (
				<S.ModalWrapper>
					<Padding left right>
						{children}
					</Padding>
				</S.ModalWrapper>
			) : children}
		</S.Modal>
	</S.ModalContainer>
);


/**
 * Configuration
 */

Modal.displayName = 'Modal';
Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	variant: PropTypes.oneOf(['standard', 'large', 'full']),
	showOverlay: PropTypes.bool,
	isOpen: PropTypes.bool,
	showClose: PropTypes.bool,
	handleClose: PropTypes.func,
	className: PropTypes.string,
	useWrapper: PropTypes.bool
};
Modal.defaultProps = {
	children: null,
	variant: 'standard',
	showOverlay: true,
	isOpen: false,
	showClose: true,
	handleClose: null,
	className: null,
	useWrapper: false
};
