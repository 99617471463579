/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:35 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 4:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import componentReducer from './slices/component/component.slice';
import localeReducer from './slices/locale/locale.slice';
import consentReducer from './slices/consent/consent.slice';
import cartReducer from './slices/cart/cart.slice';
import checkoutReducer from './slices/checkout/checkout.slice';
import uiReducer from './slices/ui/ui.slice';
import merchantReducer from './slices/merchant/merchant.slice';


/**
 * Store
 */

export default configureStore({
	reducer: {
		component: componentReducer,
		locale: localeReducer,
		consent: consentReducer,
		cart: cartReducer,
		checkout: checkoutReducer,
		ui: uiReducer,
		merchant: merchantReducer
	},
});
