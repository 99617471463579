/*
 * File: merchant.config.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 20, 2022 at 3:54 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 27, 2023 at 11:01 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Core Merchant
 */

export const CORE = {
	id: 'merchant_core',
	isCore: true,
	redirectToSubdomain: 'www',
	domain: 'seesawmarketplace.com',
	name: 'Seesaw Marketplace',
	description: 'Seesaw Marketplace is the easiest way to take in-person wholesale orders from your customers at markets, trade shows, and more.',
	website: 'https://www.seesawmarketplace.com',
	email: 'platform@seesawmarketplace.com',
	logo: 'seesaw-logo-vertical.png',
	logoMark: 'seesaw-logo-mark.png',
	favicon: 'favicon.ico',
	mobileIcon: 'icon512.png',
	socialCover: 'social-cover.png',
	colors: {
		brandPrimaryBase: '#FD6D4E',
		brandPrimaryLighter: '#FFF0ED',
		brandBackground: '#FFFFFF',
	},
	twitter: {
		handle: ''
	},
	facebook: {
		url: ''
	},
	entities: [
		'https://www.seesawtravel.com',
		'https://www.facebook.com/joinseesaw',
		'https://twitter.com/joinseesaw',
		'https://www.instagram.com/joinseesaw/',
		'https://www.tiktok.com/@joinseesaw',
		'https://www.linkedin.com/company/seesawtravel/'
	],
};


/**
 * Merchants
 */

export const MERCHANTS = [
	{
		id: 'merchant_700_rivers',
		isCore: false,
		domain: '700rivers.seesawmarketplace.com',
		name: '700 Rivers',
		description: '700 Rivers is a global, ethical, and sustainable lifestyle brand that creates all-natural products that allow us to be kind to ourselves, kind to others, and kind to our planet. Our mission is to create high-quality, eco-friendly products that employ women across the world that are guaranteed fair, living wages for their work, a safe work environment, and are treated with dignity and respect.',
		productPitch: 'Our 700 Rivers soaps are hand-crafted by Bangladeshi artisans who have escaped human trafficking and are now steadily employed with dignified work. When you use a 700 Rivers soap bar, you can get a fresh start to your day while helping our Bangladeshi artisans get a fresh start in life. We at 700 Rivers provide our artisans with fair wages, a dignified career, and a safe work environment.',
		website: 'https://www.sevenhundredrivers.com',
		email: 'hello@sevenhundredrivers.com',
		logo: '700-rivers-logo-vertical.png',
		logoMark: '700-rivers-logo-mark.png',
		favicon: '700-rivers-favicon.ico',
		mobileIcon: '700-rivers-icon512.png',
		socialCover: '700-rivers-social-cover.png',
		colors: {
			brandPrimaryBase: '#e8bb3b',
			brandPrimaryLighter: '#e8bb3b',
			brandBackground: '#faf9f6',
		},
		notices: [],
		collectionOptions: [
			{
				id: 'soap_bar',
				name: 'Fresh Start Soaps',
				description: 'Our all-natural soaps are handmade by women in Bangladesh that been granted a fresh start at life after escaping human trafficking.'
			},
			{
				id: 'bath_bomb',
				name: 'Bath Bombs',
				description: 'Treat yourself to a day of well-being and enjoy the spa-like experience of a 700 Rivers Bath Bomb.'
			},
			{
				id: 'candle',
				name: 'Shine Bright Candles',
				description: 'Shine bright and spread the light with 700 Rivers candles! Focus on mindfulness and self-care with your choice of our 700 Rivers signature scents.'
			},
			{
				id: 'groups',
				name: 'Starter Kits',
				description: 'Not sure where to start? Choose from a starter pack of our customers\' favorites.'
			}
		],
		additionalCostRules: [
			{
				id: 'shipping',
				name: 'Shipping',
				cost: 14.50,
				rule: {
					type: 'less_than',
					value: 250
				}
			}
		],
		navigation: {
			primary: [
				{
					title: 'Home',
					url: 'https://www.sevenhundredrivers.com'
				},
				{
					title: 'Shop',
					url: '/',
					action: 'close'
				},
				{
					title: 'Our Mission',
					url: 'https://www.sevenhundredrivers.com/pages/about'
				},
				{
					title: 'Blog',
					url: 'https://www.sevenhundredrivers.com/blogs/news'
				},
				{
					title: 'Follow Up',
					url: '/followup'
				}
			],
			secondary: [
				{
					id: 1,
					items: [
						{
							title: 'Our Collections',
							url: '/',
							action: 'close'
						},
						{
							title: 'Place an Order',
							url: '/cart',
							action: 'close'
						},
						{
							title: 'How It\'s Made',
							url: 'https://www.sevenhundredrivers.com/pages/supply-chain'
						},
						{
							title: 'Contact Us',
							url: 'https://www.sevenhundredrivers.com/pages/contact'
						}
					]
				},
				{
					id: 2,
					items: [
						{
							title: 'For Retailers',
							url: 'https://www.sevenhundredrivers.com/pages/retailers'
						},
						{
							title: 'Brand & Presskit',
							url: 'https://www.sevenhundredrivers.com/pages/presskit'
						}
					]
				}
			]
		},
		stripe: {
			test: {
				connectAccountId: 'acct_1LOCMqFUmRL7asuQ'
			},
			live: {
				connectAccountId: 'acct_1LBMvqDvdU6B59yJ'
			}
		},
		address: {
			city: 'Raleigh',
			state: 'NC',
		},
		founder: {
			firstName: 'Cathy',
			lastName: 'Gomes'
		},
		twitter: {
			handle: '700rivers'
		},
		facebook: {
			url: 'https://www.facebook.com/The700Rivers'
		},
		entities: [
			'https://www.sevenhundredrivers.com',
			'https://www.facebook.com/The700Rivers',
			'https://twitter.com/700rivers',
			'https://www.pinterest.com/700rivers',
			'https://www.instagram.com/700rivers',
			'https://www.tiktok.com/@700rivers'
		],
	},
	{
		id: 'merchant_gleam_eyewear',
		isCore: false,
		domain: 'gleam.seesawmarketplace.com',
		name: 'Gleam Eyewear',
		description: 'Protect your eyes with fashionable and functional anti-blue light computer glasses inspired by women in history for women who make history!',
		productPitch: 'GLEAM is a Female Founded Brand of Blue Light Blocking Computer Glasses + Sunglasses Designed for the Modern Go-Getters. Empowering them to Grow their Business, Careers, and Side Hustles with Confidence and Style.',
		website: 'https://www.gleameyewear.com',
		email: 'hellogleameyewear@gmail.com',
		logo: 'gleam-logo-vertical.png',
		logoMark: 'gleam-logo-mark.png',
		favicon: 'https://cdn.shopify.com/s/files/1/0042/9218/2106/files/fAVICON_IDEA_32x32.png?v=1573503674',
		mobileIcon: 'gleam-icon512.png',
		socialCover: 'https://cdn.shopify.com/s/files/1/0042/9218/2106/files/IMG_8596_1_1200x1200.jpg?v=1604363839',
		colors: {
			brandPrimaryBase: '#bd6d4a',
			brandPrimaryLighter: '#ffefe6',
			brandBackground: '#faf9f6',
		},
		notices: [],
		collectionOptions: [
			{
				id: 'blue_light',
				name: 'Blue Light Blocking Glasses',
				description: ''
			},
			{
				id: 'sunglasses',
				name: 'Sunglasses',
				description: ''
			},
			{
				id: 'accessories',
				name: 'Accessories',
				description: ''
			},
			{
				id: 'kids',
				name: 'Kids',
				description: ''
			}
		],
		additionalCostRules: [
			{
				id: 'shipping',
				name: 'Shipping',
				percentage: 5,
				rule: {
					type: 'greater_than',
					value: 0
				}
			}
		],
		navigation: {
			primary: [
				{
					title: 'Home',
					url: 'https://www.gleameyewear.com'
				},
				{
					title: 'Shop',
					url: '/',
					action: 'close'
				},
				{
					title: 'About Us',
					url: 'https://www.gleameyewear.com/pages/about'
				},
				{
					title: 'What is Blue Light?',
					url: 'https://www.gleameyewear.com/pages/what-is-blue-light'
				},
				{
					title: 'Follow Up',
					url: '/followup'
				}
			],
			secondary: [
				{
					id: 1,
					items: [
						{
							title: 'Our Collections',
							url: '/',
							action: 'close'
						},
						{
							title: 'Place an Order',
							url: '/cart',
							action: 'close'
						},
						{
							title: 'Shipping & Returns',
							url: 'https://www.gleameyewear.com/pages/shipping-return'
						},
						{
							title: 'Blog',
							url: 'https://www.gleameyewear.com/blogs/news'
						}
					]
				},
				{
					id: 2,
					items: [
						{
							title: 'Refund Policy',
							url: 'https://www.gleameyewear.com/policies/refund-policy'
						},
						{
							title: 'Terms of Service',
							url: 'https://www.gleameyewear.com/policies/terms-of-service'
						},
						{
							title: 'Privacy Policy',
							url: 'https://www.gleameyewear.com/pages/privacy-policy'
						}
					]
				}
			]
		},
		stripe: {
			test: {
				connectAccountId: 'acct_1LOCMqFUmRL7asuQ'
			},
			live: {
				connectAccountId: 'acct_1INiAgCPXhUq37Qx'
			}
		},
		address: {
			city: 'Atlanta',
			state: 'GA',
		},
		founder: {
			firstName: 'Eva',
			lastName: 'Lester'
		},
		twitter: {
			handle: ''
		},
		facebook: {
			url: 'https://www.facebook.com/gleameyewear'
		},
		entities: [
			'https://www.gleameyewear.com',
			'https://www.facebook.com/gleameyewear',
			'https://www.pinterest.com/gleameyewear/',
			'https://www.instagram.com/gleameyewear/'
		],
	},
	CORE
];
