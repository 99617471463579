/*
 * File: Cart.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 10:38 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Services
import { getCart } from '../../../services/cart';

// Slices
import { pushComponentState } from '../../../store/slices/component/component.slice';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, Cart as BaseCart, SchemaScript
} from '../../../components';

// Styles
import * as S from './Cart.styles';


/**
 * Component
 */

const Cart = ({ meta, locale, merchant }) => {

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Create references for elements
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Get state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [componentData, setComponentData] = useState({ cart });

	// Use hooks
	const dispatch = useDispatch();

	// Get current component data state
	const componentState = useSelector((state) => (state.component));

	// Initialize data
	const initializeData = async () => {

		// Update page status
		setPageStatus('loading');

		// Initialize component data
		try {
			const { data: cartData } = await getCart(stateMerchant);

			// Update UI if mounted
			if (isMounted.current) {

				// Set component data
				setComponentData(cartData);

				// Push new component state
				dispatch(pushComponentState(cartData));

				// Update page status
				setPageStatus('success');

			}
		} catch (e) {

			// Update page status
			setPageStatus('error');
		}
	};

	// Handle cart change action
	useEffect(() => {

		// Update component data
		setComponentData({ cart });

	}, [cart]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Initialize data
			initializeData();
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, [componentState, pageStatus]);

	// Create merchant domain
	let merchantDomain = `https://${stateMerchant.domain}`;
	if (stateMerchant.redirectToSubdomain) {
		merchantDomain = `https://${stateMerchant.redirectToSubdomain}.${stateMerchant.domain}`;
	}

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={componentData} locale={stateLocale} merchant={stateMerchant} />

			{/* Schema.org */}
			<SchemaScript schema={{
				'@context': 'http://schema.org/',
				'@type': 'CheckoutPage',
				'@id': `${merchantDomain}/cart/#WebPage`,
				name: `${stateMerchant.name} Wholesale Checkout`,
				description: meta.description,
				url: `${merchantDomain}/cart`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${merchantDomain}/#WebSite`
				},
				publisher: {
					'@id': `${stateMerchant.website}/#Organization`,
				},
			}}
			/>

			{/* Component Content */}
			<AppNavigation merchant={stateMerchant}>
				<S.Wrapper>
					<BaseCart data={componentData} status={pageStatus} merchant={stateMerchant} />
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Cart.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Cart.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Cart;
