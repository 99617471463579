/*
 * File: Topbar.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 24, 2022 at 11:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../../styles/colors';

// Components
import { TextInput } from '../../TextInput';
import { IconButton } from '../../IconButton';

// Constants
import { mobileBreakpoint, AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme }) => theme.primaryBackground};
	z-index: 1000;
	box-shadow: ${({ merchant }) => ColorValuesCreator(merchant).navigationShadow};
	border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	padding: 0.78rem 0;
	pointer-events: all;
	position: fixed !important;
	width: 100%;
	top: 0px !important;

	* :not(svg,path) {
		height: 100%;
	}

	button {
		pointer-events: auto;
		align-items: center;
	}

	.searchBar {
		max-width: none;
    	width: 100%;

		@media only screen and (min-width: ${mobileBreakpoint}em) {
			max-width: 400px;
		}
	}

	.paddingLeft {
		margin-left: 20px;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;

	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
	`}
`;

export const NavigationTabContainer = styled.div`
	height: ${AppNavTopbarHeight}px !important;

	& div {
		display: flex;
	}
`;

export const LogoImage = styled.img`
	padding: 0.1rem 0px 0.1rem 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const SearchBar = styled(TextInput)`
	max-width: none;
	width: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		max-width: 400px;
	}
`;

export const ActionPadding = styled.div`
	padding: 3px 0;
`;

export const Divider = styled.div`
	height: 100%;
	width: 1px;
	background-color: ${({ theme }) => theme.layoutBorder};
	margin: 0px 30px;
`;

export const ProfileContainer = styled.div`
	position: relative;
`;

export const ProfileImage = styled.img`
	background-color: ${({ theme }) => theme.placeholderPrimary};
	border-radius: 50%;
	margin-left: 16px;
	cursor: pointer;
`;

export const UnreadBadge = styled.div`
	width: 13px;
	height: 13px !important;
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	border: 2px solid ${({ theme }) => theme.primaryBackground};
	border-radius: 50%;
	position: absolute;
	top: 0px;
	right: 0px;
	margin-right: -4px;
	margin-top: -1px;
`;

export const NavigationToggle = styled(IconButton)`
	height: auto;
	margin-right: 18px;
`;

export const DropdownContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 12px;
`;

export const DropdownToggle = styled(IconButton)`
	height: auto;
`;

export const CreateContainer = styled.div`
	position: relative;
`;
