/*
 * File: PaymentDetails.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: March 6, 2023 at 1:11 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	CardElement, useElements, useStripe
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Slices
import { updateCheckout } from '../../../../store/slices/checkout/checkout.slice';

// Components
import { PageHeader, Typography, Checkbox } from '../../../../components';

// Constants
import { UI_MODE_OPTIONS } from '../../../../../Constants';

// Styles
import * as S from './PaymentDetails.styles';
import { DarkTheme, LightTheme } from '../../../../styles/colors';


/**
 * Component
 */

export const PaymentDetails = forwardRef(({
	className, updateStep, updateHeight, isVisible, merchant
}, ref) => {

	// Get dispatch
	const dispatch = useDispatch();

	// Get Stripe elements
	const elements = useElements();
	const stripe = useStripe();

	// Get current check out flow
	const currentFlow = useSelector((state) => state.checkout.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current theme
	const theme = uiMode.mode === UI_MODE_OPTIONS.DARK ? DarkTheme(merchant) : LightTheme(merchant);

	// Get state handlers
	const [inputValues, setInputValues] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Handle next action
	const handleNext = async () => {

		// Update submission state
		setIsSubmitting(false);

		// Get parameters
		const { sendInvoice } = inputValues;

		// Get card element
		const cardElement = elements.getElement(CardElement);

		// Create token
		let cardToken = null;
		try {
			const { token, error } = await stripe.createToken(cardElement, {
				address_country: 'US'
			});
			if (error && error.message && !sendInvoice) {

				// Show error message
				toastError(uiMode, merchant, error.message);

				// Return
				return;
			}
			if (token) cardToken = token;
		} catch (e) {}

		// Validate parameters
		if ((!sendInvoice && !cardToken) || (cardToken && sendInvoice)) {

			// Show error message
			toastError(uiMode, merchant, 'Please enter your card information or select the email invoice option.');

			// Return
			return;
		}

		// Update checkout flow
		dispatch(updateCheckout({
			currentStep: 4,
			data: {
				...currentFlow.data,
				sendInvoice,
				...cardToken ? {
					cardToken
				} : undefined
			}
		}));

		// Move to next step
		updateStep(4);
	};

	// Handle back action
	const handleBack = () => {

		// Move to previous step
		updateStep(2);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {

			// Set input values
			setInputValues({ ...inputValues, [name]: checked });

			// Clear payment method if necessary
			if (checked === true) {

				// Get card element
				const cardElement = elements.getElement(CardElement);

				// Clear card element
				cardElement.clear();
			}
		} else {

			// Set input values
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle update on flow change
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [currentFlow]);

	// Handle update on visibility change
	useEffect(() => {

		// Update height
		updateHeight(true);

		// If component is visible, update input values
		if (isVisible === true) {
			setInputValues({
				sendInvoice: currentFlow.data.sendInvoice
			});
		}
	}, [isVisible]);

	// Render component
	return (
		<S.Wrapper ref={ref}>
			<div className="stepSpacing">

				{/* Header */}
				<PageHeader
					title="Payment Details"
					subtitle={merchant.name}
					isSmall
				/>

				{/* Step Container */}
				<S.StepContainer className={className}>

					{/* Card Elements */}
					<S.OptionContainer className="formInput">
						<Typography tag="label" weight="semibold" className="animate">
							Credit Card
						</Typography>
						<Typography tag="p" variant="3" weight="light" className="animate">
							Pay for your order securely with a credit card and we will begin filling your order immediately.
						</Typography>
						<S.ElementContainer>
							<CardElement options={{
								hidePostalCode: false,
								style: {
									base: {
										iconColor: theme.primaryText,
										color: theme.primaryText,
										fontSize: '16px',
										fontFamily: '"Lexend", sans-serif',
										fontSmoothing: 'antialiased',
										'::placeholder': {
											color: theme.inputPlaceholder,
										},
										':focus': {
											color: theme.primaryText,
										}
									},
									invalid: {
										color: theme.statusDangerBase,
										':focus': {
											color: theme.statusDangerBase
										}
									}
								}
							}}
							/>
						</S.ElementContainer>
					</S.OptionContainer>

					{/* Divider */}
					<S.Divider>
						<h3><span>OR</span></h3>
					</S.Divider>

					{/* Invoice Option */}
					<S.OptionContainer>
						<Typography tag="label" weight="semibold" className="animate">
							Send an Invoice for Later
						</Typography>
						<Typography tag="p" variant="3" weight="light" className="animate">
							If you would rather pay for your order later, select this option. We will email an invoice to your inbox.
						</Typography>
						<S.CheckboxRow>
							<Checkbox
								name="sendInvoice"
								value={inputValues.sendInvoice || false}
								onChange={handleOnChange}
							/>
							<Typography variation="1" weight="light">
								Yes, send an email invoice to
								{' '}
								<strong>{currentFlow.data.email}</strong>
								{' '}
								for my order.
							</Typography>
						</S.CheckboxRow>
					</S.OptionContainer>

					{/* Actions */}
					<S.Button size="large" onClick={handleNext} disabled={isSubmitting} merchant={merchant}>
						<Typography variation="button-small" weight="medium">CONTINUE TO REVIEW</Typography>
					</S.Button>
					<S.BackButton size="large" variant="text" onClick={handleBack} disabled={isSubmitting}>
						<Typography variation="button-small" weight="medium">BACK TO SHIPPING</Typography>
					</S.BackButton>

				</S.StepContainer>
			</div>
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

PaymentDetails.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	isVisible: PropTypes.bool,
	merchant: PropTypes.shape()
};
PaymentDetails.defaultProps = {
	className: null,
	updateHeight: null,
	isVisible: false,
	merchant: {}
};
