/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:35 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const updateCart = (data, merchant) => api(merchant).post('/cart/update', data, { withCredentials: true });

export const checkOutCart = (data, merchant) => api(merchant).post('/cart/checkout', data);

export const getCart = (merchant) => api(merchant).get('/cart');
