/*
 * File: Welcome.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { replaceColor } from 'lottie-colorify';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, MarketingNavigation, Typography, Button
} from '../../../components';

// Animation
import animationData from '../../../assets/animations/bicycle.json';

// Styles
import * as S from './Welcome.styles';


/**
 * Component
 */

const Welcome = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation merchant={stateMerchant}>
				<S.Wrapper>
					<S.ContentContainer>
						<S.LottieLink identifier="error-center-lottie">
							<Lottie loop play animationData={replaceColor('#e8bb3b', merchant.colors.brandPrimaryBase, animationData)} style={{ height: 310 }} />
						</S.LottieLink>
						<S.MessageContainer>
							<Typography tag="h1" weight="semibold">
								Welcome to
								{' '}
								{stateMerchant.name}
								.
							</Typography>
							<S.DescriptionContainer>
								<Typography tag="h4" weight="light">
									We&apos;re excited to have you onboard! Your merchant profile has been completed. As soon as it&apos;s verifed, a member of our team will reach out to help you set up your virtual storefront.
								</Typography>
							</S.DescriptionContainer>
							<S.StatusContainer>
								<Typography tag="h4" weight="light">
									Status
								</Typography>
								<Typography tag="h4" className="status">
									Verifying Merchant
								</Typography>
							</S.StatusContainer>
							<S.ActionMessageContainer>
								<Typography tag="h4" weight="semibold">
									While you&apos;re waiting, check out how to get the most out of your Seesaw Marketplace account
								</Typography>
							</S.ActionMessageContainer>
						</S.MessageContainer>
						<S.ActionContainer fullWidth>
							<S.ButtonLink to="/#features">
								<Button size="large" merchant={merchant}>Explore now</Button>
							</S.ButtonLink>
						</S.ActionContainer>
					</S.ContentContainer>
				</S.Wrapper>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

Welcome.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Welcome.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Welcome;
