/*
 * File: api.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:08 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:45 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import axios from 'axios';


/**
 * API Handler
 */

const createBaseAPI = (merchant) => {

	// Create API domain
	let apiDomain = process.env.API_URL;
	if (process.env.ENV !== 'development') {
		apiDomain = `https://${merchant.domain}`;
		if (merchant.redirectToSubdomain) {
			apiDomain = `https://${merchant.redirectToSubdomain}.${merchant.domain}`;
		}
	}

	// Create base API
	const api = axios.create({
		baseURL: `${apiDomain}${process.env.API_MOUNT}`,
		withCredentials: true,
	});

	// Add request interceptors
	api.interceptors.request.use(async (config) => {
		const headers = { ...config.headers };
		return { ...config, headers };
	});

	// Add response interceptors
	api.interceptors.response.use((response) => response, (error) => Promise.reject(error));

	// Return API
	return api;
};


/**
 * Exports
 */

export default createBaseAPI;
