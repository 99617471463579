/*
 * File: PaymentDetails.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button as BaseButton
} from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;

	label {
		display: block;
	 	cursor: pointer;
		text-align: left;
	 	color: ${({ theme, error }) => (error ? theme.statusDangerBase : theme.primaryText)};
		font-size: 1.0rem;
 	}
	p {
		text-align: left;
		font-size: 0.8rem;
		color: ${({ theme }) => theme.secondaryText};
		margin-bottom: 15px;
	}
`;

export const StepContainer = styled.div`
	button {
		width: 100%;
	}
`;

export const Button = styled(BaseButton)`
	margin: 40px 0 0 0;
`;

export const BackButton = styled(BaseButton)`
	margin: 5px 0 0 0;
`;

export const ElementContainer = styled.div`
	background-color: ${({ theme }) => theme.inputBackground};
	padding: 1rem 0.75rem;
	font-size: 1rem;
	border: 1px solid ${({ theme }) => theme.inputBorder};
`;

export const OptionContainer = styled.div``;

export const Divider = styled.div`
	display: block;
	margin: 0px auto;
	padding: 40px 0px;

	h3 {
		width: 100%;
		text-align: center;
		color: ${({ theme }) => theme.inputBorder};
		border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
		line-height: 0.1em;
		margin: 10px 0 10px;
		font-size: 0.9rem;
		font-weight: 500;

		span {
			background: ${({ theme }) => theme.primaryBackground};
			padding: 0 10px;
		}
	}
`;

export const CheckboxRow = styled.div`
	display: flex;
	margin: 15px 0 0 0;

	span {
		flex-grow: 1;
		text-align: left;
		margin: 0 0 0 12px;
		color: ${({ theme }) => theme.primaryText};
		position: relative;
		top: -1px;
		line-height: 160%;

		a,
		a:link,
		a:visited {
			color: inherit;
		}
	}
`;
