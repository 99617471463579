/*
 * File: Terms.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: April 29, 2024 at 1:44 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, MarketingNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './Terms.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const Terms = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation hidePadding merchant={stateMerchant}>
				<LegalNavigation merchant={stateMerchant}>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Terms of Service
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								By using the Seesaw Marketplace platform, you understand that a legal agreement exists between yourself and Seesaw Marketplace to ensure appropriate usage of the platform. This includes an understanding of how your data and content are handled via our
								{' '}
								<LocaleLink to="https://www.seesawmarketplace.com/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
								{' '}
								and an agreement to adhere to these Terms when interacting with others on our platform. We do not allow unauthorized or malicious access or use of our content, platform, or code in any way. Finally, if you have a question or issue with Seesaw Marketplace or these terms, please
								{' '}
								<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
								{' '}
								directly.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* 1. Overview and Acceptance of Use */}
						<Typography tag="h2" weight="semibold">
							1. Overview and Acceptance of Use
						</Typography>
						<Typography tag="p" weight="light">
							Welcome to Seesaw Marketplace! Seesaw Technologies, LLC. (“Seesaw Marketplace”, DBA Seesaw Travel (seesawtravel.com), Pixie Dust Guide (pixiedustguide.com)) is a curated wholesale online marketplace connecting creative brands with retailers, accessible through its websites (collectively, the “Sites”) and mobile applications (collectively, the “Applications”) (together, the “Services”). Throughout these Terms of Service (“Terms”), the terms “Seesaw Marketplace”, “our”, “us”, and/or “we” refer to Seesaw Marketplace. The terms “you” and/or “your” refer to any visitor of the Site and/or the Application and any user of the Services, including any Member (as defined below). These Terms govern your access to and use of the Services and Collective Content (defined below), and constitute a binding legal agreement between you and Seesaw Marketplace.
						</Typography>
						<Typography tag="p" weight="light">
							Please read these Terms, our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							, and our
							{' '}
							<LocaleLink to="/legal/cookie-policy" target="_blank">Cookie Policy</LocaleLink>
							, all of which are incorporated herein by reference and govern your access to and use of the Services. The Services are offered and available solely to users who are 18 or older. By using the Services, you represent and warrant that you are of legal age to form a binding contract with Seesaw Marketplace and meet the foregoing eligibility requirements. If you do not meet these requirements, you must not access or use the Services. If you are entering into these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that party to these Terms and, in such event, “you” and “your” will refer and apply to that party.
						</Typography>
						<Typography tag="p" weight="light">
							YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING OR USING THE SERVICES (INCLUDING BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ONTO THE SERVICES), YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OTHER TERMS AND POLICIES REFERENCED IN THESE TERMS. THIS APPLIES WHETHER OR NOT YOU HAVE REGISTERED ON OR THROUGH THE SERVICES. IF YOU DO NOT AGREE, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SERVICES OR COLLECTIVE CONTENT.
						</Typography>
						<Typography tag="p" weight="light">
							THESE TERMS REQUIRE THE USE OF ARBITRATION TO RESOLVE INDIVIDUAL DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU SHOULD THERE BE A DISPUTE. YOUR AGREEMENT TO THESE TERMS INDICATES YOUR EXPRESS ACKNOWLEDGEMENT AND AGREEMENT THAT YOU HAVE READ AND UNDERSTAND HOW THE ARBITRATION PROVISION SET FORTH IN SECTION 26 BELOW WORKS.
						</Typography>

						{/* 2. Modification */}
						<br />
						<Typography tag="h2" weight="semibold">
							2. Modification
						</Typography>
						<Typography tag="p" weight="light">
							We reserve the right, at our sole discretion to modify, suspend, discontinue or terminate the Services or any content, feature or material we provide thereon, or to modify these Terms, at any time and without prior notice, and have no obligation to update any information thereon. We will not be liable if for any reason all or any part of the Services is unavailable at any time or for any period. From time to time, we may restrict access to some or all parts of the Services to users, including Members. You agree that it is your responsibility to monitor changes to our Services. If we modify these Terms we will post the modification on the Site or via the Application and/or provide you with notice of the modification. By continuing to access or use the Services thereafter, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Services.
						</Typography>

						{/* 3. Key Definitions */}
						<br />
						<Typography tag="h2" weight="semibold">
							3. Key Definitions
						</Typography>
						<Typography weight="light">
							<ol>
								<li>“Collective means, collectively, Seesaw Marketplace Content and Member Content.</li>
								<li>“Content” means text, graphics, images, music, software (excluding the Application), audio, video, information or other materials.</li>
								<li>“Seesaw Marketplace Content” means Content that Seesaw Marketplace makes available through the Services including any Content licensed from a third party, but excluding Member Content.</li>
								<li>“Member” means a business that completes Seesaw Marketplace’s account registration process, as described under the “Account Registration” section below.</li>
								<li>“Member Content” means Content that a Member posts, uploads, publishes, submits or transmits to Seesaw Marketplace to be made available through the Services.</li>
							</ol>
						</Typography>

						{/* 4. Account Registration */}
						<br />
						<Typography tag="h2" weight="semibold">
							4. Account Registration
						</Typography>
						<Typography tag="p" weight="light">
							To access certain features of the Services and to post any Member Content thereon, you must register to create an account (“Account”) and become a Member. Seesaw Marketplace offers two types of Accounts. The first is a “Retailer Account” for buyers who purchase goods through the Services for the purpose of resale (“Retailers”). The second is a “Brand Account” for sellers who offer to sell and/or sell their goods to Retailers through the Services (“Brand”). Each type of Account gives access to different aspects of the Services.
						</Typography>
						<Typography tag="p" weight="light">
							You may register directly via the Site or Application. During the registration process, you will be required to provide certain information (depending on the type of Account), and you will establish a username and a password. You agree that all information you provide to register for an Account (and/or for any subsequent activity or interaction with Seesaw Marketplace and the Services), including through the use of any interactive features on the Services, is governed by our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							, and you consent to all actions we take with respect to your information or information you provide, consistent with our Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							You agree to provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. We reserve the right to temporarily suspend or permanently terminate your Account if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete. You are responsible for safeguarding your password and you agree that you will not disclose it to any third party. You agree that you are solely responsible for any activities or actions under your Account, whether you authorized them or not. You will immediately notify us of any unauthorized use of your Account. We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms.
						</Typography>

						{/* 5. Account Levels */}
						<br />
						<Typography tag="h2" weight="semibold">
							5. Account Levels
						</Typography>

						{/* Brand Account */}
						<Typography tag="h4" weight="semibold">
							Brand Account
						</Typography>
						<Typography tag="p" weight="light">
							To sell goods through the Services as a Brand, you must submit an application to, and be approved by, Seesaw Marketplace. The application process and Brand Account are both free. The application seeks basic information about you, your company and your products. If approved as a Brand, you will be required to submit additional information, including about your business, where to deposit funds from sales of your products and applicable tax and related documentation (e.g., EIN, W-8, etc.). Additional detail regarding information collected can be found in our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							.
						</Typography>

						{/* Retailer Account */}
						<Typography tag="h4" weight="semibold">
							Retailer Account
						</Typography>
						<Typography tag="p" weight="light">
							To purchase goods through the Services as a Retailer, you need to establish a Retail Account, which is free. When you sign up as a Retailer, you will be required to submit customary information such as your first and last name, store type (e.g., online, brick & mortar), email address and other information about your business. Before you make your first purchase as a Retailer, you may be required to provide additional information such as payment method information, reseller ID number and related documentation and any other information as may be required by Seesaw Marketplace. Additional detail regarding information collected can be found in our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							. You acknowledge and agree that by submitting your application to become a Retailer, you authorize Seesaw Marketplace and its designated agents to access your personal and business credit history, including obtaining a consumer credit report, for the purpose of evaluating your eligibility for a line of credit with Seesaw Marketplace. You further understand that credit inquiries may impact your credit score.
						</Typography>
						<Typography tag="p" weight="light">
							By submitting payment details in conjunction with registering for a Retailer Account and/or purchasing goods, you agree to pay for the goods purchased and any applicable taxes and other fees that may accrue, and authorize us to charge the payment method with the information you have supplied to us, and/or to credit such payment method to make any adjustments if necessary. We reserve the right to correct any errors in pricing. We, and the Brand, have the right to refuse any order.
						</Typography>

						{/* 6. Ownership */}
						<br />
						<Typography tag="h2" weight="semibold">
							6. Ownership
						</Typography>
						<Typography tag="p" weight="light">
							The Services and Collective Content are each protected by copyright, trademark and other laws of the United States and foreign countries. You acknowledge and agree that the Services and Collective Content, excluding your specific Member Content, including all associated intellectual property rights, are the exclusive property of Seesaw Marketplace, its licensors and/or other providers of such material. Other than expressly stated herein, there are no implied licenses granted under these Terms. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services or Collective Content. Additionally Seesaw Marketplace is the owner of pending, registered and/or unregistered trademarks, trade dress and trade name appearing on the Services, including the Seesaw Marketplace name and logo, and all related names, logos, product and service names, designs and slogans. You agree to not use such marks without Seesaw Marketplace’s prior written permission. Any other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of their respective parties.
						</Typography>

						{/* 7. License Granted by Seesaw Marketplace */}
						<br />
						<Typography tag="h2" weight="semibold">
							7. License Granted by Seesaw Marketplace
						</Typography>

						{/* License Grant */}
						<Typography tag="h4" weight="semibold">
							License Grant
						</Typography>
						<Typography tag="p" weight="light">
							Subject to the terms and conditions of these Terms, Seesaw Marketplace grants you: (a) a non-transferable, non-exclusive, revocable, limited license, with no right to sublicense, to use and access the Services, and to view any Collective Content to which you are permitted access solely for the purposes set forth in these Terms; and (b) a limited non-exclusive, revocable, non-transferable license to download and install a copy of the Application on your device solely in connection with your use of the Services.
						</Typography>

						{/* Restrictions */}
						<Typography tag="h4" weight="semibold">
							Restrictions
						</Typography>
						<Typography tag="p" weight="light">
							Except as otherwise stated, the rights granted herein are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host or otherwise commercially exploit the Services or Collective Content; (b) you shall not copy, reproduce, disseminate, distribute, modify, adapt, create derivative works of, publicly display, publicly perform, stream, broadcast, republish, download, disassemble, reverse compile, reverse engineer, store, post or transmit any of the material or content on our Services; and (c) you shall not interfere with or circumvent any feature of the Services, including any security or access control mechanism, in whole or in part, except as permitted in these Terms. Except as expressly granted in these Terms, no licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Seesaw Marketplace, its licensors or others. If you violate the rights of Seesaw Marketplace, its licensors or others, your right to use the Services will stop immediately and you must, at our option, return or destroy any copies of the materials you have made.
						</Typography>

						{/* 8. License Granted by Member */}
						<br />
						<Typography tag="h2" weight="semibold">
							8. License Granted by Member
						</Typography>
						<Typography tag="p" weight="light">
							In order to get the most out of the Services, Members can provide or otherwise make Member Content available to us for use in connection with the Services. For these purposes, you hereby grant to Seesaw Marketplace a worldwide, perpetual, non-exclusive, transferable, sublicensable, royalty-free license to use, view, copy, adapt, modify, distribute, publicly display and publicly perform (through any means necessary) such Member Content on, through or by means of the Services and/or any of Seesaw Marketplace’s advertising, marketing, publicity or other initiatives or events. Seesaw Marketplace does not claim any ownership rights in any Member Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit any Member Content.
						</Typography>
						<Typography tag="p" weight="light">
							You acknowledge and agree that you are solely responsible for any and all Member Content that you make available through the Services. Accordingly, you represent and warrant that: (a) you either are the sole and exclusive owner of all such Member Content or you have all rights, licenses, consents and releases necessary to grant to Seesaw Marketplace the rights in such Member Content as contemplated under these Terms; and (b) neither the Member Content or any portion thereof nor your posting, uploading, publication, submission or transmittal of the Member Content or Seesaw Marketplace’s use of the Member Content (or any portion thereof) on, through or by means of the Services and/or third party platforms will infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy, or contain libelous, misleading, or otherwise unlawful, abusive, harassing or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Services, or result in the violation of any applicable law or regulation. You agree that you will not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any posts. You are solely responsible for any Member Content you make and its accuracy. Seesaw Marketplace takes no responsibility and assumes no liability for any Member Content posted by you or any third party, and you agree to indemnify Seesaw Marketplace per the terms of the Indemnification section herein.
						</Typography>

						{/* 9. Content from Social Media and Networking Sites */}
						<br />
						<Typography tag="h2" weight="semibold">
							9. Content from Social Media and Networking Sites
						</Typography>
						<Typography tag="p" weight="light">
							As a Brand, you may link your social media accounts to your Brand store (collectively, “Social Media Accounts”). You represent that you are entitled to grant Seesaw Marketplace access to, or otherwise make available, your Social Media Accounts and the content therein for the purposes described herein, without breach by you of any of the terms and conditions that govern your use of the applicable Social Media Account, and without obligating Seesaw Marketplace to pay any fees or making Seesaw Marketplace subject to any usage limitations imposed by such social media service providers. Depending on the Social Media Accounts you choose and subject to the privacy settings that you have set in such Social Media Accounts, personally identifiable information that you post to your Social Media Accounts will be available on and through your Brand Account on the Services.
						</Typography>
						<Typography tag="p" weight="light">
							Please note that if a Social Media Account or associated service becomes unavailable for any reason, then the Social Media Account Content that was available from such Social Media Account will no longer be available on and through the Services. You have the ability to unlink your Brand Account and your Social Media Accounts, at any time, by contacting Seesaw Marketplace. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE SOCIAL MEDIA SERVICE PROVIDERS ASSOCIATED WITH YOUR SOCIAL MEDIA ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH SOCIAL MEDIA SERVICE PROVIDERS. Seesaw Marketplace makes no effort to review any Social Media Account Content for any purpose, including for accuracy, legality or non-infringement and Seesaw Marketplace is not responsible for any Social Media Account Content. Seesaw Marketplace may, however, at any time and without prior notice, screen, remove, disable or block any Social Media Account Content that in Seesaw Marketplace’s sole judgment violates these Terms or is otherwise objectionable.
						</Typography>

						{/* 10. Feedback */}
						<br />
						<Typography tag="h2" weight="semibold">
							10. Feedback
						</Typography>
						<Typography tag="p" weight="light">
							We welcome and encourage you to provide feedback, comments and suggestions for improvements to the Services (“Feedback”). You acknowledge and agree that if you submit any Feedback to us (through any communication channel), you hereby grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sub-licensable and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose.
						</Typography>

						{/* 11. Reliance on Information Posted */}
						<br />
						<Typography tag="h2" weight="semibold">
							11. Reliance on Information Posted
						</Typography>
						<Typography tag="p" weight="light">
							The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site or user of the Services, or by anyone who may be informed of any of its contents.
						</Typography>
						<Typography tag="p" weight="light">
							The Services include content provided by third parties, including materials provided by other users, Members, bloggers and third party licensors. All statements and opinions expressed in these materials, and all articles and responses to questions and other Content, other than the Content provided by Seesaw Marketplace, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Seesaw Marketplace. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
						</Typography>

						{/* 12. Links to Third Party Websites */}
						<br />
						<Typography tag="h2" weight="semibold">
							12. Links to Third Party Websites
						</Typography>
						<Typography tag="p" weight="light">
							The Services may contain links to third-party websites or resources. You acknowledge and agree that Seesaw Marketplace is not responsible or liable for: (a) the availability or accuracy of such websites or resources; or (b) the Content, products or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Seesaw Marketplace of such websites or resources or the Content, products or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the Content, products, or services on or available from such websites or resources. Also, Apple Inc. (“Apple”) and Google, Inc. will be a third party beneficiary to these Terms if you access the Services using applications developed for Apple iOS or Android, respectively. These third party beneficiaries are not parties to these Terms and are not responsible for the provision or support of the Services in any manner. Your access to the Services using these applications or devices is subject to terms set forth in their respective terms of service and privacy policies.
						</Typography>

						{/* 13. Notice Regarding Apple */}
						<br />
						<Typography tag="h2" weight="semibold">
							13. Notice Regarding Apple
						</Typography>
						<Typography tag="p" weight="light">
							This Section only applies to the extent you are using our mobile application on an iOS device. You acknowledge that these Terms are between you and Seesaw Marketplace only, not with Apple, and Apple is not responsible for the Services or the content thereof. Apple has no obligation to furnish any maintenance and support services with respect to the Services. If the Services fails to conform to any applicable warranty, you may notify Apple and Apple will refund any applicable purchase price for the mobile application to you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Services. Apple is not responsible for addressing any claims by you or any third party relating to the Services or your possession and/or use of the Services, including: (a) product liability claims; (b) any claim that the Services fail to conform to any applicable legal or regulatory requirement; or (c) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement and discharge of any third party claim that the Services and/or your possession and use of the Services infringe a third party’s intellectual property rights. You agree to comply with any applicable third party terms when using the Services. Apple and Apple’s subsidiaries are third party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary of these Terms.
						</Typography>

						{/* 14. Prohibited Uses */}
						<br />
						<Typography tag="h2" weight="semibold">
							14. Prohibited Uses
						</Typography>
						<Typography tag="p" weight="light">
							You agree not to do any of the following:
						</Typography>
						<Typography weight="light">
							<ol>
								<li>Post, upload, publish, submit or transmit any Content that: (a) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (b) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (c) is fraudulent, false, misleading or deceptive; (d) is defamatory, obscene, pornographic, vulgar or offensive; (e) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (f) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (g) promotes illegal or harmful activities or substances.</li>
								<li>Use, embed, display, mirror, or frame the Site or Application, or any individual element within the Services, Seesaw Marketplace’s name, any Seesaw Marketplace trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Seesaw Marketplace’s express written consent or as enabled by features made available by Seesaw Marketplace (e.g., widgets) (you may link to Seesaw Marketplace’s homepage, so long as you do not imply or suggest any form of association, approval or endorsement on our behalf without our express written consent but you may not link to the Site in a libelous, misleading or otherwise unlawful manner, or in any manner that violates these Terms);</li>
								<li>Access, tamper with or use non-public areas of the Site or Application, Seesaw Marketplace’s computer systems or the technical delivery systems of Seesaw Marketplace’s providers;</li>
								<li>Attempt to probe, scan, or test the vulnerability of any Seesaw Marketplace system or network or breach any security or authentication measures;</li>
								<li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Seesaw Marketplace or any of Seesaw Marketplace’s providers or any other third party (including another user) to protect the Services or Collective Content;</li>
								<li>Attempt to access or search the Services or Collective Content or download Collective Content from the Services through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Seesaw Marketplace or other generally available third party web browsers;</li>
								<li>Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation to, through, or with any assistance from, the Services;</li>
								<li>Use any meta tags or other hidden text or metadata utilizing a Seesaw Marketplace trademark, logo URL or product name without Seesaw Marketplace’s express written consent;</li>
								<li>Use the Services or Collective Content in any manner not permitted by these Terms;</li>
								<li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services or Collective Content to send altered, deceptive or false source-identifying information;</li>
								<li>Attempt to decipher, decompile, disassemble or reverse engineer any of the software or algorithms used to provide the Services or Collective Content;</li>
								<li>Interfere with, or attempt to interfere with, the access of any user, host or network, including sending a virus, overloading, flooding, spamming or mail-bombing the Services;</li>
								<li>
									Collect or store any personally identifiable information from the Services from other users of the Site, Services or Application without their express permission and in a manner that does not violate Seesaw Marketplace’s
									{' '}
									<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
									;
								</li>
								<li>Impersonate or misrepresent your affiliation with any person or entity;</li>
								<li>Violate any applicable law or regulation; or</li>
								<li>Encourage or enable any other third party to do any of the foregoing.</li>
							</ol>
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. Seesaw Marketplace may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms. You acknowledge that Seesaw Marketplace has no obligation to monitor your access to or use of the Services or Collective Content, or to review or edit any Collective Content, but has the right to do so for the purpose of operating the Services, to ensure your compliance with these Terms, to investigate a complaint or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. Seesaw Marketplace reserves the right, at any time and without prior notice, to remove or disable access to any Member Content or Collective Content that Seesaw Marketplace, in its sole discretion, considers to be in violation of these Terms or otherwise harmful to the Services. Seesaw Marketplace reserves the right to cooperate fully with law enforcement, and to involve and share information with law enforcement, governmental agencies or other oversight bodies if Seesaw Marketplace suspects illegal activity may be taking place.
						</Typography>

						{/* 15. Text Messages and Telephone Calls */}
						<br />
						<Typography tag="h2" weight="semibold">
							15. Text Messages and Telephone Calls
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace, and those acting on Seesaw Marketplace’s behalf, may contact you by telephone or text message (including through use of an automatic telephone dialing system) at the phone numbers you have provided to us. These messages may include operational messages about your use of the Service. You understand that you are not required to consent to communications as a condition of purchasing any property, goods or services. You may opt out of receiving telephone calls or text messages from us at any time, either by texting the word “STOP” in response to a text message received from us using the mobile device that is receiving the messages. You may continue to receive text messages for a short period while Seesaw Marketplace processes your request, and you may also receive text messages confirming the receipt of your opt-out request. Opting out of receiving operational text messages may impact the functionality of the Services. Standard data and message rates may apply whenever you send or receive such messages, as specified by your carrier.
						</Typography>

						{/* 16. Procedure for Claiming Intellectual Property Infringement – DMCA Notice */}
						<br />
						<Typography tag="h2" weight="semibold">
							16. Procedure for Claiming Intellectual Property Infringement – DMCA Notice
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace respects intellectual property laws and expects its users to do the same. It is Seesaw Marketplace’s policy to terminate, in appropriate circumstances, Members or other account holders who infringe or are believed to be infringing the intellectual property rights of others. Click
							{' '}
							<LocaleLink to="/legal/copyright-policy" target="_blank">here</LocaleLink>
							{' '}
							for Seesaw Marketplace’s
							{' '}
							<LocaleLink to="/legal/copyright-policy" target="_blank">Intellectual Property Policy</LocaleLink>
							.
						</Typography>

						{/* 17. Information We Collect About You */}
						<br />
						<Typography tag="h2" weight="semibold">
							17. Information We Collect About You
						</Typography>
						<Typography tag="p" weight="light">
							All information we collect about you when you apply for an account or use or visit the Services is subject to our
							{' '}
							<LocaleLink to="/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							. By using the Services, you consent to the terms and conditions of the Privacy Policy and all actions taken by us in accordance with it.
						</Typography>

						{/* 18. Termination and Account Cancellation */}
						<br />
						<Typography tag="h2" weight="semibold">
							18. Termination and Account Cancellation
						</Typography>
						<Typography tag="p" weight="light">
							If you breach any of these Terms, Seesaw Marketplace will have the right to suspend or disable your Account or terminate these Terms, in its sole discretion and without prior notice to you. Seesaw Marketplace reserves the right to revoke your access to and use of the Services and Collective Content at any time, with or without cause. In the event Seesaw Marketplace terminates these Terms for your breach, you will remain liable for all amounts due hereunder. You may cancel your Account at any time by
							{' '}
							<LocaleLink to="/contact" target="_blank">contacting us</LocaleLink>
							.
						</Typography>

						{/* 19. Disclaimers */}
						<br />
						<Typography tag="h2" weight="semibold">
							19. Disclaimers
						</Typography>
						<Typography tag="p" weight="light">
							THE SERVICES AND COLLECTIVE CONTENT ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, SEESAW MARKETPLACE EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. SEESAW MARKETPLACE MAKES NO WARRANTY THAT THE SERVICES OR COLLECTIVE CONTENT WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS. SEESAW MARKETPLACE MAKES NO WARRANTY REGARDING THE QUALITY OF ANY PRODUCTS, SERVICES OR COLLECTIVE CONTENT PURCHASED OR OBTAINED THROUGH THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT OBTAINED THROUGH THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM SEESAW MARKETPLACE OR THROUGH THE SERVICES OR COLLECTIVE CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
						</Typography>
						<Typography tag="p" weight="light">
							YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES. YOU UNDERSTAND THAT SEESAW MARKETPLACE DOES NOT SCREEN OR INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE SERVICES, NOR DOES SEESAW MARKETPLACE MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SERVICES. SEESAW MARKETPLACE MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE SERVICES OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
						</Typography>

						{/* 20. Indemnity */}
						<br />
						<Typography tag="h2" weight="semibold">
							20. Indemnity
						</Typography>
						<Typography tag="p" weight="light">
							You agree to defend, indemnify, and hold Seesaw Marketplace, and its parent, subsidiaries, affiliates, partners, successors, and assigns, and each of their owners, members, officers, directors, employees, agents, representatives, contractors, subcontractors, licensors, service providers and third party content providers, harmless from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal and accounting fees) made by any third party due to or arising out of your violation of these Terms, and/or any law or the rights of a third party, and/or your use of the Services, including your Member Content.
						</Typography>

						{/* 21. Limitation of Liability */}
						<br />
						<Typography tag="h2" weight="semibold">
							21. Limitation of Liability
						</Typography>
						<Typography tag="p" weight="light">
							YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SERVICES AND COLLECTIVE CONTENT REMAINS WITH YOU. NEITHER SEESAW MARKETPLACE NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DELIVERING THE SITE, SERVICES, APPLICATION OR COLLECTIVE CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR COLLECTIVE CONTENT, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE SERVICES OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SEESAW MARKETPLACE HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
						</Typography>
						<Typography tag="p" weight="light">
							IN NO EVENT WILL SEESAW MARKETPLACE’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR COLLECTIVE CONTENT EXCEED US$100. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN SEESAW MARKETPLACE AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
						</Typography>

						{/* 22. Controlling Law and Jurisdiction */}
						<br />
						<Typography tag="h2" weight="semibold">
							22. Controlling Law and Jurisdiction
						</Typography>
						<Typography tag="p" weight="light">
							These Terms and any action related thereto will be governed by the laws of the State of North Carolina without regard to its conflict of laws provisions. The exclusive jurisdiction and venue of any action or court proceeding permitted under these Terms will be the state and federal courts located in Wake County, North Carolina and each of the parties hereto waives any objection to jurisdiction and venue in such courts.
						</Typography>

						{/* 23. Export Control */}
						<br />
						<Typography tag="h2" weight="semibold">
							23. Export Control
						</Typography>
						<Typography tag="p" weight="light">
							You agree to comply fully with all U.S. and foreign export laws and regulations to ensure that neither the Application nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. You represent and warrant that: (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
						</Typography>

						{/* 24. Assignment */}
						<br />
						<Typography tag="h2" weight="semibold">
							24. Assignment
						</Typography>
						<Typography tag="p" weight="light">
							You may not assign or transfer these Terms, by operation of law or otherwise, without Seesaw Marketplace’s prior written consent. Any attempt by you to assign or transfer these Terms without such consent will be null and of no effect. Seesaw Marketplace may assign or transfer these Terms in its sole discretion without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
						</Typography>

						{/* 25. Notices */}
						<br />
						<Typography tag="h2" weight="semibold">
							25. Notices
						</Typography>
						<Typography tag="p" weight="light">
							Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in writing and given: (a) by Seesaw Marketplace via email (in each case to the primary account email address that you provide); (b) by posting to the Site; or (c) via the Application. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.
						</Typography>

						{/* 26. Dispute Resolution - Arbitration */}
						<br />
						<Typography tag="h2" weight="semibold">
							26. Dispute Resolution - Arbitration
						</Typography>
						<Typography tag="p" weight="light">
							PLEASE READ THIS ARBITRATION PROVISION CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS.
						</Typography>
						<Typography tag="p" weight="light">
							You and Seesaw Marketplace agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right to: (a) bring an individual action in small claims court; (b) the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights; (c) pursue an enforcement action through the applicable federal, state or local agency if that action is available; and (d) seek injunctive relief in a court of law in aid of arbitration.YOU ACKNOWLEDGE AND AGREE THAT YOU AND SEESAW MARKETPLACE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. Further, unless both you and Seesaw Marketplace otherwise agree in writing, the arbitrator may not consolidate more than one person&apos;s claims, and may not otherwise preside over any form of any class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as provided in the preceding sentence, this “Dispute Resolution” section will survive any termination of these Terms.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Arbitration Rules and Governing Law.</Typography>
							{' '}
							The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section. The parties agree that the Federal Arbitration Act applies and will govern the interpretation and enforcement of this Arbitration Agreement.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Arbitration Process.</Typography>
							{' '}
							A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. The arbitrator will be either a retired judge or an experienced (15+ years practicing) attorney licensed to practice law in the state of California.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Arbitration Location and Procedure.</Typography>
							{' '}
							Unless you and Seesaw Marketplace otherwise agree, the arbitration will be conducted in the county where you reside. If your claim does not exceed US$10,000, then the arbitration will be conducted solely on the basis of documents you and Seesaw Marketplace submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds US$10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Arbitrator’s Decision.</Typography>
							{' '}
							The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award damages must be consistent, to the extent permitted by law, with the terms of the “Limitation of Liability” section above as to the types and the amounts of damages for which a party may be held liable.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Fees.</Typography>
							{' '}
							Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. If applicable arbitration rules or laws require us to pay a greater portion or all of such fees and costs in order for this Dispute Resolution provision to be enforceable, then we will have the right to elect to pay the fees and costs and proceed to arbitration.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Changes.</Typography>
							{' '}
							Notwithstanding the provisions of the “Modification” section above, if Seesaw Marketplace changes this “Dispute Resolution” section after the date you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by sending us written notice (including by contacting us) within 30 days of the date such change became effective, as indicated in the “Last Updated Date” above or in the date of Seesaw Marketplace’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and Seesaw Marketplace in accordance with the arbitration provisions of this “Dispute Resolution” section (however entitled) as of the date you first accepted these Terms or accepted any subsequent changes to these Terms.
						</Typography>

						{/* 27. Entire Agreement */}
						<br />
						<Typography tag="h2" weight="semibold">
							27. Entire Agreement
						</Typography>
						<Typography tag="p" weight="light">
							These Terms constitute the entire and exclusive understanding and agreement between Seesaw Marketplace and you regarding the Services and Collective Content, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Seesaw Marketplace and you regarding the Services and Collective Content.
						</Typography>

						{/* 28. Miscellaneous */}
						<br />
						<Typography tag="h2" weight="semibold">
							28. Miscellaneous
						</Typography>
						<Typography tag="p" weight="light">
							The failure by Seesaw Marketplace to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Seesaw Marketplace. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. Throughout these Terms, the word “include” or “including” means “including, but not limited to”. Provisions that by their nature are intended to survive the termination of these Terms or your use of the Services will survive.
						</Typography>

						{/* Contact Us */}
						<br />
						<Typography tag="h2" weight="semibold">
							Contact Us
						</Typography>
						<Typography tag="p" weight="light">
							If you have any questions about these terms or your rights when using our product, please email us at
							{' '}
							<LocaleLink to="mailto:legal@seesawmarketplace.com?Subject=Terms of Service">legal@seesawmarketplace.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

Terms.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Terms.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Terms;
