/*
 * File: utilities.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 21, 2022 at 10:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 27, 2022 at 2:12 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

export const getCDNURL = (merchant) => {

	// If development, use env variable
	if (process.env.ENV === 'development') {
		return process.env.CDN_URL;
	}

	// Create merchant domain
	let merchantDomain = `https://${merchant.domain}`;
	if (merchant.redirectToSubdomain) {
		merchantDomain = `https://${merchant.redirectToSubdomain}.${merchant.domain}`;
	}

	// Return merchant domain
	return merchantDomain;
};


/**
 * Parsers
 */

export const parseFirstLastName = (name) => {
	if (!name) return {};
	const nameArray = name.split(/\s+/);
	const firstName = nameArray[0].trim();
	const lastName = name.replace(firstName, '').trim();
	return { firstName, lastName };
};
