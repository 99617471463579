/*
 * File: cost.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 1, 2022 at 11:30 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Utilities
 */

export const formatCost = (cost) => (cost ? `${cost.toFixed(2)}` : '0.00');
