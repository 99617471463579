/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 7:40 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 28, 2023 at 12:15 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const saveContact = (data, merchant) => api(merchant).post('/merchant/contact', data);

export const postContactSubmission = (data, merchant) => api(merchant).post('/contact', data);
