/*
 * File: Collections.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Typography } from '../../../components';

// Constants
import { mediumBreakpoint, largeBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
`;

export const CollectionContainer = styled.div`
	padding: 40px 0px 50px;

	* {
		text-align: left;
	}
`;

export const CollectionDescription = styled.div`
	padding: 0px 0px 40px;

	h2 {
		margin: 0px 0px 10px;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		width: 100%;
		max-width: 800px;
	}
`;

export const ProductContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 25px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const NoticeContainer = styled.div`
	margin: 40px 0 -10px 0;
`;

export const InnerNotice = styled.div`
	display: inline-block;
`;

export const NoticeText = styled(Typography)`
	margin-right: 12px;
	text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;
