/*
 * File: Review.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 28, 2023 at 12:28 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { toastError } from '../../../../utilities/toaster';

// Services
import { checkOutCart } from '../../../../services/cart';

// Slices
import { clearCheckout } from '../../../../store/slices/checkout/checkout.slice';
import { clearCart } from '../../../../store/slices/cart/cart.slice';

// Components
import { PageHeader, Typography } from '../../../../components';

// Styles
import * as S from './Review.styles';


/**
 * Component
 */

export const Review = forwardRef(({
	className, updateStep, updateHeight, allowEmptyCart, isVisible, merchant
}, ref) => {

	// Get dispatch
	const dispatch = useDispatch();

	// Get current check out flow
	const currentFlow = useSelector((state) => state.checkout.value);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Handle next action
	const handleCompleteOrder = async () => {

		// Update submission state
		setIsSubmitting(true);

		// Check out cart
		try {

			// Perform check out
			await checkOutCart({
				...currentFlow.data,
				...currentFlow.data.cardToken != null ? {
					cardToken: currentFlow.data.cardToken.id
				} : undefined
			}, merchant);

			// Update submission state
			setIsSubmitting(false);

			// Set allow empty cart state
			allowEmptyCart();

			// Move to next step
			updateStep(5);

			// Clear state after delay
			setTimeout(() => {

				// Clear check out flow
				dispatch(clearCheckout());

				// Clear current cart
				dispatch(clearCart({ merchant }));

			}, 300);

		} catch (e) {

			// Update submission state
			setIsSubmitting(false);

			// Show error message
			toastError(uiMode, merchant, 'We\'re having trouble completing your order. Give it another go.');
		}
	};

	// Handle back action
	const handleBack = () => {

		// Move to previous step
		updateStep(3);
	};

	// Handle update on flow change
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [currentFlow, isVisible]);

	// Render component
	return (
		<S.Wrapper ref={ref}>
			<div className="stepSpacing">

				{/* Header */}
				<PageHeader
					title="Review Your Order"
					subtitle={merchant.name}
					isSmall
				/>

				{/* Step Container */}
				<S.StepContainer className={className}>

					{/* Personal Details */}
					<S.DetailContainer>
						<Typography tag="h4" weight="semibold">Personal Details</Typography>
						<S.DetailWrapper>
							<Typography tag="h5" weight="semibold">Name</Typography>
							<Typography tag="p" weight="light">{currentFlow.data.name}</Typography>
						</S.DetailWrapper>
						<S.DetailWrapper>
							<Typography tag="h5" weight="semibold">Email</Typography>
							<Typography tag="p" weight="light">{currentFlow.data.email}</Typography>
						</S.DetailWrapper>
						<S.DetailWrapper>
							<Typography tag="h5" weight="semibold">Company</Typography>
							<Typography tag="p" weight="light">{currentFlow.data.company}</Typography>
						</S.DetailWrapper>
					</S.DetailContainer>

					{/* Shipping Details */}
					<S.DetailContainer>
						<Typography tag="h4" weight="semibold">Shipping Address</Typography>
						<S.DetailWrapper>
							<Typography tag="p" weight="light">{currentFlow.data.addressLine1}</Typography>
							{currentFlow.data.addressLine2 && <Typography tag="p" weight="light">{currentFlow.data.addressLine2}</Typography>}
							<Typography tag="p" weight="light">{`${currentFlow.data?.city}, ${currentFlow.data.state} ${currentFlow.data.postalCode}`}</Typography>
						</S.DetailWrapper>
					</S.DetailContainer>

					{/* Payment Details */}
					<S.DetailContainer>
						<Typography tag="h4" weight="semibold">Payment Method</Typography>
						<S.DetailWrapper>
							{currentFlow.data.sendInvoice ? (
								<Typography tag="p" weight="light">
									Send email invoice to
									{' '}
									<strong>{currentFlow.data.email}</strong>
								</Typography>
							) : <Typography tag="p" weight="light">{`${currentFlow.data.cardToken?.card.brand} ending in ****${currentFlow.data.cardToken?.card.last4}`}</Typography>}
						</S.DetailWrapper>
					</S.DetailContainer>

					{/* Actions */}
					<S.Button size="large" onClick={handleCompleteOrder} disabled={isSubmitting} merchant={merchant}>
						<Typography variation="button-small" weight="medium">COMPLETE ORDER</Typography>
					</S.Button>
					<S.BackButton size="large" variant="text" onClick={handleBack} disabled={isSubmitting}>
						<Typography variation="button-small" weight="medium">BACK TO PAYMENT</Typography>
					</S.BackButton>

				</S.StepContainer>
			</div>
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

Review.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	allowEmptyCart: PropTypes.func,
	isVisible: PropTypes.bool,
	merchant: PropTypes.shape()
};
Review.defaultProps = {
	className: null,
	updateHeight: null,
	allowEmptyCart: null,
	isVisible: false,
	merchant: {}
};
