/*
 * File: Notice.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 11:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../styles/colors';


/**
 * Styles
 */

export const Notice = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	background-color: ${({ theme }) => theme.cardBackground};
	padding: 16px 20px;
	margin: 0 0 10px;
	box-shadow: ${({ merchant }) => ColorValuesCreator(merchant).elementShadow};
	font-size: 0.95rem;
`;
