/*
 * File: Notice.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 11:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './Notice.styles';


/**
 * Component
 */

export const Notice = ({ children, merchant }) => (
	<S.Notice merchant={merchant}>
		{children}
	</S.Notice>
);


/**
 * Configuration
 */

Notice.displayName = 'Notice';
Notice.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	merchant: PropTypes.shape()
};
Notice.defaultProps = {
	children: null,
	merchant: {}
};
