/*
 * File: Cart.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 27, 2022 at 11:24 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 28, 2023 at 12:31 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import { replaceColor } from 'lottie-colorify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

// Utilities
import { formatDateString } from '../../../utilities/dateTime';
import { formatCost } from '../../utilities/cost';

// Components
import { PageHeader } from '../PageHeader';
import { ErrorComponent } from '../ErrorComponent';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { ProductRow } from './ProductRow';
import { Notice } from '../Notice';

// Constants
import { AVAILABLE_I18N_NAMESPACES } from '../../../Constants';

// Animation
import animationData from '../../assets/animations/bicycle.json';

// Styles
import * as S from './Cart.styles';


/**
 * Component
 */

export const Cart = ({
	data, status, isSmall, showNotices, showTitle, allowActions, emptyAction, showAdditionalCosts, merchant
}) => {

	// Use hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Get translation hook
	const [t] = useTranslation(AVAILABLE_I18N_NAMESPACES.TRANSLATIONS);

	// Get cart
	const cart = data?.cart || { products: [], additionalCosts: [] };

	// Calculate cart metrics
	let numberItems = 0;
	let totalCost = 0;
	if (cart?.products) {
		cart?.products.forEach((product) => {
			totalCost += product.totalCost * product.quantity;
			numberItems += product.quantity;
		});
	}

	// Add additional costs if necessary
	if (showAdditionalCosts && cart.additionalCosts.length > 0) {
		cart.additionalCosts.forEach((cost) => {
			totalCost += cost.cost;
		});
	}

	// Handle check out action
	const handleCheckOut = () => {

		// Move to check out flow
		navigate(`${locale.localePath}/checkout`);
	};

	// Render component function
	const renderComponent = () => {

		// Return error component if necessary
		if (status === 'error') {
			return <ErrorComponent merchant={merchant} />;
		}

		// Return loading component if necessary
		if (status === 'loading') {
			return <Spinner merchant={merchant} />;
		}

		// Render component
		return cart.products.length === 0
			? (
				<S.ContentContainer>
					<S.LottieLink identifier="error-center-lottie">
						<Lottie loop play animationData={replaceColor('#e8bb3b', merchant.colors.brandPrimaryBase, animationData)} style={{ height: !isSmall ? 300 : 250 }} />
					</S.LottieLink>
					<S.MessageContainer>
						<Typography tag={!isSmall ? 'h2' : 'h3'} weight="semibold" isSmall>
							<Trans t={t} i18nKey="cart.emptyTitle">
								Your cart is currently empty!
							</Trans>
						</Typography>
						<S.DescriptionContainer>
							<Typography tag={!isSmall ? 'h4' : 'h5'} weight="light" isSmall>
								Add something special from our collection to get started.
							</Typography>
						</S.DescriptionContainer>
					</S.MessageContainer>
					<S.ActionContainer fullWidth>
						<S.ButtonLink onClick={(e) => {
							e.preventDefault();
							if (emptyAction) emptyAction();
							navigate(`${locale.localePath}/`);
						}}
						>
							<Button size="large" merchant={merchant}>Shop all our products</Button>
						</S.ButtonLink>
					</S.ActionContainer>
				</S.ContentContainer>
			) : (
				<S.CartContainer>

					{/* Product Header */}
					<ProductRow isHeader isSmall={isSmall} allowActions={allowActions} />

					{/* Products */}
					{cart?.products.map((product) => (
						<ProductRow
							product={product}
							key={product.id}
							isSmall={isSmall}
							allowActions={allowActions}
						/>
					))}

					{/* Additional Costs */}
					{cart?.products.length > 0 && cart?.additionalCosts.length > 0 && showAdditionalCosts && (
						<S.CostsContainer>
							{cart?.additionalCosts.map((cost) => (
								<S.CostItem key={cost.id}>
									<Typography>{cost.name}</Typography>
									<Typography weight="semibold" className="cost">
										$
										{formatCost(cost.cost)}
									</Typography>
								</S.CostItem>
							))}
						</S.CostsContainer>
					)}

					{/* Check Out Button */}
					{cart?.products.length > 0 && (
						<S.BottomActionContainer>
							<Typography>Subtotal</Typography>
							<Typography weight="semibold" className="cost">
								$
								{formatCost(totalCost)}
							</Typography>
							{allowActions && (
								<Button size="large" onClick={handleCheckOut} merchant={merchant}>
									<Typography variation="button-small" weight="medium">CHECKOUT</Typography>
								</Button>
							)}
						</S.BottomActionContainer>
					)}
				</S.CartContainer>
			);
	};

	// Render component
	return (
		<S.Wrapper>

			{/* Notices */}
			{showNotices && (
				<S.NoticeContainer>
					{merchant.notices?.map((notice) => (
						<Notice key={notice.id} merchant={merchant}>
							<S.InnerNotice>
								<S.NoticeText>
									{notice.icon}
								</S.NoticeText>
								<S.NoticeText weight="bold" underline>
									{notice.highlight}
								</S.NoticeText>
								<S.NoticeText>
									{notice.content}
								</S.NoticeText>
							</S.InnerNotice>
						</Notice>
					))}
				</S.NoticeContainer>
			)}

			{/* Header */}
			{showTitle && (
				<PageHeader
					title={numberItems > 0 ? `My Cart (${numberItems})` : 'My Cart'}
					subtitle={formatDateString(new Date(), 'MMMM D, YYYY')}
					isSmall
				/>
			)}

			{/* Component */}
			{renderComponent()}
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Cart.displayName = 'Cart';
Cart.propTypes = {
	data: PropTypes.shape(),
	status: PropTypes.oneOf(['idle', 'error', 'loading', 'success']),
	isSmall: PropTypes.bool,
	showNotices: PropTypes.bool,
	showTitle: PropTypes.bool,
	allowActions: PropTypes.bool,
	emptyAction: PropTypes.func,
	showAdditionalCosts: PropTypes.bool,
	merchant: PropTypes.shape()
};
Cart.defaultProps = {
	data: null,
	status: 'success',
	isSmall: false,
	showNotices: true,
	showTitle: true,
	allowActions: true,
	emptyAction: null,
	showAdditionalCosts: false,
	merchant: {}
};
