/*
 * File: dateTime.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:39 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 4:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Constants
import { DEFAULT_TIMEZONE } from '../Constants';

// Plugins
dayjs.extend(timezone);
dayjs.extend(utc);


/**
 * Configuration
 */

TimeAgo.addDefaultLocale(en);


/**
 * Utilities
 */

export const formatToRelativeTime = (date) => {
	const timeAgo = new TimeAgo('en-US');
	return timeAgo.format(date);
};

export const formatDateString = (date, format) => dayjs(date || new Date()).tz(DEFAULT_TIMEZONE).format(format);
