/*
 * File: Error.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta, AppNavigation, ErrorComponent, MarketingNavigation
} from '../../../components';

// Styles
import * as S from './Error.styles';


/**
 * Component
 */

const Error = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Handle render content
	const renderContent = () => (
		<S.Wrapper>
			<ErrorComponent merchant={stateMerchant} />
		</S.Wrapper>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			{stateMerchant.isCore
				? (
					<MarketingNavigation merchant={stateMerchant}>
						{renderContent()}
					</MarketingNavigation>
				)
				: (
					<AppNavigation merchant={stateMerchant}>
						{renderContent()}
					</AppNavigation>
				)}
		</>
	);
};


/**
 * Configuration
 */

Error.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
Error.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default Error;
