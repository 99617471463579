/*
 * File: FollowUp.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 13, 2022 at 4:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button as BaseButton
} from '../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
	max-width: 600px;
	margin: 0 auto;

	.formInput {
		margin: 25px 0 0;
	}

`;

export const FormContainer = styled.div`
	padding: 0px 0px 50px;

	* {
		text-align: left;
	}
`;

export const Form = styled.form``;

export const Button = styled(BaseButton)`
	margin: 40px 0 0 0;
	width: 100%;
`;
