/*
 * File: Constants.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on January 16, 2021 at 9:11 PM
 * Copyright © 2021 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 22, 2023 at 9:34 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

export const ROLES = {
	ALL: 'all',
	STANDARD: 'standard'
};


/**
 * Background Jobs
 */

export const BACKGROUND_JOBS = {
	HANDLE_REVOKABLE_SESSIONS: 'handle_revokable_sessions'
};


/**
 * Available Locales
 */

export const AVAILABLE_LOCALES = ['en'];
export const DEFAULT_LOCALE = 'en';
export const AVAILABLE_I18N_NAMESPACES = {
	TRANSLATIONS: 'translations'
};


/**
 * UI Mode Options
 */

export const UI_MODE_OPTIONS = {
	LIGHT: 'light',
	DARK: 'dark'
};


/**
 * General
 */

export const DEFAULT_TIMEZONE = 'America/New_York';
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/; // Enforce password with at least 8 char with at least 1 lower case, 1 upper case and 1 digit
export const MAX_QUERY_LIMIT = 1000;


/**
 * Cookies
 */

export const SESSION_TOKEN_COOKIE = 'e_s_token';
export const CART_TOKEN_COOKIE = 'e_c_token';
export const LOCALE_COOKIE = 'pl_locale';
export const UI_MODE_COOKIE = 'ui_mode';
export const CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
export const CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
export const CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
export const CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';


/**
 * Admin Email
 */

export const ADMIN_EMAIL = 'platform@seesawmarketplace.com';


/**
 * Cookie Domain
 */

export const COOKIE_DOMAIN = (merchant) => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return `qa.${merchant.domain}`;
		case 'staging':
			return `staging.${merchant.domain}`;
		case 'production':
			if (merchant.redirectToSubdomain) {
				return `${merchant.redirectToSubdomain}.${merchant.domain}`;
			}
			return merchant.domain;
		default:
			return null;
	}
};


/**
 * Headers
 */

export const SESSION_TOKEN_HEADER = 'x-seesaw-market-e-s-token';
export const CART_TOKEN_HEADER = 'x-seesaw-market-e-c-token';
export const CLIENT_PLATFORM_HEADER = 'x-seesaw-market-client-platform';
export const CLIENT_VERSION_HEADER = 'x-seesaw-market-client-version';


/**
 * Expirations
 */

export const SESSION_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const LOCALE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const UI_MODE_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
export const IMPERSONATION_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours
export const RESET_REQUEST_EXPIRATION = 2 * 60 * 60 * 1000; // 2 hours


/**
 * Core Domain Whitelists
 */

export const CORE_DOMAIN_WHITELIST = () => [
	'https://*.seesawmarketplace.com',
	'https://seesaw-marketplace-*.herokuapp.com/',
	process.env.ENV === 'development' && [
		'http://localhost:*'
	]
].filter(Boolean);


/**
 * Alert Types
 */

export const ALERT_STYLES = {
	ERROR: 'error',
	INFO: 'info'
};


/**
 * Error Messages
 */

export const GENERAL_ERROR_MESSAGE = 'Something went wrong, try again later!';


/**
 * Analytics Action Types
 */

export const ANALYTICS_ACTION_TYPES = {
	USER_CREATED: 'user_created',
	USER_CREATED_ACCOUNT: 'user_created_account',
	USER_LOGGED_OUT: 'user_logged_out',
	USER_LOGGED_IN: 'user_logged_in',
	BEGIN_PASS_RESET: 'begin_pass_reset',
	CONTACT_SUBMISSION: 'contact_submission',
	OUTBOUND_URL: 'outbound_url'
};


/**
 * SendGrid Lists
 */

export const LIST_CONTACT_SUBMISSIONS = 'e24e0329-2b38-4cd9-be39-2c0cb45f49ae';


/**
 * State Options
 */

export const STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

export const STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];


/**
 * Email Templates
 */

export const EMAIL_TEMPLATES = {
	MERCHANT_ORDER_EMAIL: 'merchantOrderEmail'
};
