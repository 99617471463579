/*
 * File: Collections.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 12:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { formatDateString } from '../../../../utilities/dateTime';
import { getDataStateForPage } from '../../../utilities/state';
import { createStateLocale } from '../../../utilities/locale';

// Services
import { getGroups } from '../../../services/product';

// Slices
import { pushComponentState } from '../../../store/slices/component/component.slice';

// Components
import {
	Meta, AppNavigation, Notice, PageHeader, ErrorComponent, Spinner, Typography, ProductCard, SchemaScript
} from '../../../components';

// Styles
import * as S from './Collections.styles';


/**
 * Component
 */

const Collections = ({
	meta, locale, merchant, data
}) => {

	// Get state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [componentData, setComponentData] = useState(data || {});

	// Use hooks
	const dispatch = useDispatch();

	// Get current component data state
	const componentState = useSelector((state) => (state.component));

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Initialize data
	const initializeData = async () => {

		// Get data state for page
		getDataStateForPage(componentState, setComponentData, async ({ requiresLoad, isError, data: stateData }) => {
			if (isError) {

				// Update page status
				setPageStatus('error');

			} else if (requiresLoad && pageStatus === 'idle') {

				// Update page status
				setPageStatus('loading');

				// Initialize component data
				try {
					const { data: groupData } = await getGroups(stateMerchant);

					// Set component data
					setComponentData(groupData);

					// Push new component state
					dispatch(pushComponentState(groupData));

					// Update page status
					setPageStatus('success');

				} catch (e) {

					// Update page status
					setPageStatus('error');
				}
			} else if (!requiresLoad && pageStatus === 'idle') {

				// Update page status
				setPageStatus('success');

				// Initialize component data
				setComponentData(stateData);
			}
		});
	};

	// Handle component initialization
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Initialize data
			initializeData();
		}
	}, [componentState, pageStatus]);

	// Render component function
	const renderComponent = () => {

		// Return error component if necessary
		if (pageStatus === 'error') {
			return <ErrorComponent merchant={stateMerchant} />;
		}

		// Return loading component if necessary
		if (pageStatus === 'loading') {
			return <Spinner merchant={stateMerchant} showMeta meta={meta} />;
		}

		// Render component
		return componentData?.groups?.map((group) => (
			<S.CollectionContainer key={group.id}>

				{/* Schema.org */}
				<SchemaScript schema={{
					'@context': 'http://schema.org/',
					'@type': 'ProductGroup',
					name: group.name,
					...group.description ? {
						description: group.description,
					} : undefined,
					variesBy: 'color',
					brand: {
						'@type': 'Brand',
						name: stateMerchant.name
					},
					isFamilyFriendly: true
				}}
				/>

				{/* Collection */}
				<S.CollectionDescription>
					<Typography tag="h2" weight="semibold">{group.name}</Typography>
					{group.description && <Typography tag="p" weight="light">{group.description}</Typography>}
				</S.CollectionDescription>
				<S.ProductContainer>
					{group.products.map((product) => <ProductCard product={product} key={product.id} merchant={stateMerchant} showMarkup />)}
				</S.ProductContainer>
			</S.CollectionContainer>
		));
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} data={data} />

			{/* Component Content */}
			<AppNavigation merchant={stateMerchant}>
				<S.Wrapper>

					{/* Notices */}
					<S.NoticeContainer>
						{stateMerchant.notices.map((notice) => (
							<Notice key={notice.id} merchant={stateMerchant}>
								<S.InnerNotice>
									<S.NoticeText>
										{notice.icon}
									</S.NoticeText>
									<S.NoticeText weight="bold" underline>
										{notice.highlight}
									</S.NoticeText>
									<S.NoticeText>
										{notice.content}
									</S.NoticeText>
								</S.InnerNotice>
							</Notice>
						))}
					</S.NoticeContainer>

					{/* Header */}
					<PageHeader
						title="Welcome, Las Vegas Market!"
						subtitle={formatDateString(new Date(), 'MMMM D, YYYY')}
					/>

					{/* Component */}
					{renderComponent()}

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Collections.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
	data: PropTypes.shape(),
};
Collections.defaultProps = {
	meta: {},
	locale: {},
	merchant: {},
	data: null
};


/**
 * Exports
 */

export default Collections;
