/*
 * File: Review.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:33 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button as BaseButton
} from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
`;

export const StepContainer = styled.div`
	* {
		text-align: left;
	}
	button {
		width: 100%;
	}
`;

export const Button = styled(BaseButton)`
	margin: 30px 0 0 0;
`;

export const BackButton = styled(BaseButton)`
	margin: 5px 0 0 0;
`;

export const DetailContainer = styled.div`
	padding: 0px 0px 20px;
`;

export const DetailWrapper = styled.div`
	margin: 10px 0 10px;
`;
