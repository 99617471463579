/*
 * File: merchant.slice.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 20, 2022 at 4:33 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 24, 2022 at 9:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
	},
};


/**
 * Slices
 */

export const merchantSlice = createSlice({
	name: 'merchant',
	initialState,
	reducers: {
		updateMerchant: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearMerchant: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateMerchant, clearMerchant } = merchantSlice.actions;

export default merchantSlice.reducer;
