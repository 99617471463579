/*
 * File: Overview.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 8, 2023 at 7:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Card } from '../../../../components';

// Constants
import { mediumBreakpoint } from '../../../../styles/constants';

// Colors
import { ColorValuesCreator } from '../../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
    max-width: 900px;
	margin: 0 auto;
`;

export const PoliciesContainer = styled.div`
	padding-top: 10px;
	display: grid;
	gap: 30px;
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	& > a {
		text-decoration: none !important;
	}
`;

export const PolicyCard = styled(Card)`
	position: relative;
	z-index: 2;
	border: 1px solid ${({ theme }) => theme.layoutBorder};

	&:hover {
		box-shadow: ${({ merchant }) => ColorValuesCreator(merchant).elementShadow};
	}
`;

export const TextHolder = styled.div`
	padding: 24px;
	min-height: 220px;

	h3 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Illustration = styled.div`
	height: 240px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	&.legal-cover-1 {
		background-image: url(${process.env.CDN_URL}/public/assets/covers/legal-cover-1.jpg);
	}
	&.legal-cover-2 {
		background-image: url(${process.env.CDN_URL}/public/assets/covers/legal-cover-2.jpg);
	}
	&.legal-cover-3 {
		background-image: url(${process.env.CDN_URL}/public/assets/covers/legal-cover-3.jpg);
	}
	&.legal-cover-4 {
		background-image: url(${process.env.CDN_URL}/public/assets/covers/legal-cover-4.jpg);
	}
`;

export const CTAHolder = styled.div`
	padding: 20px;
	display: inline-block;
	width: 100%;
	margin: 0px 0px -6px 0px;
`;

export const CTALeft = styled.div`
	float: left;

	* {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const CTARight = styled.div`
	float: right;

	* {
		color: ${({ theme }) => theme.secondaryText};
	}
`;
