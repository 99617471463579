/*
 * File: Home.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 13, 2022 at 4:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button as BaseButton } from '../../../components/Button';

// Constants
import { mediumBreakpoint, largeBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	padding: 0px 0px 50px;
	margin: 0 auto;

	.devicesHolderHero {
		width: 829px;
		margin-left: -10px;
		top: 20px;
		-webkit-transform: rotate(-4deg);
		transform: rotate(-4deg);
		transform-origin: 100% 0;
		flex-wrap: wrap;
		pointer-events: none;
		position: absolute;
		display: flex;
	}
	@media only screen {
		.devicesHolderHero {
			display: none;
		}
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		.devicesHolderHero {
			left: 73%;
			display: flex;
		}
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		.devicesHolderHero {
			left: 70%;
			display: flex;
		}
	}
	.devicesHolder {
		div {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			border-radius: 38px;
			background: ${({ theme }) => theme.primaryBackground};
			-webkit-box-shadow: ${({ theme }) => `inset 0 4px 7px 1px ${theme.primaryBackground}, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08)`};
			box-shadow: ${({ theme }) => `inset 0 4px 7px 1px ${theme.primaryBackground}, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.08)`};
		}
		.centered {
			margin: 0px auto;
		}
	}
	.tabletLandscape {
		align-self: flex-end;
		margin-right: 50px;
		width: 512px;
		height: 352px;
	}
	.phoneLarge {
		display: flex;
		width: 267px;
		height: 553px;
	}
	.phoneSmall {
		order: 0;
		margin: 50px 50px 0 0;
		width: 225px;
		height: 467px;
	}
	.tabletPortrait {
		display: flex;
		width: 450px;
		height: 675px;
		margin-top: 50px;
		position: relative;
	}
`;

export const HeroSection = styled.div`
	background: ${({ theme }) => theme.primaryBackground};
	padding: 100px 0px 80px;
	position: relative;
`;

export const ContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin-top: 30px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		max-width: 400px;
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		max-width: 600px;
	}
	* {
		text-align: left;
	}
	p {
		margin-top: 20px;
		font-size: 1.2rem;
	}
	button {
		margin-top: 20px;
	}
`;

export const FeatureSection = styled.div`
	padding: 100px 0px;
	background-color: ${({ theme }) => theme.primaryBackground};
	text-align: center;

	h4 {
		text-transform: uppercase;
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.85rem;
		margin-bottom: 15px;
	}
`;

export const FeatureContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 25px;
	margin-top: 30px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const Feature = styled.div`
	padding: 20px;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: left;

	p {
		text-align: left;
		font-size: 1.2rem;
		margin-top: 5px;
	}
`;

export const MarketBackground = styled.div`
	border-radius: 14px;
	padding: 30px 20px;
	background-image: ${({ CDNURL }) => `url("${CDNURL}/public/assets/backgrounds/market-background-image.jpg")`};
	background-position: center;
	background-size: cover;
`;

export const FeatureContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin: 0px auto 40px;

	p {
		margin-top: 15px;
		font-size: 1.2rem;
	}
`;

export const HighlightContainer = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	border-radius: 14px;
	padding: 10px 30px 40px;
	margin: 20px 0 0;
`;

export const PricingContainer = styled.div`
	display: inline-block;
	margin: 0 auto;
	text-align: center;
	color: ${({ theme }) => theme.secondaryText};

	.percentage {
		font-size: 8.0rem;
		margin-right: 8px;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const FormContainer = styled.div`
	margin: 0 auto;
	max-width: 600px;

	.formInput {
		margin: 25px 0 0;
	}
`;

export const Form = styled.form``;

export const Button = styled(BaseButton)`
	margin: 25px 0 0;
	width: 100%;
`;

export const Video = styled.video`
	top: 0px;
	height: 572px;
`;

export const Overlay = styled.div`
	height: 572px;
	width: 2px;
	background: #faf9f6 !important;
	box-shadow: none !important;
	border-radius: 0px !important;
	position: absolute;
	right: 5px;
`;
