/*
 * File: FollowUp.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 6:57 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 18, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';

// Utilities
import { formatDateString } from '../../../../utilities/dateTime';
import { toastError, toastSuccess } from '../../../utilities/toaster';
import { createStateLocale } from '../../../utilities/locale';

// Services
import { saveContact } from '../../../services/contact';

// Components
import {
	Meta, AppNavigation, PageHeader, Typography, TextInput, TextArea
} from '../../../components';

// Styles
import * as S from './FollowUp.styles';


/**
 * Component
 */

const FollowUp = ({ meta, locale, merchant }) => {

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Get state handlers
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [notesError, setNotesError] = useState(null);
	const [inputValues, setInputValues] = useState({});

	// Handle next action
	const handleNext = async () => {

		// Get parameters
		const { name, email, notes } = inputValues;

		// Validate parameters
		if (name && validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter a name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter an email' });
			return;
		}
		if (notes && validator.isEmpty(notes, { ignore_whitespace: true })) {
			setNotesError({ message: 'Please enter any notes' });
			return;
		}

		// Update submission state
		setIsSubmitting(true);

		// Check out cart
		try {

			// Perform check out
			await saveContact({
				name,
				email,
				notes
			}, stateMerchant);

			// Update submission state
			setIsSubmitting(false);

			// Empty inputs
			setInputValues({});

			// Show success message
			toastSuccess(uiMode, stateMerchant, 'Success! We\'ve saved your follow up.');

		} catch (e) {

			// Update submission state
			setIsSubmitting(false);

			// Show error message
			toastError(uiMode, stateMerchant, 'We\'re having trouble saving your contact info. Give it another go.');
		}
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};


	// Render component function
	const renderComponent = () => (
		<S.FormContainer>
			<S.Form>

				{/* Inputs */}
				<TextInput
					label="Your name"
					placeholder="e.g. Beyoncé Knowles"
					name="name"
					type="text"
					error={nameError}
					value={inputValues.name || ''}
					containerClassName="formInput"
					onFocus={() => { setNameError(null); }}
					onKeyUp={() => { setNameError(null); }}
					onBlur={() => { setNameError(null); }}
					onChange={handleOnChange}
					autocomplete="off"
					autocorrect="off"
					spellcheck="false"
				/>
				<TextInput
					label="Your email"
					name="email"
					type="email"
					placeholder="e.g. hello@acme.com"
					error={emailError}
					value={inputValues.email || ''}
					containerClassName="formInput"
					onFocus={() => { setEmailError(null); }}
					onKeyUp={() => { setEmailError(null); }}
					onBlur={() => { setEmailError(null); }}
					onChange={handleOnChange}
					autocorrect="off"
					autocapitalize="off"
					spellcheck="false"
				/>
				<TextArea
					label="Notes"
					placeholder="Add any notes here..."
					name="notes"
					type="text"
					rows={6}
					error={notesError}
					value={inputValues.notes || ''}
					containerClassName="formInput"
					onFocus={() => { setNotesError(null); }}
					onKeyUp={() => { setNotesError(null); }}
					onBlur={() => { setNotesError(null); }}
					onChange={handleOnChange}
				/>

				{/* Actions */}
				<S.Button size="large" onClick={handleNext} disabled={isSubmitting} merchant={stateMerchant}>
					<Typography variation="button-small" weight="medium">SAVE</Typography>
				</S.Button>
			</S.Form>
		</S.FormContainer>
	);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={{}} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<AppNavigation merchant={stateMerchant}>
				<S.Wrapper>

					{/* Header */}
					<PageHeader
						title="Follow Up"
						subtitle={formatDateString(new Date(), 'MMMM D, YYYY')}
					/>

					{/* Component */}
					{renderComponent()}

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

FollowUp.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
FollowUp.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default FollowUp;
