/*
 * File: ShippingDetails.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:33 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: August 13, 2022 at 4:06 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button as BaseButton
} from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
`;

export const StepContainer = styled.div`
	button {
		width: 100%;
	}
`;

export const Form = styled.form``;

export const Button = styled(BaseButton)`
	margin: 40px 0 0 0;
`;

export const BackButton = styled(BaseButton)`
	margin: 5px 0 0 0;
`;
