/*
 * File: ProductDetail.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 12:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { getDataStateForPage } from '../../../utilities/state';
import { toastError } from '../../../utilities/toaster';
import { formatCost } from '../../../utilities/cost';
import { scrollPageTo } from '../../../utilities/position';
import { formatOfferDate } from '../../../utilities/product';
import { buildDynamicMeta } from '../../../components/Meta';
import { createStateLocale } from '../../../utilities/locale';

// Services
import { updateCart } from '../../../services/cart';
import { getProduct } from '../../../services/product';

// Slices
import { pushComponentState } from '../../../store/slices/component/component.slice';
import { updateCart as updateCartSlice } from '../../../store/slices/cart/cart.slice';

// Components
import {
	Meta, AppNavigation, PageHeader, ErrorComponent, Spinner, ProductImage, Typography, SuggestedProducts, SchemaScript
} from '../../../components';

// Styles
import * as S from './ProductDetail.styles';


/**
 * Component
 */

const ProductDetail = ({
	meta, locale, merchant, data
}) => {

	// Get component parameters
	const { slug } = useParams();

	// Get state handlers
	const [pageStatus, setPageStatus] = useState('idle');
	const [componentData, setComponentData] = useState(data || { product: { name: 'Product' } });

	// Create references
	const navigationRef = useRef();

	// Use hooks
	const dispatch = useDispatch();

	// Get current component data state
	const componentState = useSelector((state) => (state.component));

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle add to cart function
	const addToCart = async (e) => {

		// Prevent default action
		e.preventDefault();

		// Update cart
		try {

			// Get product
			const product = componentData?.product;

			// Update cart
			const { data: { cart } } = await updateCart({
				...!product.isGroup ? {
					products: [{ id: product.id, add: 1 }]
				} : {
					groups: [{ id: product.id, add: 1 }]
				}
			}, stateMerchant);

			// Update cart
			dispatch(updateCartSlice(cart));

			// Open cart sidebar
			navigationRef.current.openCart();

		} catch (error) {

			// Show error message
			toastError(uiMode, stateMerchant, 'We\'re having trouble adding this product to your cart. Give it another go.');
		}
	};

	// Initialize data
	const initializeData = async () => {

		// Get data state for page
		getDataStateForPage(componentState, setComponentData, async ({ requiresLoad, isError, data: stateData }) => {
			if (isError) {

				// Update page status
				setPageStatus('error');

			} else if (requiresLoad && pageStatus === 'idle') {

				// Update page status
				setPageStatus('loading');

				// Initialize component data
				try {
					const { data: productData } = await getProduct(slug, stateMerchant);

					// Set component data
					setComponentData(productData);

					// Push new component state
					dispatch(pushComponentState(productData));

					// Update page status
					setPageStatus('success');

				} catch (e) {

					// Update page status
					setPageStatus('error');
				}
			} else if (!requiresLoad && pageStatus === 'idle') {

				// Update page status
				setPageStatus('success');

				// Initialize component data
				setComponentData(stateData);
			}
		});
	};

	// Handle component initialization
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Initialize data
			initializeData();
		}
	}, [componentState, pageStatus]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Only change if component state finalized
		if (componentState && componentState.status === 'final') {

			// Set page status if necessary
			if (pageStatus !== 'idle') {

				// Reset page status
				setPageStatus('idle');

			} else {

				// Initialize data
				initializeData();
			}
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [slug]);

	// Render component function
	const renderComponent = () => {

		// Return error component if necessary
		if (pageStatus === 'error') {
			return <ErrorComponent merchant={stateMerchant} />;
		}

		// Return loading component if necessary
		if (pageStatus === 'loading') {
			return <Spinner merchant={stateMerchant} showMeta meta={meta} />;
		}

		// Create merchant domain
		let merchantDomain = `https://${stateMerchant.domain}`;
		if (stateMerchant.redirectToSubdomain) {
			merchantDomain = `https://${stateMerchant.redirectToSubdomain}.${stateMerchant.domain}`;
		}

		// Render component
		return (
			<>
				{/* Schema.org */}
				<SchemaScript schema={{
					'@context': 'http://schema.org/',
					'@type': 'Product',
					'@id': `${merchantDomain}/product/${componentData?.product?.slug}/#Product`,
					name: componentData?.product?.name,
					url: `${merchantDomain}/product/${componentData?.product?.slug}`,
					image: [
						componentData?.product?.image
					],
					description: componentData?.product?.description,
					brand: {
						'@type': 'Brand',
						name: stateMerchant.name
					},
					mpn: '',
					gtin: '',
					sku: componentData?.product?.sku,
					productID: componentData?.product?.slug,
					category: componentData?.product?.typeName,
					isFamilyFriendly: true,
					offers: {
						'@type': 'Offer',
						availability: 'http://schema.org/InStock',
						price: formatCost(componentData?.product?.totalCost),
						priceCurrency: 'USD',
						itemCondition: 'https://schema.org/NewCondition',
						priceValidUntil: formatOfferDate(),
						url: `${merchantDomain}/product/${componentData?.product?.slug}`
					},
					potentialAction: [
						{
							'@type': 'BuyAction',
							target: `${merchantDomain}/product/${componentData?.product?.slug}`,
						},
						{
							'@type': 'ReviewAction',
							target: `${merchantDomain}/product/${componentData?.product?.slug}`,
						},
						{
							'@type': 'ShareAction',
							target: `${merchantDomain}/product/${componentData?.product?.slug}`,
						}
					]
				}}
				/>
				<SchemaScript schema={{
					'@context': 'http://schema.org/',
					'@type': 'ItemPage',
					'@id': `${merchantDomain}/product/${componentData?.product?.slug}/#WebPage`,
					name: buildDynamicMeta(meta.title, componentData),
					description: buildDynamicMeta(meta.description, componentData),
					url: `${merchantDomain}/product/${componentData?.product?.slug}`,
					inLanguage: stateLocale.localeBaseId,
					isPartOf: {
						'@id': `${merchantDomain}/#WebSite`
					},
					publisher: {
						'@id': `${stateMerchant.website}/#Organization`,
					},
					mainEntity: {
						'@id': `${merchantDomain}/product/${componentData?.product?.slug}/#Product`,
					}
				}}
				/>

				{/* Header */}
				<PageHeader
					title={componentData?.product?.name}
					subtitle={componentData?.product?.typeName}
				/>

				{/* Product */}
				<S.ProductWrapper>

					{/* Image */}
					<ProductImage image={componentData?.product?.image} ratio={0.7} />

					{/* Details */}
					<S.DetailContainer>

						{/* Purchase Details */}
						<S.LeftDetail>
							<Typography tag="p" variation="3" weight="light" className="secondary">
								{stateMerchant.name.toUpperCase()}
							</Typography>
							<Typography tag="h3" weight="semibold">
								{componentData?.product?.name}
							</Typography>
							<Typography tag="p" weight="light">
								Wholesale Price - $
								{formatCost(componentData?.product?.totalCost)}
							</Typography>
							<S.ButtonContainer>
								<S.Button onClick={addToCart} merchant={stateMerchant}>
									<Typography variation="button-small" weight="medium">ADD TO CART</Typography>
								</S.Button>
							</S.ButtonContainer>
							<S.MadeWith>
								<Typography tag="p" variation="2" weight="light" className="fullWidth">
									Made with
									{' '}
									<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
									{' '}
									in
									{' '}
									{stateMerchant.address?.city}
									,
									{' '}
									{stateMerchant.address?.state}
								</Typography>
							</S.MadeWith>
						</S.LeftDetail>

						{/* Product Details */}
						<S.RightDetail>
							<Typography tag="p" weight="light">{componentData?.product?.description}</Typography>
						</S.RightDetail>
					</S.DetailContainer>

					{/* Suggested Products */}
					<SuggestedProducts product={componentData?.product} />

				</S.ProductWrapper>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} data={componentData} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<AppNavigation ref={navigationRef} merchant={stateMerchant}>
				<S.Wrapper>
					{renderComponent()}
				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

ProductDetail.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
	data: PropTypes.shape(),
};
ProductDetail.defaultProps = {
	meta: {},
	locale: {},
	merchant: {},
	data: null
};


/**
 * Exports
 */

export default ProductDetail;
