/*
 * File: ContactPreferences.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on November 18, 2022 at 3:58 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: May 18, 2023 at 12:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';
import { parseFirstLastName } from '../../../../utilities/utilities';

// Services
import { validateGeneralAccessAttempt } from '../../../services/user';

// Components
import {
	Meta, AppNavigation, MarketingNavigation, Spinner, ErrorComponent, NotificationSettings, Typography
} from '../../../components';

// Styles
import * as S from './ContactPreferences.styles';


/**
 * Component
 */

const ContactPreferences = ({ meta, locale, merchant }) => {

	// Get component parameters
	const { token } = useParams();

	// Create state handlers
	const [componentData, setComponentData] = useState({});
	const [pageStatus, setPageStatus] = useState('idle');

	// Create references for components
	const isMounted = useRef(true);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Initialize component data function
	const validateAccessAttempt = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch data
			const { data } = await validateGeneralAccessAttempt({ token });

			// Ensure component is mounted
			if (isMounted.current) {

				// Set new data state
				setComponentData(data);

				// Update page status
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Validate reset password attempt
		validateAccessAttempt();

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle render content
	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner merchant={stateMerchant} showMeta meta={meta} />;
			case 'error':
				return <ErrorComponent locale={stateLocale} merchant={stateMerchant} />;
			case 'success':
				return (
					<S.Form>

						{/* Content */}
						<Typography tag="h2" weight="bold">
							Hi,
							{' '}
							{parseFirstLastName(componentData?.user.name)?.firstName}
							!
						</Typography>
						<Typography tag="p" variation="1" weight="regular">Update the kinds of notifications you want to receive from Seesaw Marketplace.</Typography>

						{/* Notification Settings */}
						<S.SettingsWrapper>
							<NotificationSettings
								accessToken={token}
								preferences={componentData?.user.notificationPreferences}
							/>
						</S.SettingsWrapper>
					</S.Form>
				);
			default:
				return null;
		}
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			{stateMerchant.isCore
				? (
					<MarketingNavigation merchant={stateMerchant}>
						<S.Wrapper>
							{renderContent()}
						</S.Wrapper>
					</MarketingNavigation>
				)
				: (
					<AppNavigation merchant={stateMerchant}>
						<S.Wrapper>
							{renderContent()}
						</S.Wrapper>
					</AppNavigation>
				)}
		</>
	);
};


/**
 * Configuration
 */

ContactPreferences.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
ContactPreferences.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default ContactPreferences;
