/*
 * File: ShippingDetails.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:33 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 28, 2023 at 12:28 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';

// Slices
import { updateCheckout } from '../../../../store/slices/checkout/checkout.slice';

// Components
import {
	PageHeader, Select, TextArea, TextInput, Typography
} from '../../../../components';

// Constants
import { STATE_OPTIONS } from '../../../../../Constants';

// Styles
import * as S from './ShippingDetails.styles';


/**
 * Component
 */

export const ShippingDetails = forwardRef(({
	className, updateStep, updateHeight, isVisible, merchant
}, ref) => {

	// Get dispatch
	const dispatch = useDispatch();

	// Get current check out flow
	const currentFlow = useSelector((state) => state.checkout.value);

	// Get state handlers
	const [addressLine1Error, setAddressLine1Error] = useState(null);
	const [addressLine2Error, setAddressLine2Error] = useState(null);
	const [cityError, setCityError] = useState(null);
	const [stateError, setStateError] = useState(null);
	const [postalCodeError, setPostalCodeError] = useState(null);
	const [notesError, setNotesError] = useState(null);
	const [inputValues, setInputValues] = useState({});

	// Handle next action
	const handleNext = () => {

		// Get parameters
		const {
			addressLine1,
			addressLine2,
			city,
			state,
			postalCode,
			notes
		} = inputValues;

		// Validate parameters
		if (!addressLine1 || validator.isEmpty(addressLine1, { ignore_whitespace: true })) {
			setAddressLine1Error({ message: 'Please enter your shipping address' });
			return;
		}
		if (!city || validator.isEmpty(city, { ignore_whitespace: true })) {
			setCityError({ message: 'Please enter the city' });
			return;
		}
		if (!state || validator.isEmpty(state, { ignore_whitespace: true })) {
			setStateError({ message: 'Please enter the state' });
			return;
		}
		if (!postalCode || !validator.isPostalCode(postalCode, 'US')) {
			setPostalCodeError({ message: 'Please enter the ZIP code' });
			return;
		}

		// Update checkout flow
		dispatch(updateCheckout({
			currentStep: 3,
			data: {
				...currentFlow.data,
				addressLine1,
				addressLine2,
				city,
				state,
				postalCode,
				notes
			}
		}));

		// Move to next step
		updateStep(3);
	};

	// Handle back action
	const handleBack = () => {

		// Move to previous step
		updateStep(1);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle update on flow change
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [currentFlow]);

	// Handle update on visibility change
	useEffect(() => {

		// Update height
		updateHeight(true);

		// If component is visible, update input values
		if (isVisible === true) {
			setInputValues({
				addressLine1: currentFlow.data.addressLine1,
				addressLine2: currentFlow.data.addressLine2,
				city: currentFlow.data?.city,
				state: currentFlow.data.state,
				postalCode: currentFlow.data.postalCode,
				notes: currentFlow.data.notes,
			});
		}
	}, [isVisible]);

	// Render component
	return (
		<S.Wrapper ref={ref}>
			<div className="stepSpacing">

				{/* Header */}
				<PageHeader
					title="Shipping Details"
					subtitle={merchant.name}
					isSmall
				/>

				{/* Step Container */}
				<S.StepContainer className={className}>
					<S.Form>

						{/* Inputs */}
						<TextInput
							label="Address"
							placeholder="e.g. 123 Acme Street"
							name="addressLine1"
							type="text"
							error={addressLine1Error}
							value={inputValues.addressLine1 || ''}
							containerClassName="formInput"
							onFocus={() => { setAddressLine1Error(null); }}
							onKeyUp={() => { setAddressLine1Error(null); }}
							onBlur={() => { setAddressLine1Error(null); }}
							onChange={handleOnChange}
						/>
						<TextInput
							label="Apartment, suite, etc."
							placeholder="e.g. Suite 402"
							name="addressLine2"
							type="text"
							error={addressLine2Error}
							value={inputValues.addressLine2 || ''}
							containerClassName="formInput"
							onFocus={() => { setAddressLine2Error(null); }}
							onKeyUp={() => { setAddressLine2Error(null); }}
							onBlur={() => { setAddressLine2Error(null); }}
							onChange={handleOnChange}
						/>
						<TextInput
							label="City"
							placeholder="e.g. Las Vegas"
							name="city"
							type="text"
							error={cityError}
							value={inputValues?.city || ''}
							containerClassName="formInput"
							onFocus={() => { setCityError(null); }}
							onKeyUp={() => { setCityError(null); }}
							onBlur={() => { setCityError(null); }}
							onChange={handleOnChange}
						/>
						<Select
							label="State"
							name="state"
							placeholder="e.g. Nevada"
							options={STATE_OPTIONS.map((stateOption) => ({
								value: stateOption.abbreviation,
								label: stateOption.name
							}))}
							error={stateError}
							values={Array.isArray(inputValues.state) ? inputValues.state : [inputValues.state || '']}
							containerClassName="formInput"
							onFocus={() => { setStateError(null); }}
							onKeyUp={() => { setStateError(null); }}
							onBlur={() => { setStateError(null); }}
							onChange={handleOnChange}
							merchant={merchant}
						/>
						<TextInput
							label="ZIP / Postal code"
							placeholder="e.g. 88901"
							name="postalCode"
							type="text"
							error={postalCodeError}
							value={inputValues.postalCode || ''}
							containerClassName="formInput"
							onFocus={() => { setPostalCodeError(null); }}
							onKeyUp={() => { setPostalCodeError(null); }}
							onBlur={() => { setPostalCodeError(null); }}
							onChange={handleOnChange}
						/>
						<TextArea
							label="Notes"
							placeholder="Add any notes here..."
							name="notes"
							type="text"
							rows={6}
							error={notesError}
							value={inputValues.notes || ''}
							containerClassName="formInput"
							onFocus={() => { setNotesError(null); }}
							onKeyUp={() => { setNotesError(null); }}
							onBlur={() => { setNotesError(null); }}
							onChange={handleOnChange}
						/>

						{/* Actions */}
						<S.Button size="large" onClick={handleNext} merchant={merchant}>
							<Typography variation="button-small" weight="medium">CONTINUE TO PAYMENT</Typography>
						</S.Button>
						<S.BackButton size="large" variant="text" onClick={handleBack}>
							<Typography variation="button-small" weight="medium">BACK TO DETAILS</Typography>
						</S.BackButton>
					</S.Form>
				</S.StepContainer>
			</div>
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

ShippingDetails.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	isVisible: PropTypes.bool,
	merchant: PropTypes.shape()
};
ShippingDetails.defaultProps = {
	className: null,
	updateHeight: null,
	isVisible: false,
	merchant: {}
};
