/*
 * File: PrivacyPolicy.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 8, 2023 at 7:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, MarketingNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './PrivacyPolicy.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const PrivacyPolicy = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation hidePadding merchant={stateMerchant}>
				<LegalNavigation merchant={stateMerchant}>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Privacy Policy
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								By using the Seesaw Marketplace platform, you understand that we gather and securely store certain information about you that applies to your use of Seesaw Marketplace. This includes profile information you provide to us, content you create, and automated data like IP addresses and device identifiers. We use this information to provide a customized experience, improve our technology, and allow trusted third-parties to advertise to you. Please read the “Your Choices” section below to learn more about ways you can monitor what data we collect about you.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							Seesaw Marketplace understands you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our privacy practices. By using or accessing the Services in any manner, you acknowledge the practices and policies outlined in this Privacy Policy, and that we will collect, use, and share your information in the following ways.
						</Typography>
						<Typography tag="p" weight="light">
							Remember that your use of Seesaw Marketplace’s Services is subject to the
							{' '}
							<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
							, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the
							{' '}
							<LocaleLink to="/legal/terms" target="_blank">Terms of Service</LocaleLink>
							.
						</Typography>

						{/* What does this Privacy Policy cover? */}
						<br />
						<Typography tag="h2" weight="semibold">
							What does this Privacy Policy cover?
						</Typography>
						<Typography tag="p" weight="light">
							This Privacy Policy applies to seesawmarketplace.com, and all other websites, applications, widgets, products, services, and other offerings (collectively, the “Services”) owned and operated by Seesaw Technologies, LLC. and our affiliates and subsidiaries (collectively, “Seesaw”) that link to this Privacy Policy. This Policy covers our treatment of the personal information we gather through use of our Services.
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Technologies, LLC (Raleigh, NC 27613, USA) is the data controller for the personal information collected in connection with use of Seesaw Marketplace’s services worldwide.
						</Typography>

						{/* What Information does Seesaw Marketplace Collect? */}
						<br />
						<Typography tag="h2" weight="semibold">
							What Information does Seesaw Marketplace Collect?
						</Typography>
						<Typography tag="p" weight="light">
							We collect information about your use of our Services. We also gather various types of personal information, as explained in more detail below.
						</Typography>

						{/* Information You Provide to Us */}
						<Typography tag="h3" weight="semibold">
							Information You Provide to Us
						</Typography>
						<Typography tag="p" weight="light">
							We receive and store any information you knowingly provide to us. If you create an account or place an order with us, we may collect personal information such as:
						</Typography>

						{/* Retailers */}
						<Typography tag="h4" weight="semibold">
							Retailers
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Account Registration.</Typography>
							{' '}
							When you register as a Retailer with Seesaw Marketplace, we will collect your first and last name, email address, phone number, business address, website, store name, store address, years you have been in business, and projected sales figures.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Order Fulfillment.</Typography>
							{' '}
							If you place an order to purchase goods from Brands through Seesaw Marketplace, we will also collect information to facilitate that order, such as your shipping address, Tax Identification Number, and payment information.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Identity Verification.</Typography>
							{' '}
							In some cases, we may request additional information to validate your business, such as photos of your store or your company’s social media profiles. In some cases, we may request information such as your tax identifier or other government issued numbers or financial or lease records to establish your account limits.
						</Typography>

						{/* Brands */}
						<Typography tag="h4" weight="semibold">
							Brands
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Account Registration.</Typography>
							{' '}
							When you register as a Brand with Seesaw Marketplace, we will collect your first and last name, brand name, website, address, phone number, and information about your brand such as the primary category of products you provide, the countries you operate in, and your social media profiles.
						</Typography>
						<Typography tag="p" weight="light">
							If you are accepted as a Brand for Seesaw Marketplace, we may ask for additional information to build out your profile such as your profile photo, wholesale pricing and order requirements, and years you have been established.
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace collects demographic information including race, ethnic origin, disability and sexual orientation to allow Brands to indicate their affinities and values on their profile. This information is entirely optional.
						</Typography>
						<Typography tag="p" weight="light">
							Optionally, you may choose to provide information about your existing retail customers to refer to Seesaw Marketplace, such as their name, store name, and email address.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Order Fulfillment.</Typography>
							{' '}
							When you receive an order through Seesaw Marketplace, we will collect information to facilitate that order, such as your Tax Identification Number, tax class, and bank account information so we can pay you.
						</Typography>

						{/* Using the Services */}
						<Typography tag="h3" weight="semibold">
							Using the Services
						</Typography>
						<Typography tag="p" weight="light">
							When you browse and use our Services, we collect information about the items you browse, show interest in, and buy. Some of our Services allow you to create audio-visual content, which can be streamed live to other Seesaw Marketplace users in some cases. Depending on the functionalities, these Services may access the camera and/or microphone on your device and record your image and voice.
						</Typography>

						{/* Communications with Us */}
						<Typography tag="h3" weight="semibold">
							Communications with Us
						</Typography>
						<Typography tag="p" weight="light">
							We collect personal information from you when you communicate with us, for example when you request information about our services, contact our Support team, apply for a job, or register for our newsletter.
						</Typography>

						{/* Surveys, Sweepstakes, and Contests */}
						<Typography tag="h3" weight="semibold">
							Surveys, Sweepstakes, and Contests
						</Typography>
						<Typography tag="p" weight="light">
							We may ask you to participate in optional surveys, where you may be asked to provide personal information.
						</Typography>
						<Typography tag="p" weight="light">
							Additionally, we may conduct sweepstakes or contests where you may be asked to provide personal information. This information may be used to contact you about the sweepstakes or contest, and for other purposes as permitted by law, including promotional and marketing purposes. Additionally, note that some jurisdictions require us to publicly share information about sweepstakes and contest winners.
						</Typography>

						{/* Forums and Social Media */}
						<Typography tag="h3" weight="semibold">
							Forums and Social Media
						</Typography>
						<Typography tag="p" weight="light">
							Our Services may provide forums, blogs, social media pages, or other channels where individuals may review purchases, talk about their experience using our Services, or “like” or “share” content to social media. Note that content provided for and posted in these channels, including by you, is public and that the processing of your information might be subject to the policies and notices of third parties that make such channels available or index such content.
						</Typography>

						{/* Information Collected Automatically */}
						<br />
						<Typography tag="h2" weight="semibold">
							Information Collected Automatically
						</Typography>
						<Typography tag="p" weight="light">
							Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or device, which may include your IP address, geolocation data, device identification, the type of browser and/or device you’re using to access our Services, and the page or feature you requested.
						</Typography>
						<Typography tag="p" weight="light">
							Additionally, we, as well as third parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel tags, local storage, and other technologies to collect information automatically through our Services.
						</Typography>
						<Typography tag="p" weight="light">
							These technologies are small data files that we transfer to your browser or device that allows us to provide and enhance our Services. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of these technologies, but this may prevent you from taking advantage of some of our features.
						</Typography>
						<Typography tag="p" weight="light">
							Please refer to our dedicated Cookie Policy for more information on how we use cookies and similar automatic collection technologies when providing our Services.
						</Typography>

						{/* Analytics */}
						<Typography tag="h3" weight="semibold">
							Analytics
						</Typography>
						<Typography tag="p" weight="light">
							We may use analytics service providers, including Google Analytics, to collect information regarding visitors to our Services, such as their behavior on our Services or information about their demographic. For more information about Google Analytics, see
							{' '}
							<LocaleLink to="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/</LocaleLink>
							. To opt out of Google Analytics, visit
							{' '}
							<LocaleLink to="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</LocaleLink>
							.
						</Typography>

						{/* Do-Not-Track */}
						<Typography tag="h3" weight="semibold">
							Do-Not-Track
						</Typography>
						<Typography tag="p" weight="light">
							Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. At this time, we do not respond to nor honor Do-Not-Track signals from web browsers.
						</Typography>

						{/* Information Collected from Third Parties */}
						<Typography tag="h3" weight="semibold">
							Information Collected from Third Parties
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Referral Programs.</Typography>
							{' '}
							We may collect information about you through a referral program, such as when a Seesaw Marketplace Retailer or Brand refers one of their existing customers, leads, or partners to Seesaw Marketplace. If you have been referred to Seesaw Marketplace but wish to opt out of emails from us, please contact us as explained below.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Single Sign On.</Typography>
							{' '}
							If you access our Services through a third party or social networking site, we may collect information about you that has been made available via your privacy settings with that third party. For example, if you register for Seesaw Marketplace with Facebook, we may collect your name, profile ID, location, gender, profile picture, and list of your friends.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Third-Party Integrations.</Typography>
							{' '}
							We may offer third-party integrations. If you choose to integrate third-party applications with Seesaw Marketplace, we will collect personal information as part of that integration. Because each third-party service you may choose to integrate collects different personal information, the detail in which information will be collected will be explained during the integration setup process. For more information on integrations, please contact us as described below.
						</Typography>
						<Typography tag="p" weight="light">
							With your explicit consent, Seesaw Marketplace uses Stripe, Inc. (“Stripe”) to gather data from financial institutions. By using our Services, you grant Seesaw Marketplace and Stripe the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Stripe in accordance with the Stripe Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Other Sources.</Typography>
							{' '}
							We may receive information about you from other sources, including to supplement the information we have collected about you. In some circumstances, we may receive payment performance information to evaluate your eligibility for extended payment terms.
						</Typography>

						{/* How Does Seesaw Marketplace Use the Personal Information It Collects? */}
						<br />
						<Typography tag="h2" weight="semibold">
							How Does Seesaw Marketplace Use the Personal Information It Collects?
						</Typography>
						<Typography tag="p" weight="light">
							We use the information we collect about you for various business purposes as described below.
						</Typography>

						{/* To provide you with our Services */}
						<Typography tag="h4" weight="semibold">
							To provide you with our Services
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Enter into a contract with you</li>
								<li>Allow you to set up a user account and profile</li>
								<li>Fulfill your requests for products and services</li>
								<li>Allow you to create and share content with others</li>
								<li>Communicate with you about your account and updates to our Services</li>
								<li>Determine your order or account limits and eligibility for extended payment terms</li>
								<li>Process applications, transactions, and payments</li>
								<li>Allow you to register for events</li>
								<li>Facilitate communications between you and other users</li>
							</ul>
						</Typography>

						{/* For our legitimate business interests */}
						<Typography tag="h4" weight="semibold">
							For our legitimate business interests
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Analyze how you use the Services</li>
								<li>Understand user interest and engagement on the Services</li>
								<li>Engage in marketing or sales outreach</li>
								<li>Provide customized content, offers, or Services, including marketing content via email, postal mail, social media, SMS, or other channels, subject to applicable laws</li>
								<li>Research and develop Services</li>
								<li>Improve and streamline our Services</li>
								<li>Verify your identity and prevent fraud</li>
								<li>Detect security incidents; protect against malicious, deceptive, fraudulent, or illegal activity</li>
								<li>Ensure quality control</li>
								<li>Debug to identify and repair errors</li>
								<li>Enforce our Terms and policies</li>
								<li>Audit or other compliance activities</li>
							</ul>
						</Typography>
						<Typography tag="p" weight="light">
							You can obtain further information on the legitimate interest balancing exercise on request by reaching out to us via the context details below, to the extent this information is not contained in this Privacy Policy.
						</Typography>

						{/* To comply with our legal obligations */}
						<Typography tag="h4" weight="semibold">
							To comply with our legal obligations
						</Typography>
						<Typography tag="p" weight="light">
							We retain and use your information in connection with potential legal claims, and for compliance, regulatory and auditing purposes. For example, we retain information where we are required by law, or if we are compelled to do so by a court order or regulatory body. Also, when you exercise any applicable legal rights you have to access, amend or delete your personal information, we may request identification and verification documents from you for the purpose of confirming your identity.
						</Typography>

						{/* With your consent */}
						<Typography tag="h4" weight="semibold">
							With your consent
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace may collect and use personal information with your consent. You can revoke your consent at any time (either through our Services directly or by contacting us via
							{' '}
							<LocaleLink to="mailto:legal@seesawmarketplace.com?Subject=Privacy Policy">legal@seesawmarketplace.com</LocaleLink>
							), though note that you might not be able to use any service or feature that requires collection or use of that personal information.
						</Typography>

						{/* How We Use Sensitive Personal Data */}
						<Typography tag="h3" weight="semibold">
							How We Use Sensitive Personal Data
						</Typography>
						<Typography tag="p" weight="light">
							As noted above, Seesaw Marketplace collects optional information about Brands’ affinities and values, including sensitive demographic data such as race, ethnic origin, and sexual orientation. We display this information in your profile if you choose to include it. We may also reach out to ask you if you want to be featured in Seesaw Marketplace communications relating to affinities or values or on our social media pages, or if you want to be featured in our “Community” pages, which allow Retailers to browse Brands with particular value tags, e.g., Black-owned brands.
						</Typography>

						{/* Automated Decision-Making */}
						<Typography tag="h3" weight="semibold">
							Automated Decision-Making
						</Typography>
						<Typography tag="p" weight="light">
							Seesaw Marketplace does not engage in automated decision-making that has legal or similarly significant effect on individuals.
						</Typography>

						{/* De-Identified, Aggregated and Anonymized Information */}
						<Typography tag="h3" weight="semibold">
							De-Identified, Aggregated and Anonymized Information
						</Typography>
						<Typography tag="p" weight="light">
							We use personal information to create de-identified, aggregated or anonymized information such as: information about demographics, de-identified location information, information about devices used to access our Services, and de-identified, aggregate or anonymized information on transactions on our Services that help our users understand and optimize sales.
						</Typography>

						{/* Third-Party Websites */}
						<Typography tag="h3" weight="semibold">
							Third-Party Websites
						</Typography>
						<Typography tag="p" weight="light">
							We may offer links to third-party websites or Services, which are not controlled by us and not subject to the protections laid out in this Privacy Policy. We do not endorse, nor do we approve any third-party website and we encourage our users to read the privacy policy of each service they interact with.
						</Typography>

						{/* APIs and SDKs */}
						<Typography tag="h3" weight="semibold">
							APIs and SDKs
						</Typography>
						<Typography tag="p" weight="light">
							We may use third-party APIs and software development kits (“SDKs”), which may allow third parties to collect personal information about you for various purposes, such as to conduct analytics, verify your business when you register with Seesaw Marketplace as a Retailer or Brand, provide customized content, or otherwise streamline the Services. For more information about our use of APIs and SDKs, contact us as described below.
						</Typography>

						{/* Will Seesaw Marketplace Disclose Any of the Personal Information It Receives? */}
						<br />
						<Typography tag="h2" weight="semibold">
							Will Seesaw Marketplace Disclose Any of the Personal Information It Receives?
						</Typography>
						<Typography tag="p" weight="light">
							We “share” information as defined by California law. We do not rent nor sell your personal information in personally identifiable form to anyone. Additionally, we disclose personal information as described below.
						</Typography>
						<Typography tag="p" weight="light">
							We may disclose your personal information with third parties as described in this section:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Service Providers.</Typography>
							{' '}
							We employ service providers to perform tasks on our behalf, and we need to disclose your information with them in order to provide products or services to you; for example, we may use a payment processing company to receive and process any credit card transactions for us.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">When You Request That We Disclose Your Information.</Typography>
							{' '}
							We may offer Services, features, or promotions that involve disclosing your information to a third party or with other Seesaw Marketplace users. If you request or agree to have your information disclosed to a third party as part of a feature or Service, we will disclose that information at your request.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Credit Evaluation.</Typography>
							{' '}
							If you are extended payment terms, we may disclose aggregated payment performance information to third-party service providers that report on payment performance to Seesaw Marketplace and other businesses.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">De-Identified Information.</Typography>
							{' '}
							We may provide de-identified, aggregate or anonymized information to our partners, including usage information to help our partners understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal online experience. Before we disclose this information, we take reasonable efforts aligned with industry best practices to ensure that this information cannot identify you.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Advertising on the Seesaw Marketplace Platform.</Typography>
							{' '}
							We may allow advertisers and/or merchant partners (“Advertisers”) to choose the demographic information of users who will see their advertisements and/or promotional offers. You agree that we may provide any of the information we have collected from you in non-personally identifiable form to an Advertiser, in order for that Advertiser to select the appropriate audience for those advertisements and/or offers. For example, we might use the fact you are located in San Francisco to show you ads or offers for San Francisco businesses, but we will not tell such businesses who you are. Or, we might allow Advertisers to display their ads to users with similar usage patterns to yours, but we will not disclose usage information to Advertisers except in aggregate form, and not in a manner that would identify you personally. Note that if an advertiser asks us to show an ad to a certain audience or audience segment and you respond to that ad, the advertiser may conclude that you fit the description of the audience they were trying to reach.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Third-party Advertising.</Typography>
							{' '}
							We want to make sure that our advertising is relevant and interesting to you and our other users. To achieve this, we use specialized third-party advertising companies, including companies which collect data about you in order to build a profile of your preferences based on your activities when you visit or use the Services. We also use these companies to automatically collect data from you when you use the Services in order to help us identify the ads that are served to you and what you do after seeing those ads. When you enter your email address or similar details through our Services (either to log in, or to sign up to a service, newsletter, or similar) we may disclose personal or other information that we collect from you, such as your email (in a hashed form) or other unique identifiers, to third-party technology companies that help us personalize our services to you (including our ads). For example, we may use Meta’s custom audience and conversion offerings and disclose your email address and other identifiers in a protected format with Meta so that we can: include you in a custom audience that we will serve relevant advertising content to on Meta platforms; or create an audience of other Meta users based on the information in your profile (i.e., Instagram or Facebook platform). You can manage the data which is disclosed by such third parties with us by amending your preferences, using the privacy settings which these third parties provide on their platforms. For further information relating to our third-party partners or to opt-out of such sharing of your information please reach out to us via
							{' '}
							<LocaleLink to="mailto:legal@seesawmarketplace.com?Subject=Privacy Policy">legal@seesawmarketplace.com</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Affiliates.</Typography>
							{' '}
							We may disclose your information to our affiliated companies.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">User Profiles and Submissions.</Typography>
							{' '}
							Certain user profile information, including your name, location, and any video or image content that such user has uploaded to the Services, may be displayed to other users to facilitate user interaction within the Services or address your request for our services. Your account privacy settings may allow you to limit the other users who can see the personal information in your user profile and/or what information in your user profile is visible to others. Please remember that any content you upload to your public user profile, along with any personal information or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your user name may also be displayed to other users if you send messages or comments or upload images or videos through the Services and other users can contact you through messages and comments.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Business Transfers.</Typography>
							{' '}
							We may choose to buy or sell assets, and may disclose and/or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, personal information could be one of the assets transferred to or acquired by a third party.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Protection of Company and Others.</Typography>
							{' '}
							We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of Company, our employees, our users, or others.
						</Typography>

						{/* International Data Transfers */}
						<Typography tag="h3" weight="semibold">
							International Data Transfers
						</Typography>
						<Typography tag="p" weight="light">
							We may transfer information about you anywhere in the world, including transferring information we collect about you to recipients outside of the EEA or the United Kingdom, including to the United States where our global headquarters are located. These countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer or disclose your personal information to other countries, we will protect that information as described in this Privacy Policy.
						</Typography>
						<Typography tag="p" weight="light">
							We comply with applicable legal requirements providing adequate safeguards for the transfer of personal information to countries other than the country where you are located. We may transfer personal information to countries for which
							{' '}
							<LocaleLink to="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en" target="_blank">Adequacy Decisions</LocaleLink>
							{' '}
							have been issued, use contractual protections for the transfer of personal information, such as the European Commission&apos;s Standard Contractual Clauses, a vendor&apos;s Binding Corporate Rules or by relying on such other data transfer mechanisms as available under applicable data protection laws, and where necessary, by implementing further organizational, contractual and technical supplement measures in order to ensure the effectiveness of the transfer mechanisms. You may contact us as specified in the sections below to obtain a copy of the safeguards we use to transfer Personal Information outside of the EEA or the United Kingdom.
						</Typography>

						{/* Children’s Information */}
						<br />
						<Typography tag="h2" weight="semibold">
							Children’s Information
						</Typography>
						<Typography tag="p" weight="light">
							As noted in the Terms of Service, we do not knowingly collect nor solicit personal information from anyone under the age of 18. If you are under 18, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 18, we will delete that information as quickly as possible. If you believe that a child under 18 may have provided us with personal information, please contact us as described below.
						</Typography>

						{/* Is Personal Information About Me Secure? */}
						<br />
						<Typography tag="h2" weight="semibold">
							Is Personal Information About Me Secure?
						</Typography>
						<Typography tag="p" weight="light">
							You must prevent unauthorized access to your account and personal information by selecting and protecting your password appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.
						</Typography>
						<Typography tag="p" weight="light">
							We endeavor to protect the privacy of your account and other personal information we hold in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time. Please read our
							{' '}
							<LocaleLink to="/legal/security-promise" target="_blank">Security Promise</LocaleLink>
							{' '}
							for more information.
						</Typography>

						{/* What Are My Privacy Rights and Choices? */}
						<br />
						<Typography tag="h2" weight="semibold">
							What Are My Privacy Rights and Choices?
						</Typography>
						<Typography tag="p" weight="light">
							You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us, use our Services or to take advantage of some of our features.
						</Typography>

						{/* Opting Out of Communications */}
						<Typography tag="h3" weight="semibold">
							Opting Out of Communications
						</Typography>
						<Typography tag="p" weight="light">
							If you no longer wish to receive electronic communications from us, click the unsubscribe link at the bottom of the email or follow opt-out instructions provided in other communication channels. Note you may still receive transactional emails from us. We process requests to be placed on do-not-mail and do-not-call lists as required by applicable law.
						</Typography>

						{/* Opting Out of Cookies and Advertising */}
						<Typography tag="h3" weight="semibold">
							Opting Out of Cookies and Advertising
						</Typography>
						<Typography tag="p" weight="light">
							You may stop or restrict the placement of cookies on your device or remove them as your browser or device permits. Visit
							{' '}
							<LocaleLink to="https://www.networkadvertising.org/managing/opt_out.asp" target="_blank">https://www.networkadvertising.org/managing/opt_out.asp</LocaleLink>
							,
							{' '}
							<LocaleLink to="https://www.youronlinechoices.eu" target="_blank">https://www.youronlinechoices.eu</LocaleLink>
							,
							{' '}
							<LocaleLink to="https://www.youradchoices.ca/choices" target="_blank">https://www.youradchoices.ca/choices</LocaleLink>
							, and
							{' '}
							<LocaleLink to="https://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</LocaleLink>
							{' '}
							to learn more about the choices available to opt out of cookies and advertising.
						</Typography>

						{/* Updating Your Personal Information */}
						<Typography tag="h3" weight="semibold">
							Updating Your Personal Information
						</Typography>
						<Typography tag="p" weight="light">
							Through your account settings, you may access, and, in some cases, edit or delete the following information you have provided to us:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>name</li>
								<li>email address</li>
								<li>location</li>
								<li>payment information</li>
							</ul>
						</Typography>
						<Typography tag="p" weight="light">
							The information you can view, update, and delete may change as the Services change. If you have any questions about viewing or updating information we have on file about you, please contact us as described below.
						</Typography>

						{/* Data Rights */}
						<Typography tag="h3" weight="semibold">
							Data Rights
						</Typography>
						<Typography tag="p" weight="light">
							Subject to the requirements of applicable law, you may also have privacy rights including:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>Data access and portability: You may have the right to receive a copy of the data and related information we have about you and request us to transfer all or some of this data to a third party.</li>
								<li>Correction of data: You may request that we update or rectify information we have about you.</li>
								<li>Deletion of data: You may request that we delete information we have about you.</li>
								<li>Restriction of or object to processing: You may have the right to opt in or opt out of certain uses of your data or to object to all processing of your data.</li>
							</ul>
						</Typography>
						<Typography tag="p" weight="light">
							If you would like to exercise any of these rights, please contact us as described below. We will process your request in the time and manner required by law. To protect your privacy, we may take steps to verify your identity before fulfilling your request, such as by asking you to log in to your Seesaw Marketplace account or confirm your email address. The rights above may be limited in some circumstances, for example if fulfilling your request would reveal personal information about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. We will inform you of relevant exemptions we rely upon when responding to your request.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Non-Discrimination.</Typography>
							{' '}
							You have the right not to receive discriminatory treatment for the exercise of your privacy rights. If you have questions about your data rights, contact us as described below. In the event you have unresolved concerns, you also have the right to contact your local data protection authority.
						</Typography>

						{/* Data Retention */}
						<br />
						<Typography tag="h2" weight="semibold">
							Data Retention
						</Typography>
						<Typography tag="p" weight="light">
							We generally retain personal information for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, financial, or reporting obligations, to establish or defend legal claims, or for compliance and protection purposes.
						</Typography>
						<Typography tag="p" weight="light">
							To determine the appropriate retention period for personal information, we may consider factors such as:
						</Typography>
						<Typography weight="light">
							<ul>
								<li>The amount, nature, and sensitivity of the personal information</li>
								<li>The potential risk of harm from unauthorized use or disclosure of your personal information</li>
								<li>The purposes for which we process your personal information</li>
								<li>Whether we can achieve those purposes through other means</li>
								<li>Applicable legal requirements</li>
							</ul>
						</Typography>
						<Typography tag="p" weight="light">
							When we no longer require the personal information we have collected about you, we may either delete it, anonymize it, or isolate it from further processing.
						</Typography>

						{/* Will Seesaw Marketplace Change this Privacy Policy? */}
						<br />
						<Typography tag="h2" weight="semibold">
							Will Seesaw Marketplace Change this Privacy Policy?
						</Typography>
						<Typography tag="p" weight="light">
							We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to material changes by placing a notice on the https://www.seesawmarketplace.com website, by sending you an email, and/or by some other means.
						</Typography>
						<Typography tag="p" weight="light">
							By using the Services after any changes to the Privacy Policy have been posted, you agree to the new Privacy Policy.
						</Typography>

						{/* Notice for California Residents */}
						<br />
						<Typography tag="h2" weight="semibold">
							Notice for California Residents
						</Typography>

						{/* Financial Incentives */}
						<Typography tag="h3" weight="semibold">
							Financial Incentives
						</Typography>
						<Typography tag="p" weight="light">
							We may offer referral programs that include financial incentives. To participate in these referral programs, you may be asked to provide information regarding customers, colleagues, leads, or other third parties, such as their name, store name, and email address. They and/or you will receive rewards such as reduced commissions and/or fees, cash, or promotional credit to spend on the Seesaw Marketplace marketplace if they sign up to use our Services. These referral programs are entirely voluntary. The value of data received in referral programs depends on whether, how, and how often the referred party uses the Services. If you wish to withdraw from an incentive program, please contact us as described below.
						</Typography>

						{/* Rights and Choices */}
						<Typography tag="h3" weight="semibold">
							Rights and Choices
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Authorized Agents.</Typography>
							{' '}
							You may designate an authorized agent to exercise your California privacy rights. Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal data. You may also make a verifiable consumer request on behalf of your minor child. To designate an authorized agent, please contact us as described below.
						</Typography>
						<Typography tag="p" weight="light">
							If you are a California resident and would like to exercise any of your privacy rights, please contact us as described below. We will process such requests in accordance with applicable laws.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Limit Processing of Sensitive Personal Data.</Typography>
							{' '}
							We only use Sensitive Personal Data as necessary for service delivery and operations, security and integrity of our Services, or for quality and safety of our Services.
						</Typography>

						{/* Accessibility */}
						<Typography tag="h3" weight="semibold">
							Accessibility
						</Typography>
						<Typography tag="p" weight="light">
							This Privacy Policy web page uses industry-standard technologies and was developed inline with the World Wide Web Consortium’s Web Content Accessibility Guidelines (v. 2.1). If you wish to print this Policy, you may do so from your web browser or by saving the page as a PDF.
						</Typography>

						{/* Notice for Colorado, Connecticut, and Virginia Residents */}
						<br />
						<Typography tag="h2" weight="semibold">
							Notice for Colorado, Connecticut, and Virginia Residents
						</Typography>
						<Typography tag="p" weight="light">
							In addition to the rights listed above, residents of Colorado, Connecticut, and Virginia may submit requests to opt-out of their personal information being used for targeted advertising purposes and profiling.
						</Typography>
						<Typography tag="p" weight="light">
							To submit a request to opt-out of targeted advertising and profiling, visit Seesaw Marketplace’s
							{' '}
							<LocaleLink to="/legal/cookie-policy" target="_blank">Cookie Policy</LocaleLink>
							, click on “Manage My Cookies”, and disable targeting cookies.
						</Typography>

						{/* United States Postal Service Privacy Act Statement */}
						<br />
						<Typography tag="h2" weight="semibold">
							United States Postal Service Privacy Act Statement
						</Typography>
						<Typography tag="p" weight="light">
							Where applicable, your information will be used to facilitate the purchase of USPS postage and fulfill transactional reporting requirements for USPS postage systems. Collection is authorized by 39 U.S.C. 401, 403, and 404. Providing the information is voluntary, but if not provided, your transaction may not be processed. The Postal Service does not disclose your information to third parties without your consent, except to facilitate the transaction, to act on your behalf or request, or as legally required. This includes the following limited circumstances: to a congressional office on your behalf; to financial entities regarding financial transaction issues; to a US Postal Service (USPS) auditor; to entities, including law enforcement, as required by law or in legal proceedings; and to contractors and other entities aiding us to fulfill the service (service providers). For more information regarding our privacy policies, visit
							{' '}
							<LocaleLink to="https://www.usps.com/privacypolicy" target="_blank">https://www.usps.com/privacypolicy</LocaleLink>
							.
						</Typography>

						{/* Contact Us */}
						<br />
						<Typography tag="h2" weight="semibold">
							Contact Us
						</Typography>
						<Typography tag="p" weight="light">
							If you have any questions about this policy or your rights when using our product, please email us at
							{' '}
							<LocaleLink to="mailto:legal@seesawmarketplace.com?Subject=Privacy Policy">legal@seesawmarketplace.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

PrivacyPolicy.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
PrivacyPolicy.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default PrivacyPolicy;
