/*
 * File: PublicRouteWrapper.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';


/**
 * Route Wrapper
 */

export const PublicRouteWrapper = ({
	children
}) => <div>{children}</div>;


/**
 * Configuration
 */

PublicRouteWrapper.propTypes = {
	children: PropTypes.element
};
PublicRouteWrapper.defaultProps = {
	children: null
};
