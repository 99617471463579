/*
 * File: checkout.slice.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 3, 2022 at 3:28 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		currentStep: 0,
		data: {}
	},
};


/**
 * Slices
 */

export const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		updateCheckout: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearCheckout: (state) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const { updateCheckout, clearCheckout } = checkoutSlice.actions;

export default checkoutSlice.reducer;
