/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:14 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:36 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const getGroups = (merchant) => api(merchant).get('/groups');

export const getGroup = (id, merchant) => api(merchant).get(`/groups/${id}`);

export const getProduct = (id, merchant) => api(merchant).get(`/products/${id}`);

export const getSuggestedProducts = ({ productId, groupId }, merchant) => api(merchant).get('/products/suggested', { params: { productId, groupId } });
