/*
 * File: Topbar.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 25, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Utilities
import { getCDNURL } from '../../../../utilities/utilities';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';
import { Button } from '../../Button';

// Styles
import * as S from './Topbar.styles';


/**
 * Component
 */

export const Topbar = ({
	toggleNavigation, isLarge, merchant
}) => {

	// Use hooks
	const navigate = useNavigate();

	// Get CDN URL
	const CDNURL = getCDNURL(merchant);

	// Render component
	return (
		<S.Topbar merchant={merchant}>
			<Padding left right isLarge={isLarge}>
				<S.TopbarWrapper>
					<S.NavigationToggle icon={['fa', 'bars']} size={1.25} onClick={() => { toggleNavigation(); }} />
					<LocaleLink to="/">
						<S.LogoImage
							className="isMobile"
							alt={`${merchant.name} Logo`}
							title={`${merchant.name} Logo`}
							src={merchant.logoMark.startsWith('http') ? merchant.logoMark : `${CDNURL}/public/assets/logos/${merchant.logoMark}`}
						/>
						<S.LogoImage
							className="isNotMobile"
							alt={`${merchant.name} Logo`}
							title={`${merchant.name} Logo`}
							src={merchant.logo.startsWith('http') ? merchant.logo : `${CDNURL}/public/assets/logos/${merchant.logo}`}
						/>
					</LocaleLink>
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth />
				<S.TopbarWrapper>
					<S.LinkContainer className="isNotMobile">
						<S.NavigationLink to="/#features">
							Features
						</S.NavigationLink>
						<S.NavigationLink to="/#pricing">
							Pricing
						</S.NavigationLink>
						<S.NavigationLink to="/#about">
							Demo
						</S.NavigationLink>
					</S.LinkContainer>
					<S.Divider className="isNotMobile" />
					<Button onClick={() => {
						navigate('/#contact');
					}}
					>
						Get started
					</Button>
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	toggleNavigation: PropTypes.func,
	isLarge: PropTypes.bool,
	merchant: PropTypes.shape()
};
Topbar.defaultProps = {
	toggleNavigation: null,
	isLarge: false,
	merchant: {}
};
