/*
 * File: ProductCard.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 9:39 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 28, 2022 at 2:52 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Utilities
import { toastError } from '../../utilities/toaster';
import { formatCost } from '../../utilities/cost';
import { formatOfferDate } from '../../utilities/product';

// Slices
import { updateCart as updateCartSlice } from '../../store/slices/cart/cart.slice';

// Services
import { updateCart } from '../../services/cart';

// Context
import { AppNavigationContext } from '../AppNavigation/AppNavigation';

// Components
import { LocaleLink } from '../LocaleLink';
import { ProductImage } from '../ProductImage';
import { Typography } from '../Typography';

// Styles
import * as S from './ProductCard.styles';
import { SchemaScript } from '../SchemaScript';


/**
 * Component
 */

export const ProductCard = ({ product, merchant, showMarkup }) => {

	// Get context from navigation parent
	const {
		dispatch: contextDispatch,
	} = useContext(AppNavigationContext);

	// Use hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Handle add to cart function
	const addToCart = async (e) => {

		// Prevent default action
		e.preventDefault();

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({
				...!product.isGroup ? {
					products: [{ id: product.id, add: 1 }]
				} : {
					groups: [{ id: product.id, add: 1 }]
				}
			}, merchant);

			// Update cart
			dispatch(updateCartSlice(cart));

			// Open cart sidebar
			contextDispatch({
				type: 'OPEN_CART_SIDEBAR',
				payload: {}
			});
		} catch (error) {

			// Show error message
			toastError(uiMode, merchant, 'We\'re having trouble adding this product to your cart. Give it another go.');
		}
	};

	// Format product url
	const formatProductURL = (productObj) => (productObj.isGroup ? `/collection/${productObj.slug}` : `/product/${productObj.slug}`);

	// Create merchant domain
	let merchantDomain = `https://${merchant.domain}`;
	if (merchant.redirectToSubdomain) {
		merchantDomain = `https://${merchant.redirectToSubdomain}.${merchant.domain}`;
	}

	// Render component
	return (
		<S.Wrapper>
			<LocaleLink
				key={product.id}
				to={formatProductURL(product)}
			>

				{/* Schema.org */}
				{showMarkup && (
					<SchemaScript schema={{
						'@context': 'http://schema.org/',
						'@type': product.isGroup ? 'ProductCollection' : 'Product',
						'@id': `${merchantDomain}${formatProductURL(product)}/#${product.isGroup ? 'ProductCollection' : 'Product'}`,
						name: product?.name,
						url: `${merchantDomain}${formatProductURL(product)}`,
						image: [
							product?.image
						],
						description: product?.description,
						brand: {
							'@type': 'Brand',
							name: merchant.name
						},
						mpn: '',
						gtin: '',
						sku: product?.sku,
						productID: product?.slug,
						category: product?.typeName,
						isFamilyFriendly: true,
						offers: {
							'@type': 'Offer',
							availability: 'http://schema.org/InStock',
							price: formatCost(product?.totalCost),
							priceCurrency: 'USD',
							itemCondition: 'https://schema.org/NewCondition',
							priceValidUntil: formatOfferDate(),
							url: `${merchantDomain}${formatProductURL(product)}`
						},
						potentialAction: [
							{
								'@type': 'BuyAction',
								target: `${merchantDomain}${formatProductURL(product)}`,
							},
							{
								'@type': 'ReviewAction',
								target: `${merchantDomain}${formatProductURL(product)}`,
							},
							{
								'@type': 'ShareAction',
								target: `${merchantDomain}${formatProductURL(product)}`,
							}
						]
					}}
					/>
				)}

				{/* Image */}
				<S.ImageContainer>
					<ProductImage image={product.image} ratio={0.75} />
				</S.ImageContainer>

				{/* Details */}
				<S.DetailContainer>
					<Typography tag="h4" weight="semibold">{product.name}</Typography>
					<Typography tag="p" variation="2" weight="light">
						Wholesale Price - $
						<span>{formatCost(product.totalCost)}</span>
					</Typography>
				</S.DetailContainer>
				<S.ButtonContainer>
					<S.Button size="small" variant="outline" onClick={addToCart} merchant={merchant}>
						<Typography variation="button-small" weight="medium">ADD TO CART</Typography>
					</S.Button>
				</S.ButtonContainer>
			</LocaleLink>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

ProductCard.displayName = 'ProductCard';
ProductCard.propTypes = {
	product: PropTypes.shape(),
	merchant: PropTypes.shape(),
	showMarkup: PropTypes.bool
};
ProductCard.defaultProps = {
	product: null,
	merchant: {},
	showMarkup: true
};
