/*
 * File: Topbar.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:52 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 24, 2022 at 10:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Colors
import { ColorValuesCreator } from '../../../styles/colors';

// Components
import { IconButton } from '../../IconButton';
import { LocaleLink } from '../../LocaleLink';

// Constants
import { AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Topbar = styled.div`
	height: ${AppNavTopbarHeight}px;
	min-height: ${AppNavTopbarHeight}px;
	display: flex;
	background-color: ${({ theme }) => theme.primaryBackground};
	z-index: 1000;
	box-shadow: ${({ merchant }) => ColorValuesCreator(merchant).navigationShadow};
	border-bottom: 1px solid ${({ theme }) => theme.navigationBorder};
	padding: 0.78rem 0;
	pointer-events: all;
	position: fixed !important;
	width: 100%;
	top: 0px !important;

	* :not(svg,path) {
		height: 100%;
	}
	button {
		pointer-events: auto;
		align-items: center;
	}
	.paddingLeft {
		margin-left: 20px;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;

	${({ fullWidth }) => fullWidth && css`
		justify-content: center;
		flex: 1;
	`}
`;

export const NavigationTabContainer = styled.div`
	height: ${AppNavTopbarHeight}px !important;

	& div {
		display: flex;
	}
`;

export const LogoImage = styled.img`
	padding: 0.3rem 0px 0.3rem 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const Divider = styled.div`
	height: 100%;
	width: 1px;
	background-color: ${({ theme }) => theme.layoutBorder};
	margin: 0px 30px;
`;

export const NavigationToggle = styled(IconButton)`
	height: auto;
	margin-right: 18px;
`;

export const LinkContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

export const NavigationLink = styled(LocaleLink)`
	height: auto !important;
	text-decoration: none;
	color: ${({ theme }) => theme.primaryText};
	margin-left: 40px;
`;
