/*
 * File: Sidebar.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 7, 2023 at 9:51 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Padding } from '../../Padding';
import { Typography } from '../../Typography';

// Styles
import * as S from './Sidebar.styles';


/**
 * Component
 */

export const Sidebar = ({
	toggleOverlay, className
}) => (
	<S.Sidebar className={className}>
		<Padding>

			{/* Mobile Navigation */}
			<S.MobileNavigation>
				<S.NavigationToggle icon={['fa', 'times']} size={1.35} onClick={() => { toggleOverlay(); }} />
			</S.MobileNavigation>

			{/* Primary Navigation */}
			<S.SidebarSection showBorder>
				<S.PrimaryLink
					to="/"
					onClick={() => {
						toggleOverlay();
					}}
				>
					<Typography tag="h5" weight="semibold">Home</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink
					to="/#features"
					onClick={() => {
						toggleOverlay();
					}}
				>
					<Typography tag="h5" weight="semibold">Features</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink
					to="/#pricing"
					onClick={() => {
						toggleOverlay();
					}}
				>
					<Typography tag="h5" weight="semibold">Pricing</Typography>
				</S.PrimaryLink>
				<S.PrimaryLink
					to="/#contact"
					onClick={() => {
						toggleOverlay();
					}}
				>
					<Typography tag="h5" weight="semibold">Get Started</Typography>
				</S.PrimaryLink>
			</S.SidebarSection>

			{/* Navigation */}
			<S.SidebarSection showBorder={false}>
				<S.LinkSection>
					<S.Link to="/contact">Contact</S.Link>
					<S.Link to="https://www.seesawtravel.com/careers">Careers</S.Link>
					<S.Link to="https://www.seesawtravel.com/presskit">Presskit</S.Link>
					<S.Link to="/contact#help">Help</S.Link>
					<S.Link to="https://www.seesawmarketplace.com/legal/terms">Terms</S.Link>
					<S.Link to="https://www.seesawmarketplace.com/legal/privacy">Privacy</S.Link>
					<S.Link to="/legal/cookie-policy">Cookie Policy</S.Link>
				</S.LinkSection>
				<S.LinkSection>
					<Typography tag="p" className="fullWidth">
						Made with
						{' '}
						<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
						{' '}
						in Raleigh, NC
					</Typography>
					<Typography tag="p" className="fullWidth">
						{`© ${new Date().getFullYear()} Seesaw`}
					</Typography>
				</S.LinkSection>
			</S.SidebarSection>
		</Padding>
	</S.Sidebar>
);


/**
 * Configuration
 */

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func,
};
Sidebar.defaultProps = {
	className: null,
	toggleOverlay: null,
};
