/*
 * File: cart.slice.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:02 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:43 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Utilities
import { removeCookie } from '../../../utilities/cookies';

// Services
import { getCart } from '../../../services/cart';

// Constants
import { CART_TOKEN_COOKIE } from '../../../../Constants';


/**
 * Constants
 */

export const initialState = {
	status: 'idle',
	error: null,
	value: {
		isSet: false,
		products: [],
		additionalCosts: []
	},
};


/**
 * Functions
 */

export const fetchCart = createAsyncThunk('cart/fetchCart', async (merchant) => {
	try {

		// Fetch current cart
		const { data } = await getCart(merchant);

		// Return cart
		return data.cart;

	} catch (e) {

		// Return null
		return null;
	}
});


/**
 * Slices
 */

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		updateCart: (state, action) => {

			// Set state
			state.value = {
				...action.payload,
				isSet: true
			};
		},
		clearCart: (state, action) => {

			// Clear state
			state.value = {
				...initialState.value,
				isSet: true
			};

			// Clear cookie
			removeCookie(CART_TOKEN_COOKIE, action.payload.merchant);
		},
	},
	extraReducers: {
		[fetchCart.pending]: (state) => {
			state.status = 'loading';
		},
		[fetchCart.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.value = action.payload;
		},
		[fetchCart.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		},
	},
});


/**
 * Exports
 */

export const { updateCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
