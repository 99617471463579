/*
 * File: MarketingNavigation.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 8, 2023 at 3:56 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useState, createContext, forwardRef
} from 'react';
import PropTypes from 'prop-types';

// Components
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { CopyrightFooter } from '../CopyrightFooter';

// Styles
import * as S from './MarketingNavigation.styles';


/**
 * Context
 */

export const MarketingNavigationContext = createContext();


/**
 * Component
 */

export const MarketingNavigation = forwardRef(({
	children, isLarge, showCart, merchant, hidePadding
}, ref) => {

	// Set state
	const [sideNavigationOpen, setSideNavigationOpen] = useState(false);

	// Toggle navigation function
	const toggleNavigation = () => {

		// Toggle mobile sidebar visibility
		setSideNavigationOpen(!sideNavigationOpen);
	};

	// Render component
	return (
		<S.Navigation ref={ref}>

			{/* Top Bar */}
			<Topbar
				toggleNavigation={toggleNavigation}
				isLarge={isLarge}
				showCart={showCart}
				merchant={merchant}
			/>

			{/* Side Navigation */}
			<S.NavigationOverlay isOpen={sideNavigationOpen}>
				<Sidebar
					className="leftSidebar"
					toggleOverlay={toggleNavigation}
					merchant={merchant}
				/>
				<S.Overlay isOpen={sideNavigationOpen} onClick={() => { toggleNavigation(); }} />
			</S.NavigationOverlay>

			{/* Content */}
			<S.Wrapper>
				<S.Content id="scroller">

					{/* Content */}
					{!hidePadding ? (
						<S.ContentPadding left right dynamic isLarge={isLarge}>
							{children}
						</S.ContentPadding>
					) : (
						<S.ContentPadding isLarge={isLarge}>
							{children}
						</S.ContentPadding>
					)}

					{/* Footer */}
					<CopyrightFooter isLarge={isLarge} merchant={merchant} />
				</S.Content>
			</S.Wrapper>
		</S.Navigation>
	);
});


/**
 * Configuration
 */

MarketingNavigation.displayName = 'MarketingNavigation';
MarketingNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	isLarge: PropTypes.bool,
	showCart: PropTypes.bool,
	merchant: PropTypes.shape(),
	hidePadding: PropTypes.bool
};
MarketingNavigation.defaultProps = {
	children: null,
	isLarge: false,
	showCart: true,
	merchant: {},
	hidePadding: false
};
