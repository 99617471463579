/*
 * File: AppNavigation.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:29 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 11:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useState, createContext, useReducer, useMemo, forwardRef, useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';

// Components
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { CopyrightFooter } from '../CopyrightFooter';
import { CartSidebar } from './CartSidebar';

// Styles
import * as S from './AppNavigation.styles';


/**
 * Context
 */

export const AppNavigationContext = createContext();


/**
 * Component
 */

export const AppNavigation = forwardRef(({
	children, isLarge, showCart, merchant
}, ref) => {

	// Set state
	const [sideNavigationOpen, setSideNavigationOpen] = useState(false);
	const [cartOpen, setCartOpen] = useState(false);

	// Toggle navigation function
	const toggleNavigation = () => {

		// Toggle mobile sidebar visibility
		setSideNavigationOpen(!sideNavigationOpen);
	};

	// Toggle cart function
	const toggleCart = () => {

		// Toggle mobile sidebar visibility
		setCartOpen(!cartOpen);
	};

	// Create reducer
	const reducer = (state, action) => {
		switch (action.type) {
			case 'OPEN_CART_SIDEBAR':
				setCartOpen(true);
				return { ...state };
			default:
				return state;
		}
	};

	// Set reducers
	const [contextState, contextDispatch] = useReducer(reducer, {});

	// Create memoized context provider
	const contextProvider = useMemo(() => ({ state: contextState, dispatch: contextDispatch }), [contextState, contextDispatch]);

	// Define parent handlers
	useImperativeHandle(ref, () => ({

		// Define open cart handler
		openCart() {
			setCartOpen(true);
		}
	}));

	// Render component
	return (
		<AppNavigationContext.Provider value={contextProvider}>
			<S.Navigation>

				{/* Top Bar */}
				<Topbar
					toggleNavigation={toggleNavigation}
					toggleCart={toggleCart}
					isLarge={isLarge}
					showCart={showCart}
					merchant={merchant}
				/>

				{/* Side Navigation */}
				<S.NavigationOverlay isOpen={sideNavigationOpen}>
					<Sidebar
						className="leftSidebar"
						toggleOverlay={toggleNavigation}
						merchant={merchant}
					/>
					<S.Overlay isOpen={sideNavigationOpen} onClick={() => { toggleNavigation(); }} />
				</S.NavigationOverlay>

				{/* Cart */}
				{showCart && (
					<S.NavigationOverlay isOpen={cartOpen}>
						<CartSidebar
							className="rightSidebar"
							toggleOverlay={toggleCart}
							merchant={merchant}
						/>
						<S.Overlay isOpen={cartOpen} onClick={() => { toggleCart(); }} />
					</S.NavigationOverlay>
				)}

				{/* Content */}
				<S.Wrapper>
					<S.Content id="scroller">

						{/* Content */}
						<S.ContentPadding left right dynamic isLarge={isLarge}>
							{children}
						</S.ContentPadding>

						{/* Footer */}
						<CopyrightFooter isLarge={isLarge} merchant={merchant} />
					</S.Content>
				</S.Wrapper>
			</S.Navigation>
		</AppNavigationContext.Provider>
	);
});


/**
 * Configuration
 */

AppNavigation.displayName = 'AppNavigation';
AppNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	isLarge: PropTypes.bool,
	showCart: PropTypes.bool,
	merchant: PropTypes.shape()
};
AppNavigation.defaultProps = {
	children: null,
	isLarge: false,
	showCart: true,
	merchant: {}
};
