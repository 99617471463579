/*
 * File: component.slice.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 10:02 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import { createSlice } from '@reduxjs/toolkit';


/**
 * Constants
 */

const initialState = {
	status: 'initial',
	error: null,
	states: [],
};


/**
 * Slices
 */

export const componentSlice = createSlice({
	name: 'component',
	initialState,
	reducers: {
		pushComponentState: (state, action) => {

			// Set state
			if (action.payload != null) {

				// Initialize state
				let pushNewPayload = true;

				// Check if should update current payload
				if (state.states.length > 0) {
					if (state.states[state.states.length - 1].path === window.location.pathname) {
						pushNewPayload = false;
						state.states[state.states.length - 1].data = {
							...state.states[state.states.length - 1].data,
							...action.payload
						};
					}
				}

				// Push new payload
				if (pushNewPayload) {
					state.states.push({
						data: action.payload,
						isUsed: false,
						path: window.location.pathname,
						timestamp: (new Date()).getTime()
					});
				}
			}
			state.status = 'final';
		},
		clearComponentState: (state) => {

			// Clear state
			state.states = [];
			state.status = 'final';
		},
	},
	extraReducers: {},
});


/**
 * Exports
 */

export const {
	pushComponentState, clearComponentState
} = componentSlice.actions;

export default componentSlice.reducer;
