/*
 * File: CopyrightFooter.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:59 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 1:49 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink } from '../LocaleLink';


/**
 * Styles
 */

export const CopyrightFooter = styled.div`
	position: relative;
	padding: 15px 0px 30px;
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.layoutBorder};

	* {
		text-align: left;
	}

	span {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.8rem;
		font-weight: 300;
	}
	a {
		display: flex;
	}
`;

export const LogoMark = styled.img`
	width: auto;
	height: 34px;
	margin-right: 10px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const CoreLogoMark = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;

export const InlineLink = styled(LocaleLink)`
	display: inline !important;
	color: ${({ theme }) => theme.secondaryText} !important;
`;
