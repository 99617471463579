/*
 * File: ProductRow.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 10:17 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:40 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'beautiful-react-hooks';

// Utilities
import { formatCost } from '../../../utilities/cost';

// Slices
import { updateCart as updateCartSlice } from '../../../store/slices/cart/cart.slice';

// Services
import { updateCart } from '../../../services/cart';

// Components
import { Typography } from '../../Typography';
import { TextInput } from '../../TextInput';
import { Button } from '../../Button';

// Styles
import * as S from './ProductRow.styles';
import { toastError } from '../../../utilities/toaster';


/**
 * Component
 */

export const ProductRow = ({
	product, isHeader, isSmall, allowActions
}) => {

	// Get state handlers
	const [quantity, setQuantity] = useState(product?.quantity || 1);

	// Use hooks
	const dispatch = useDispatch();

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Get current merchant from hook
	const merchant = useSelector((state) => state.merchant.value);

	// Handle update product quantity function
	const handleChangeQuantity = useDebouncedCallback(async (e) => {

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({
				...!product.isGroup ? {
					products: [{ id: product.id, quantity: e.target.value }]
				} : {
					groups: [{ id: product.id, quantity: e.target.value }]
				}
			}, merchant);

			// Update cart
			dispatch(updateCartSlice(cart));

		} catch (error) {}
	}, [], 400);

	// Handle remove product function
	const handleRemoveProduct = async () => {

		// Update cart
		try {

			// Update cart
			const { data: { cart } } = await updateCart({
				...!product.isGroup ? {
					products: [{ id: product.id, quantity: 0 }]
				} : {
					groups: [{ id: product.id, quantity: 0 }]
				}
			}, merchant);

			// Update cart
			dispatch(updateCartSlice(cart));

		} catch (error) {

			// Show error message
			toastError(uiMode, merchant, 'We\'re having trouble removing this product from your cart. Give it another go.');
		}
	};

	// Handle cart change action
	useEffect(() => {

		// Update product quantity
		setQuantity(product?.quantity || 1);

	}, [product]);

	// Render component
	return (
		<S.ProductRow isHeader={isHeader}>

			{/* Image */}
			{isHeader
				? (
					<S.GrowRow>
						<Typography weight="light">Product</Typography>
					</S.GrowRow>
				)
				: <S.ProductImage image={product.image} ratio={0.75} />}

			{/* Name */}
			{!isHeader && (
				<S.GrowRow>
					<Typography tag="p">{product.name}</Typography>
					{allowActions && (
						<Button variant="text" onClick={handleRemoveProduct} merchant={merchant}>
							<Typography variation="button-small" weight="medium">REMOVE</Typography>
						</Button>
					)}
				</S.GrowRow>
			)}

			{/* Cost */}
			<S.CostRow>
				{isHeader
					? <Typography weight="light">Cost</Typography>
					: (
						<Typography tag="p" weight="semibold">
							$
							{formatCost(product.totalCost)}
						</Typography>
					)}
			</S.CostRow>

			{/* Quantity */}
			<S.QuantityRow>
				{isHeader
					? <Typography weight="light" className={isSmall ? 'alignRight' : 'alignLeft'}>Quantity</Typography>
					: (
						<div>
							{allowActions ? (
								<TextInput
									type="number"
									value={quantity}
									onChange={(e) => {
										setQuantity(e.target.value > 0 ? e.target.value : 1);
										handleChangeQuantity(e);
									}}
								/>
							) : <Typography tag="p" weight="semibold" className={isSmall ? 'alignRight' : 'alignLeft'}>{quantity}</Typography>}
						</div>
					)}
			</S.QuantityRow>

			{/* Total Cost */}
			{!isSmall && (
				<S.TotalRow>
					{isHeader
						? <Typography weight="light">Total</Typography>
						: (
							<Typography tag="p" weight="semibold">
								$
								{formatCost(quantity * product.totalCost)}
							</Typography>
						)}
				</S.TotalRow>
			)}
		</S.ProductRow>
	);
};


/**
 * Configuration
 */

ProductRow.displayName = 'ProductRow';
ProductRow.propTypes = {
	product: PropTypes.shape(),
	isHeader: PropTypes.bool,
	isSmall: PropTypes.bool,
	allowActions: PropTypes.bool
};
ProductRow.defaultProps = {
	product: null,
	isSmall: false,
	isHeader: false,
	allowActions: true
};
