/*
 * File: PageHeader.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 26, 2022 at 9:15 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './PageHeader.styles';


/**
 * Component
 */

export const PageHeader = ({
	title, subtitle, icon, showIcon, isSmall
}) => (
	<S.Wrapper isSmall={isSmall}>
		{showIcon && <S.Icon icon={icon} />}
		<Typography tag={!isSmall ? 'h4' : 'h5'} weight="semibold">{subtitle}</Typography>
		<Typography tag={!isSmall ? 'h1' : 'h2'} weight="black">{title}</Typography>
	</S.Wrapper>
);


/**
 * Configuration
 */

PageHeader.displayName = 'PageHeader';
PageHeader.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.node,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	showIcon: PropTypes.bool,
	isSmall: PropTypes.bool
};
PageHeader.defaultProps = {
	title: null,
	subtitle: null,
	icon: null,
	showIcon: true,
	isSmall: false
};
