/*
 * File: ProductDetail.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 17, 2022 at 8:15 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { Button as BaseButton } from '../../../components/Button';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const ProductWrapper = styled.div`
	margin: 20px 0 0 0;
`;

export const DetailContainer = styled.div`
	margin: 40px 0 50px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
		gap: 80px;
	}
`;

export const LeftDetail = styled.div`
	padding: 20px 0 0 0;

	h3,
	p {
		text-align: left;
	}
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const RightDetail = styled.div`
	p {
		text-align: left;
		white-space: pre-line;
	}
`;

export const MadeWith = styled.div`
	margin: 20px 0 0 0;

	p {
		text-align: center;
	}
`;

export const ButtonContainer = styled.div`
	padding: 0px;
	margin: 40px 0 0 0;
`;

export const Button = styled(BaseButton)`
	width: 100%;
	height: 50px;
	margin: 0px 0px 0px;
`;
