/*
 * File: IntellectualPropertyPolicy.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Marketplace Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 8, 2023 at 3:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, MarketingNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './IntellectualPropertyPolicy.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const IntellectualPropertyPolicy = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation hidePadding merchant={stateMerchant}>
				<LegalNavigation merchant={stateMerchant}>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Intellectual Property Policy
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								Seesaw Marketplace respects the intellectual property rights of others, and we expect you to do the same. Please don’t post content that you didn’t create and claim it as your own. If you do use or mention someone else’s content or brand, make sure to add something of value that is your own - review it, remix it, parody it, etc. If you need to file a complaint against a Seesaw Marketplace user for misusing your content, please
								{' '}
								<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
								{' '}
								directly.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							Seesaw Marketplace respects the intellectual property rights of others, and we expect you to do the same. Seesaw Marketplace’s
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/terms" target="_blank">Terms of Service</LocaleLink>
							{' '}
							do not allow posting, sharing, or sending any content that violates or infringes someone else’s copyrights, trademarks or other intellectual property rights.
						</Typography>

						{/* Copyright Policy */}
						<br />
						<Typography tag="h2" weight="semibold">
							Copyright Policy
						</Typography>
						<Typography tag="p" weight="light">
							Copyright is a legal right that protects original works of authorship (e.g., products, brands, etc.). Generally, copyright protects an original expression of an idea (e.g., the specific way a product or brand is expressed or created) but does not protect underlying ideas or facts.
						</Typography>

						{/* Copyright Infringement */}
						<Typography tag="h3" weight="semibold">
							Copyright Infringement
						</Typography>
						<Typography tag="p" weight="light">
							We do not allow any content that infringes copyright. The use of copyrighted content of others without proper authorization or legally valid reason may lead to a violation of Seesaw Marketplace&apos;s policies.
						</Typography>
						<Typography tag="p" weight="light">
							At the same time, not all unauthorized uses of copyrighted content constitute an infringement. In many countries, exceptions to copyright infringement allow the use of copyrighted works under certain circumstances without authorization. These include the fair use doctrine in the United States and permitted acts of fair dealing in the European Union (and other equivalent exceptions under applicable local laws in other countries).
						</Typography>

						{/* Removal of Content; Suspension or Termination of Account */}
						<Typography tag="h3" weight="semibold">
							Removal of Content; Suspension or Termination of Account
						</Typography>
						<Typography tag="p" weight="light">
							Any user content that infringes another person’s copyright may be removed. The account may be suspended or terminated for multiple copyright violations in connection with the use of the Seesaw Marketplace site or app, or other violations of the
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/terms" target="_blank">Terms of Service</LocaleLink>
							. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on Seesaw Marketplace’s site or app, or otherwise hosted by Seesaw Marketplace.
						</Typography>

						{/* Information for Rights Holders */}
						<Typography tag="h3" weight="semibold">
							Information for Rights Holders
						</Typography>

						{/* Copyright Infringement Notification */}
						<Typography tag="h4" weight="semibold">
							Copyright Infringement Notification
						</Typography>
						<Typography tag="p" weight="light">
							If you become aware of a Seesaw Marketplace user that is using your copyrighted material without permission, contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also
							{' '}
							<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
							{' '}
							to request the removal of the alleged infringing content from Seesaw Marketplace.
						</Typography>
						<Typography tag="p" weight="light">
							All complaints should contain your name, contact information, a link to the content in question, and proof of your ownership. Failure to include necessary information may limit our ability to investigate your claims and may result in your complaint being denied.
						</Typography>
						<Typography tag="p" weight="light">
							We may provide the account holder with your contact information, including the email address and the name of the copyright owner, and/or details of the complaint.
						</Typography>
						<Typography tag="p" weight="light">
							Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under section 512(f) of the United States Digital Millennium Copyright Act (DMCA) or similar laws as may be applicable in other countries.
						</Typography>
						<Typography tag="p" weight="light">
							If you submit a report or infringement notification to us, we may contact you if we have additional questions about your report or notification. Please note that Seesaw Marketplace is not in a position to adjudicate disputes between third parties, and may not be able to remove the content or suspend the account you reported. As an alternative, you may want to contact the person who posted the content or owns the account to try to resolve your issue directly.
						</Typography>

						{/* EU Copyright Directive */}
						<Typography tag="h4" weight="semibold">
							EU Copyright Directive
						</Typography>
						<Typography tag="p" weight="light">
							Pursuant to Article 17 of the Copyright Directive (EU 2019/790), if you want to make an enquiry about granting an authorization to Seesaw Marketplace to make your copyright-protected works available on Seesaw Marketplace, please
							{' '}
							<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
							. We will review your request and be in touch.
						</Typography>
						<Typography tag="p" weight="light">
							If you want to request that your music or audiovisual works are made unavailable in the EU, we need you to
							{' '}
							<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
							{' '}
							directly. So that Seesaw Marketplace can consider your request, you will have to provide us with relevant and necessary information about you and your copyright works. Upon receiving this information and validating your request, Seesaw Marketplace will do its best to ensure that your copyright work is made unavailable on Seesaw Marketplace in the EU.
						</Typography>
						<Typography tag="p" weight="light">
							Please note that in accordance with its legal obligations Seesaw Marketplace provides to users and rights holders a copyright infringement dispute resolution mechanism, however rightholders remain free to assert their rights in court.
						</Typography>

						{/* Information for Users: Copyright Infringement Counter-Notification */}
						<Typography tag="h3" weight="semibold">
							Information for Users: Copyright Infringement Counter-Notification
						</Typography>

						{/* If you are a user located outside of the European Union */}
						<Typography tag="h4" weight="semibold">
							If you are a user located outside of the European Union
						</Typography>
						<Typography tag="p" weight="light">
							If you receive a copyright infringement notification that you believe to be in error or believe that you are authorized to use the content, you can reach out to the copyright owner directly to request a retraction.
						</Typography>
						<Typography tag="p" weight="light">
							You may also provide us with a counter-notification by
							{' '}
							<LocaleLink to="/contact" target="_blank">contacting us</LocaleLink>
							{' '}
							directly. Your counter-notification should contain your name, contact information, a link to the content in question, and proof of your ownership or right to use. Failure to include necessary information may limit our ability to investigate your claims and may result in your counter-notification being denied.
						</Typography>
						<Typography tag="p" weight="light">
							The counter-notification process will take time to complete, please be patient. During this time, the copyright claimant may file an action seeking a court order to keep the content down pursuant to the United States Digital Millennium Copyright Act (DMCA) or similar laws in other countries. Please note that, where appropriate and authorized by law, we will forward the entire counter-notification to the original reporter, including any contact information you provide, in accordance with our Terms of Services and Privacy Policy. The claimant may use this information to file a lawsuit against you.
						</Typography>
						<Typography tag="p" weight="light">
							If we do not receive notice that the original reporter is seeking a court order to prevent further infringement of the material at issue, we may replace or cease disabling access to the material that was removed if the material does not infringe on third-party copyright. The decision to re-post any material is at Seesaw Marketplace’s sole discretion.
						</Typography>

						{/* If you are a user located in the European Union */}
						<Typography tag="h4" weight="semibold">
							If you are a user located in the European Union
						</Typography>
						<Typography tag="p" weight="light">
							If you receive a copyright infringement notification and believe that you have the right to post the content in question, you can
							{' '}
							<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
							{' '}
							directly.
						</Typography>
						<Typography tag="p" weight="light">
							Under EU law, users are allowed to use copyright works without the authorization of the copyright holder for quotation, criticism, review and for the purpose of caricature, parody or pastiche provided that such use is fair. EU countries may also provide for additional exceptions. See below some further information about the exceptions and limitations to copyright available in the EU:
						</Typography>

						{/* Quotation, criticism and review */}
						<Typography tag="h5" weight="semibold" fontStyle="italic">
							Quotation, criticism and review
						</Typography>
						<Typography tag="p" weight="light">
							Quotation is the use of an extract from a copyright-protected work for purposes such as illustrating an assertion, defending an opinion or engaging in debate. Quotations may also be used for the purposes of criticizing a copyrighted work (e.g. critical commentary on a movie) or reviewing one (e.g. reviewing a book or an album).
						</Typography>

						{/* Caricature, parody and pastiche */}
						<Typography tag="h5" weight="semibold" fontStyle="italic">
							Caricature, parody and pastiche
						</Typography>
						<Typography tag="p" weight="light">
							Caricatures aim at exaggerating or distorting reality, usually for humorous purposes. A parody will evoke an existing copyrighted work while being noticeably different from it and should constitute an expression of humor or mockery. A pastiche will usually incorporate distinctive elements from other works or styles into a new work.
						</Typography>
						<Typography tag="p" weight="light">
							In order for any use of copyrighted work to come within an exception or limitation it must be fair, this means that it should, where applicable: (i) be no longer than necessary; (ii) be accompanied by sufficient acknowledgement of the source material and (iii) not unreasonably harm the legitimate interests of the rightholder.
						</Typography>
						<Typography tag="p" weight="light">
							Please note that in accordance with its legal obligations Seesaw Marketplace provides to users and rights holders a copyright infringement dispute resolution mechanism, however users remain free to assert their rights in court.
						</Typography>

						{/* Trademark Policy */}
						<br />
						<Typography tag="h2" weight="semibold">
							Trademark Policy
						</Typography>
						<Typography tag="p" weight="light">
							A trademark is a word, symbol, slogan, design, or combination of any of the foregoing that identifies the source of a product or service and distinguishes it from other products or services.
						</Typography>

						{/* Trademark Infringement */}
						<Typography tag="h3" weight="semibold">
							Trademark Infringement
						</Typography>
						<Typography tag="p" weight="light">
							Trademark laws prohibit trademark infringement, which is generally the unauthorized use of a trademark or service mark in connection with goods or services in a way that is likely to cause confusion, deception or mistake about the source, origin, sponsorship or affiliation of the associated goods and/or services.
						</Typography>
						<Typography tag="p" weight="light">
							At the same time, the use of another’s trademark for purposes of accurately referencing, lawfully commenting, criticizing, parodying, or reviewing the trademark owner’s products or services, or for purposes of comparing them to other products or services, where the mark is not used to designate the user’s own goods or services or those of a third party, is generally not considered a violation of our policies. Likewise, it is generally permissible to make a fan page about a brand, even without the brand’s permission, provided that you do not claim to speak for or be associated with the brand or otherwise violate the brand’s intellectual property rights.
						</Typography>

						{/* Removal of Content; Suspension or Termination of Account */}
						<Typography tag="h3" weight="semibold">
							Removal of Content; Suspension or Termination of Account
						</Typography>
						<Typography tag="p" weight="light">
							Any content that violates another’s trademark rights may be taken down. Repeat violations of trademark rights in connection with the use of the Seesaw Marketplace site or app, or other violations of the
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/terms" target="_blank">Terms of Service</LocaleLink>
							{' '}
							may result in the suspension or termination of the user account. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on Seesaw Marketplace’s site or app, or otherwise hosted by Seesaw Marketplace.
						</Typography>

						{/* Trademark Complaint and Notification */}
						<Typography tag="h3" weight="semibold">
							Trademark Complaint and Notification
						</Typography>
						<Typography tag="p" weight="light">
							Contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also
							{' '}
							<LocaleLink to="/contact" target="_blank">contact us</LocaleLink>
							{' '}
							directly.
						</Typography>
						<Typography tag="p" weight="light">
							All complaints should contain your name, contact information, a link to the content in question, and proof of your ownership. Failure to include necessary information may limit our ability to deal with your claims and may result in your complaint being denied. We may provide the account holder with your contact information, including the email address and the name of the trademark owner, and/or details of the complaint, in accordance with our
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/terms" target="_blank">Terms of Services</LocaleLink>
							{' '}
							and
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							.
						</Typography>
						<Typography tag="p" weight="light">
							Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under laws as may be applicable in some countries.
						</Typography>

						{/* Trademark Infringement Counter-Notification */}
						<Typography tag="h3" weight="semibold">
							Trademark Infringement Counter-Notification
						</Typography>
						<Typography tag="p" weight="light">
							If you receive a trademark infringement notification that you believe to be in error or believe that you are authorized to use the content, you can reach out to the trademark owner directly to request a retraction.
						</Typography>
						<Typography tag="p" weight="light">
							You may also provide us with a counter-notification by
							{' '}
							<LocaleLink to="/contact" target="_blank">contacting us</LocaleLink>
							{' '}
							directly. All counter-notification should contain your name, contact information, a link to the content in question, and proof of your ownership or right to use. Failure to include necessary information may limit our ability to investigate your claims and may result in your counter-notification being denied.
						</Typography>

						{/* General Note */}
						<br />
						<Typography tag="h2" weight="semibold">
							General Note
						</Typography>
						<Typography tag="p" weight="light">
							As a Seesaw Marketplace user, you are responsible for the content you post. If you have questions about copyright law or trademark law, such as questions about whether your content or your use of another person’s name or brand infringes or otherwise violates another person’s rights, you may want to contact an attorney. If you are unsure whether the material you plan to report to us is infringing or otherwise violating another person’s right, you may also want to first seek legal advice before reporting such content to us.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

IntellectualPropertyPolicy.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
IntellectualPropertyPolicy.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default IntellectualPropertyPolicy;
