/*
 * File: CopyrightFooter.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:31 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 25, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { getCDNURL } from '../../../utilities/utilities';

// Components
import { Typography } from '../Typography';
import { Padding } from '../Padding';
import { LocaleLink } from '../LocaleLink';

// Assets
import SeesawLogoMark from '../../assets/seesaw-logo-mark-neutral.svg';

// Styles
import * as S from './CopyrightFooter.styles';


/**
 * Component
 */

export const CopyrightFooter = ({ isLarge, merchant }) => {

	// Get CDN URL
	const CDNURL = getCDNURL(merchant);

	// Render component
	return (
		<Padding left right isLarge={isLarge}>
			<S.CopyrightFooter>
				<LocaleLink to="/">
					{merchant.isCore ? <S.CoreLogoMark src={SeesawLogoMark} alt="Seesaw Logo" title="Seesaw Logo" />
						: <S.LogoMark alt={`${merchant.name} Logo`} title={`${merchant.name} Logo`} src={merchant.logoMark.startsWith('http') ? merchant.logoMark : `${CDNURL}/public/assets/logos/${merchant.logoMark}`} />					}
				</LocaleLink>
				<Typography tag="span">
					{!merchant.isCore ? (
						<Typography tag="span">
							{`© ${new Date().getFullYear()} ${merchant.name}.`}
							{' '}
							Powered by
							{' '}
							<S.InlineLink to="https://www.seesawmarketplace.com" target="_blank">
								Seesaw Marketplace
							</S.InlineLink>
							.
						</Typography>
					) : (
						<Typography tag="span">{`© ${new Date().getFullYear()} Seesaw Technologies, LLC. All rights reserved.`}</Typography>
					)}
				</Typography>
			</S.CopyrightFooter>
		</Padding>
	);
};


/**
 * Configuration
 */

CopyrightFooter.displayName = 'CopyrightFooter';
CopyrightFooter.propTypes = {
	isLarge: PropTypes.bool,
	merchant: PropTypes.shape()
};
CopyrightFooter.defaultProps = {
	isLarge: false,
	merchant: {}
};
