/*
 * File: SecurityPromise.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 8, 2022 at 3:21 PM
 * Copyright © 2022 Seesaw Marketplace Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 8, 2023 at 3:55 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../../utilities/locale';

// Components
import {
	Meta, MarketingNavigation, LegalNavigation, Typography, Emoji, LocaleLink
} from '../../../../components';

// Styles
import * as S from './SecurityPromise.styles';
import { HighlightSection, HighlightTitle } from '../../../../components/LegalNavigation/LegalNavigation.styles';


/**
 * Component
 */

const SecurityPromise = ({ meta, locale, merchant }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current merchant from hook
	const clientMerchant = useSelector((state) => state.merchant.value);
	const stateMerchant = merchant || clientMerchant;

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} merchant={stateMerchant} />

			{/* Component Content */}
			<MarketingNavigation hidePadding merchant={stateMerchant}>
				<LegalNavigation merchant={stateMerchant}>
					<S.Wrapper>

						{/* Title */}
						<Typography tag="h1" weight="semibold">
							Security Promise
						</Typography>

						{/* TL;DR */}
						<HighlightSection>
							<HighlightTitle tag="h5" weight="semibold">
								TL;DR
								<Emoji symbol="👀" label="smile" size={1.5} />
							</HighlightTitle>
							<Typography tag="p" weight="light">
								At Seesaw Marketplace, we use industry-standard security techniques to protect your data. These include encrypted data storage and transfer, frequent backups, and regular platform updates. We also encourage you to take time to secure your own Seesaw Marketplace account with a strong password and up-to-date contact information.
							</Typography>
						</HighlightSection>

						{/* Last Updated */}
						<Typography tag="p" weight="light" fontStyle="italic">
							Last updated January 2023
						</Typography>

						{/* Content */}
						<Typography tag="p" weight="light">
							At Seesaw Marketplace, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency. This promise aims to serve as a clear, transparent and easily digestible summary of Seesaw Marketplace’s long-standing policies with respect to data privacy, security, and protection.
						</Typography>
						<Typography tag="p" weight="light">
							Below are a few examples of our active security policies and precautions at Seesaw Marketplace. These measures, along with many others, serve to create a secure, reliable, and safe system for our users and community.
						</Typography>

						{/* Strong Login Details */}
						<br />
						<Typography tag="h2" weight="semibold">
							Strong Login Details
						</Typography>
						<Typography tag="p" weight="light">
							We generate passwords using a strong password generator and avoid predictable usernames and credentials. This approach makes it much harder for “brute force” attacks to successfully gain access to our platform. We don’t just apply this principle to website logins; the same level of security is applied to databases and account logins as well.
						</Typography>

						{/* Regular Software Upgrades */}
						<br />
						<Typography tag="h2" weight="semibold">
							Regular Software Upgrades
						</Typography>
						<Typography tag="p" weight="light">
							We strive to ensure that the software that powers our platform is kept up to date, minimizing the risk of security vulnerabilities and reducing the chance of exploitation. Updates are often made in response to new security fixes and overall improvements.
						</Typography>

						{/* Encrypted Infrastructure */}
						<br />
						<Typography tag="h2" weight="semibold">
							Encrypted Infrastructure
						</Typography>
						<Typography tag="p" weight="light">
							Our databases and data storage volumes are designed to encrypt data at rest. We endeavor to encrypt all data submitted to Seesaw Marketplace, including product information, emails, records, and feedback, and make it visible only to internal Seesaw Marketplace administrators and third-party partners pursuant to our
							{' '}
							<LocaleLink to="https://www.seesawmarketplace.com/legal/privacy" target="_blank">Privacy Policy</LocaleLink>
							.
						</Typography>

						{/* Infrastructure Backups */}
						<br />
						<Typography tag="h2" weight="semibold">
							Infrastructure Backups
						</Typography>
						<Typography tag="p" weight="light">
							Our system is designed to capture and encrypt all databases and preferences on a daily basis and store backups for at least one week. This provides extra insurance in the unlikely event of serious infrastructure failure.
						</Typography>

						{/* Secure Hosting Over TLS */}
						<br />
						<Typography tag="h2" weight="semibold">
							Secure Hosting Over TLS
						</Typography>
						<Typography tag="p" weight="light">
							All of our platform websites, APIs, and databases are hosted on secure servers designed to run an TLS/SSL certificate. This essentially means that information is encrypted when transferred over the wire. This encryption makes it much more difficult for unscrupulous hackers to see those details.
						</Typography>
						<Typography tag="p" weight="light">
							We also run active monitoring tools to protect our users from things like malware, suspicious code and unexpected file changes, as well as logging platform activity to flag any unusual behavior.
						</Typography>

						{/* Securing Your Account */}
						<br />
						<Typography tag="h2" weight="semibold">
							Securing Your Account
						</Typography>
						<Typography tag="p" weight="light">
							As we continuously work to keep our community’s information secure, we also empower people with tools and information for good security habits. Some tips:
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Keep your contact information updated:</Typography>
							{' '}
							Link your most up to date phone number and/or email address to your Seesaw Marketplace account. Email may be a method of alerting you of any suspicious activity on your account and can be used in confirming your identity if you ever get locked out.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Create a strong and unique password:</Typography>
							{' '}
							Choose a password that’s memorable for you but difficult for others to guess. Unique means having a different password for each of your accounts, and strong means making the password complex by using at least 12 characters including numbers and symbols. Be sure to set up strong and unique passwords/PINs for all of the devices you use to access Seesaw Marketplace such as laptop, phone, and other mobile devices. A password manager can help take the guesswork out of remembering dozens of unique usernames and passwords.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Security alerts:</Typography>
							{' '}
							Our teams continuously monitor for suspicious or unauthorized activity. Keep track of recent unusual security events that you do not recognize.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Verify your devices:</Typography>
							{' '}
							You can view phones and other mobile devices that are currently using or have recently accessed your Seesaw Marketplace account. This will display all devices associated with your account, how you logged into the account, and when. Check this information to make sure no one else has signed in to your account.
						</Typography>
						<Typography tag="p" weight="light">
							<Typography weight="semibold">Avoid phishing attacks:</Typography>
							{' '}
							Phishing is a common method attackers use to trick others into giving up their personal information such as passwords, credit card numbers, social security, or other sensitive data. Never trust any third-party websites that promise to give away free likes, fans, followers, or other incentives as they may be able to take your login info. Be sure to always verify any links sent to you via private message before accessing the page.
						</Typography>
						<Typography tag="p" weight="light" fontStyle="italic">
							Don’t be fooled if someone contacts you pretending to be from Seesaw Marketplace. Remember: any legitimate email from our teams will never ask you for your password!
						</Typography>
						<Typography tag="p" weight="light">
							If you find content or receive messages on Seesaw Marketplace that you think might be spam or phishing, please report them so our teams can continue to keep our community safe. You can also report products directly to us.
						</Typography>

						{/* In Closing */}
						<br />
						<Typography tag="h2" weight="semibold">
							In Closing
						</Typography>
						<Typography tag="p" weight="light">
							At Seesaw Marketplace, we are dedicated to earning and keeping your trust as you use our platform and participate in our community. Our goal is to make it easy for you to understand your privacy choices and we are fully committed to providing the data privacy and security that you expect. As we strive to maintain the highest standards of security and transparency, we welcome your feedback, comments, and questions at
							{' '}
							<LocaleLink to="mailto:hello@seesawmarketplace.com?Subject=Security Question">hello@seesawmarketplace.com</LocaleLink>
							.
						</Typography>
					</S.Wrapper>
				</LegalNavigation>
			</MarketingNavigation>
		</>
	);
};


/**
 * Configuration
 */

SecurityPromise.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	merchant: PropTypes.shape(),
};
SecurityPromise.defaultProps = {
	meta: {},
	locale: {},
	merchant: {}
};


/**
 * Exports
 */

export default SecurityPromise;
