/*
 * File: PersonalDetails.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 11:39 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import validator from 'validator';

// Slices
import { updateCheckout } from '../../../../store/slices/checkout/checkout.slice';

// Components
import {
	TextInput, PageHeader, Typography
} from '../../../../components';

// Styles
import * as S from './PersonalDetails.styles';


/**
 * Component
 */

export const PersonalDetails = forwardRef(({
	className, updateStep, updateHeight, isVisible, merchant
}, ref) => {

	// Get dispatch
	const dispatch = useDispatch();

	// Get current check out flow
	const currentFlow = useSelector((state) => state.checkout.value);

	// Get state handlers
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [companyError, setCompanyError] = useState(null);
	const [inputValues, setInputValues] = useState({});

	// Handle next action
	const handleNext = () => {

		// Get parameters
		const { name, email, company } = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email' });
			return;
		}
		if (!company || validator.isEmpty(company, { ignore_whitespace: true })) {
			setCompanyError({ message: 'Please enter your company name' });
			return;
		}

		// Update checkout flow
		dispatch(updateCheckout({
			currentStep: 2,
			data: {
				...currentFlow.data,
				name,
				email,
				company
			}
		}));

		// Move to next step
		updateStep(2);
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value
		} = event.target;
		setInputValues({ ...inputValues, [name]: value });
	};

	// Handle update on flow change
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [currentFlow]);

	// Handle update on visibility change
	useEffect(() => {

		// Update height
		updateHeight(true);

		// If component is visible, update input values
		if (isVisible === true) {
			setInputValues({
				name: currentFlow.data.name,
				email: currentFlow.data.email,
				company: currentFlow.data.company
			});
		}
	}, [isVisible]);

	// Render component
	return (
		<S.Wrapper ref={ref}>
			<div className="stepSpacing">

				{/* Header */}
				<PageHeader
					title="Check Out"
					subtitle={merchant.name}
					isSmall
				/>

				{/* Step Container */}
				<S.StepContainer className={className}>

					{/* Inputs */}
					<TextInput
						label="Your name"
						placeholder="e.g. Beyoncé Knowles"
						name="name"
						type="text"
						error={nameError}
						value={inputValues.name || ''}
						containerClassName="formInput"
						onFocus={() => { setNameError(null); }}
						onKeyUp={() => { setNameError(null); }}
						onBlur={() => { setNameError(null); }}
						onChange={handleOnChange}
						autocomplete="off"
						autocorrect="off"
					/>
					<TextInput
						label="Your email"
						name="email"
						type="email"
						placeholder="e.g. hello@acme.com"
						error={emailError}
						value={inputValues.email || ''}
						containerClassName="formInput"
						onFocus={() => { setEmailError(null); }}
						onKeyUp={() => { setEmailError(null); }}
						onBlur={() => { setEmailError(null); }}
						onChange={handleOnChange}
						autocorrect="off"
						autocapitalize="off"
						spellcheck="false"
					/>
					<TextInput
						label="Your company"
						placeholder="e.g. ACME Inc"
						name="company"
						type="text"
						error={companyError}
						value={inputValues.company || ''}
						containerClassName="formInput"
						onFocus={() => { setCompanyError(null); }}
						onKeyUp={() => { setCompanyError(null); }}
						onBlur={() => { setCompanyError(null); }}
						onChange={handleOnChange}
						autocomplete="off"
						autocorrect="off"
					/>

					{/* Actions */}
					<S.Button size="large" onClick={handleNext} merchant={merchant}>
						<Typography variation="button-small" weight="medium">CONTINUE TO SHIPPING</Typography>
					</S.Button>
				</S.StepContainer>
			</div>
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

PersonalDetails.propTypes = {
	className: PropTypes.string,
	updateStep: PropTypes.func.isRequired,
	updateHeight: PropTypes.func,
	isVisible: PropTypes.bool,
	merchant: PropTypes.shape()
};
PersonalDetails.defaultProps = {
	className: null,
	updateHeight: null,
	isVisible: false,
	merchant: {}
};
