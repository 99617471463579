/*
 * File: Confirmation.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 24, 2022 at 2:50 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';

// Styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './Confirmation.styles';
import { Emoji, Typography } from '../../../../components';


/**
 * Component
 */

export const Confirmation = forwardRef(({
	className, updateHeight, isVisible, merchant
}, ref) => {

	// Use hooks
	const navigate = useNavigate();

	// Get current locale from hook
	const locale = useSelector((state) => state.locale.value);

	// Initialize window parameters
	const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 500;
	const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 500;

	// Set state
	const [width, setWidth] = useState(windowWidth);
	const [height, setHeight] = useState(windowHeight);

	// Handle next action
	const handleNext = () => {

		// Move to home
		navigate(`${locale.localePath}/`);
	};

	// Handle actions on window resize
	useWindowResize(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	});

	// Handle update on flow change
	useEffect(() => {

		// Update height
		updateHeight(true);

	}, [isVisible]);

	// Render component
	return (
		<S.Wrapper ref={ref}>
			<div className="stepSpacing">

				{/* Step Container */}
				<S.StepContainer className={className}>

					{/* Confirmation Content */}
					<S.ConfirmationContent>

						{/* Content */}
						<Emoji symbol="🎉" label="party" size={3.7} />
						<Typography tag="h2" weight="bold">
							Thank you!
						</Typography>
						<Typography tag="h4" weight="semibold">
							We&apos;ve received your order and sent you a confirmation email. Once payment is received, we will ship your
							{' '}
							{merchant.name}
							{' '}
							products right to your door!
						</Typography>
						<Typography tag="p" variation="1" weight="light">
							{merchant.productPitch}
						</Typography>
						<Typography tag="p" variation="2" weight="light">
							<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
							{' '}
							{`${merchant.founder.firstName} and the ${merchant.name} team`}
						</Typography>

						{/* Actions */}
						<S.Button size="large" onClick={handleNext} merchant={merchant}>
							<Typography variation="button-small" weight="medium">BACK TO HOME</Typography>
						</S.Button>
					</S.ConfirmationContent>

					{/* Confetti */}
					{isVisible && (
						<Confetti
							style={{
								zIndex: 9999999,
								position: 'fixed',
								width: '100%',
								height: '100%'
							}}
							width={width}
							height={height}
							gravity={0.05}
						/>
					)}
				</S.StepContainer>
			</div>
		</S.Wrapper>
	);
});


/**
 * Configuration
 */

Confirmation.propTypes = {
	className: PropTypes.string,
	updateHeight: PropTypes.func,
	isVisible: PropTypes.bool,
	merchant: PropTypes.shape()
};
Confirmation.defaultProps = {
	className: null,
	isVisible: false,
	updateHeight: null,
	merchant: {}
};
