/*
 * File: CartSidebar.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 4:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { LocaleLink as BaseLink } from '../../LocaleLink';
import { IconButton } from '../../IconButton';

// Constants
import { AppNavCartWidth, AppNavTopbarHeight } from '../../../styles/constants';


/**
 * Styles
 */

export const Sidebar = styled.div`
	width: 98%;
	max-width: ${AppNavCartWidth}px;
	height: 100%;
	background-color: ${({ theme }) => theme.primaryBackground};
	border-left: 1px solid ${({ theme }) => theme.layoutBorder};
	padding: 15px 30px 60px 30px;
	overflow: auto;
	position: relative;
	z-index: 2;

	& > div {
		flex-direction: column;
	}
`;

export const SidebarSection = styled.div`
	padding: 20px 0;
	width: 100%;

	${({ showBorder }) => showBorder !== false && css`
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
 	`}

	* {
		text-align: left;
	}

	h5,
	.secondary {
		color: ${({ theme }) => theme.secondaryText};
	}

	button.minimal {
		margin: 15px 0px 0px;
		padding: 0px;
		font-weight: 600;
		font-size: 12px;
	}

	button.full {
		margin: 15px 0px 0px;
		width: 100%;
	}
`;

export const MobileNavigation = styled.div`
	margin-top: -15px;
	height: ${AppNavTopbarHeight}px;
	display: flex;
	align-items: center;
`;

export const NavigationToggle = styled(IconButton)`
	pointer-events: all;
`;

export const Link = styled(BaseLink)`
	text-decoration: none;
	margin: 0px 10px 8px 0px;

	&:hover {
		text-decoration: underline;
	}
`;

export const LinkSection = styled.div`
	margin: 0px 0px 12px;
	display: flex;
	flex-wrap: wrap;

	* {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.72rem;
	}
	svg {
		font-size: 0.72rem;
		position: relative;
	}

	.fullWidth {
		margin: 0px 0px 3px 0px;
		width: 100%;
	}
`;
