/*
 * File: ProductImage.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 9:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled, { css } from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
    width: 100%;
	padding-top: ${({ ratio }) => `${ratio}%`};
	position: relative;
	display: block;
	overflow: hidden;
`;

export const Image = styled.div`
	display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	background-color: ${({ theme }) => theme.primaryBackground};
	${({ image }) => image != null && css`
		background-image: url("${image}");
		background-position: center;
		background-size: cover;
 	`}

	&:hover,
	&:focus {
		transform: scale(1.05);
	}
`;
