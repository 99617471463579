/*
 * File: ProductImage.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 9:28 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import * as S from './ProductImage.styles';


/**
 * Component
 */

export const ProductImage = ({ image, ratio, className }) => (
	<S.Wrapper ratio={ratio * 100} className={className}>
		<S.Image image={image} className="animate" />
	</S.Wrapper>
);


/**
 * Configuration
 */

ProductImage.displayName = 'ProductRow';
ProductImage.propTypes = {
	image: PropTypes.string,
	ratio: PropTypes.number,
	className: PropTypes.string
};
ProductImage.defaultProps = {
	image: null,
	ratio: 0.75,
	className: null
};
