/*
 * File: Routes.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 1:03 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: February 22, 2023 at 9:31 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { matchPath } from 'react-router-dom';

// Utilities
import { createLocaleVariants } from '../utilities/locale';

// Slice
import { initialState as initialLocale } from '../store/slices/locale/locale.slice';

// Navigation
import { PublicRouteWrapper } from '../navigation/PublicRouteWrapper';

// Components
import { Cart } from './pages/Cart';
import { Checkout } from './pages/Checkout';
import { Collections } from './pages/Collections';
import { Error } from './pages/Error';
import { ProductDetail } from './pages/ProductDetail';
import { CollectionDetail } from './pages/CollectionDetail';
import { FollowUp } from './pages/FollowUp';
import { Home } from './pages/Home';
import { Welcome } from './pages/Welcome';
import { CookiePolicy } from './pages/Legal/CookiePolicy';
import { Overview as LegalOverview } from './pages/Legal/Overview';
import { IntellectualPropertyPolicy } from './pages/Legal/IntellectualPropertyPolicy';
import { PrivacyPolicy } from './pages/Legal/PrivacyPolicy';
import { SecurityPromise } from './pages/Legal/SecurityPromise';
import { Terms } from './pages/Legal/Terms';
import { ContactPreferences } from './pages/ContactPreferences';
import { Contact } from './pages/Contact';


/**
 * Helpers
 */

const filterProps = (path, props) => {

	// If data is undefined, return props
	if (!props || !props.data) return props;

	// Search for route path
	const routes = Object.keys(props.data).filter((key) => key.startsWith('/'));
	const matchRoute = routes.find((route) => matchPath(path, route));

	// Check for exact match
	let exactMatch = true;
	if (typeof window !== 'undefined') {
		const fullPath = (props?.locale?.url?.fullPath !== '' ? props?.locale?.url?.fullPath?.replace(/\/$/, '') : props?.locale?.url?.fullPath) || '';
		let basePath = fullPath.startsWith(props?.locale?.localePath) ? fullPath.replace(props?.locale?.localePath, '') : fullPath;
		if (basePath === '') basePath = '/';
		exactMatch = fullPath === window.location.pathname || `${fullPath}/` === window.location.pathname || basePath === window.location.pathname;
	}

	// Return data
	return (props.data ? {
		...props,
		data: {
			...props?.data.global,
			...matchRoute && exactMatch ? props?.data[matchRoute] : undefined
		}
	} : props);
};

const prepareComponent = (routeObj) => (Array.isArray(routeObj) ? routeObj.map((route) => ({
	...route,
	component: route.component(route.path)
})) : {
	...routeObj,
	component: routeObj.component(routeObj.path)
});


/**
 * Routes
 */

export const Routes = (props) => {

	// Get current locale from props
	const localeObj = props?.locale?.localePath != null ? props?.locale : initialLocale.value;

	// Get current meta from props
	const metaObj = props?.meta;

	// Get current merchant from props
	const merchantObj = props?.merchant;
	const isCore = merchantObj?.isCore === true;

	// Render routes
	return [].concat(...[

		/**
		 * Product Pages
		 */

		isCore ? prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Home
							{...filterProps(path, props)}
							meta={{
								title: 'Sell Your Wholesale Products In Person | Seesaw Marketplace',
								description: 'Seesaw Marketplace is the easiest way to take in-person wholesale orders from your customers at markets, trade shows, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})) : prepareComponent(createLocaleVariants({
			path: '/',
			exact: true,
			shouldIndex: true,
			priority: '1.00',
			functions: [
				{ function: 'product/getGroups', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Collections
							{...filterProps(path, props)}
							meta={{
								title: 'Order Wholesale from {{merchant.name}}',
								description: '{{merchant.description}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		!isCore && prepareComponent(createLocaleVariants({
			path: '/product/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getProduct', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<ProductDetail
							{...filterProps(path, props)}
							meta={{
								title: '{{product.name}} - {{merchant.name}}',
								description: '{{product.description}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		!isCore && prepareComponent(createLocaleVariants({
			path: '/collection/:slug',
			exact: true,
			shouldIndex: true,
			isDynamic: true,
			priority: '0.8',
			functions: [
				{ function: 'product/getGroup', restrictToPath: true },
			],
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<CollectionDetail
							{...filterProps(path, props)}
							meta={{
								title: '{{group.name}} - {{merchant.name}}',
								description: '{{group.description}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Cart Pages
		 */

		!isCore && prepareComponent(createLocaleVariants({
			path: '/cart',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Cart
							{...filterProps(path, props)}
							meta={{
								title: 'Your Shopping Cart - {{merchant.name}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Checkout Pages
		 */

		!isCore && prepareComponent(createLocaleVariants({
			path: '/checkout',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Checkout
							{...filterProps(path, props)}
							meta={{
								title: 'Checkout - {{merchant.name}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * General Pages
		 */

		isCore && prepareComponent(createLocaleVariants({
			path: '/contact',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<Contact
							{...filterProps(path, props)}
							meta={{
								title: 'Contact Us - Seesaw Marketplace',
								description: 'One of our team members will follow up shortly. In the meantime, check out our website for other helpful topics.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/contact-preferences/:token',
			exact: true,
			shouldIndex: false,
			component(path) {
				return (
					<PublicRouteWrapper>
						<ContactPreferences
							{...filterProps(path, props)}
							meta={{
								title: 'Update Preferences - {{merchant.name}}',
								description: 'Update your {{merchant.name}} contact preferences.',
								bodyClasses: 'hide-captcha',
								showSocial: false,
								shouldIndex: false,
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Legal Pages
		 */

		isCore && prepareComponent(createLocaleVariants({
			path: '/legal',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<LegalOverview
							{...filterProps(path, props)}
							meta={{
								title: 'Legal - Seesaw Marketplace',
								description: 'Seesaw Marketplace\'s Terms and Policies, including security guidelines, Terms of Service, cookie policies, privacy, and more.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		prepareComponent(createLocaleVariants({
			path: '/legal/cookie-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<CookiePolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Cookie Policy - {{merchant.name}}',
								description: 'Our Cookie Policy explains what these technologies are and why we use them, as well as your rights to control our use of them.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		isCore && prepareComponent(createLocaleVariants({
			path: '/legal/copyright-policy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<IntellectualPropertyPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Intellectual Property Policy - Seesaw Marketplace',
								description: 'Seesaw Marketplace respects the intellectual property rights of others, and we expect you to do the same. We do not allow posting, sharing, or sending any content that violates or infringes someone else\'s intellectual property rights.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		isCore && prepareComponent(createLocaleVariants({
			path: '/legal/privacy',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<PrivacyPolicy
							{...filterProps(path, props)}
							meta={{
								title: 'Privacy Policy - Seesaw Marketplace',
								description: 'We care about your privacy. Our privacy policy will help you understand what information is collected, how it\'s used, and what choices you have.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		isCore && prepareComponent(createLocaleVariants({
			path: '/legal/security',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<SecurityPromise
							{...filterProps(path, props)}
							meta={{
								title: 'Security Promise - Seesaw Marketplace',
								description: 'At Seesaw Marketplace, privacy and data security are important to us, and both are paramount to the safety and security of our users and visitors. So is transparency.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		isCore && prepareComponent(createLocaleVariants({
			path: '/legal/terms',
			exact: true,
			shouldIndex: true,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper>
						<Terms
							{...filterProps(path, props)}
							meta={{
								title: 'Terms of Service - Seesaw Marketplace',
								description: 'Our Terms of Service detail and clarify your rights and responsibilities when using Seesaw Marketplace.',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Admin Pages
		 */

		!isCore && prepareComponent(createLocaleVariants({
			path: '/followup',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<FollowUp
							{...filterProps(path, props)}
							meta={{
								title: 'Follow Up - {{merchant.name}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),
		isCore && prepareComponent(createLocaleVariants({
			path: '/welcome',
			exact: true,
			shouldIndex: false,
			priority: '0.8',
			component(path) {
				return (
					<PublicRouteWrapper locale={localeObj}>
						<Welcome
							{...filterProps(path, props)}
							meta={{
								title: 'Welcome to {{merchant.name}}',
								bodyClasses: 'hide-captcha',
								...metaObj
							}}
						/>
					</PublicRouteWrapper>
				);
			}
		})),


		/**
		 * Error
		 */

		prepareComponent({
			path: '*',
			shouldIndex: false,
			isError: true,
			code: 404,
			component(path) {
				return (
					<Error
						code={404}
						{...filterProps(path, props)}
						locale={localeObj}
						meta={{
							title: 'Page Not Found - {{merchant.name}}',
							description: 'Whoops! We can\'t seem to find the page you\'re looking for.',
							bodyClasses: 'hide-captcha',
							showSocial: false,
							shouldIndex: false,
							...metaObj
						}}
					/>
				);
			}
		})
	].filter(Boolean));
};
