/*
 * File: EmptyComponent.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 22, 2022 at 10:36 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 4:04 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// Components
import { Typography } from '../Typography';

// Styles
import * as S from './EmptyComponent.styles';


/**
 * Component
 */

export const EmptyComponent = ({ title, message, icon }) => (
	<S.Wrapper>
		<FontAwesomeIcon className="empty-icon" icon={icon} size="5x" />
		<Typography tag="h4" weight="semibold" center>
			{title}
		</Typography>
		<Typography tag="p" className="description" center>
			{message}
		</Typography>
	</S.Wrapper>
);


/**
 * Configuration
 */

EmptyComponent.displayName = 'EmptyComponent';
EmptyComponent.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	icon: PropTypes.arrayOf(PropTypes.string),
};
EmptyComponent.defaultProps = {
	icon: ['fa', 'hashtag']
};
