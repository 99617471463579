/*
 * File: SuggestedProducts.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 3, 2022 at 12:11 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 25, 2022 at 11:23 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Services
import { getSuggestedProducts } from '../../services/product';

// Components
import { Typography } from '../Typography';
import { ProductCard } from '../ProductCard';

// Styles
import * as S from './SuggestedProducts.styles';


/**
 * Component
 */

export const SuggestedProducts = ({ product }) => {

	// Get state handlers
	const [componentStatus, setComponentStatus] = useState('idle');
	const [products, setProducts] = useState([]);

	// Create references for elements
	const isMounted = useRef(true);

	// Get current merchant from hook
	const merchant = useSelector((state) => state.merchant.value);

	// Initialize data
	const initializeData = async () => {

		// Update component status
		setComponentStatus('loading');

		// Initialize component data
		try {
			const { data: productData } = await getSuggestedProducts({
				...!product.isGroup ? {
					productId: product.id
				} : {
					groupId: product.id
				}
			}, merchant);

			// Update UI if mounted
			if (isMounted.current) {

				// Set component data
				setProducts(productData.products);

				// Update component status
				setComponentStatus('success');
			}
		} catch (e) {

			// Update component status
			setComponentStatus('error');
		}
	};

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Ensure initial component loading is not complete
		if (componentStatus === 'idle' && product?.id != null) {

			// Initialize data
			initializeData();
		}

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, [product]);

	// Render component
	return (
		<S.Wrapper>
			<S.CollectionDescription>
				<Typography tag="h2" weight="semibold">
					You may also like
				</Typography>
			</S.CollectionDescription>
			<S.ProductContainer>
				{products.map((productObj) => <ProductCard product={productObj} key={productObj.id} merchant={merchant} showMarkup={false} />)}
			</S.ProductContainer>
		</S.Wrapper>
	);
};


/**
 * Configuration
 */

SuggestedProducts.displayName = 'SuggestedProducts';
SuggestedProducts.propTypes = {
	product: PropTypes.shape()
};
SuggestedProducts.defaultProps = {
	product: null
};
