/*
 * File: Topbar.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 25, 2022 at 10:47 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { getCDNURL } from '../../../../utilities/utilities';

// Components
import { Padding } from '../../Padding';
import { LocaleLink } from '../../LocaleLink';

// Styles
import * as S from './Topbar.styles';


/**
 * Component
 */

export const Topbar = ({
	toggleNavigation, toggleCart, isLarge, showCart, merchant
}) => {

	// Get CDN URL
	const CDNURL = getCDNURL(merchant);

	// Render component
	return (
		<S.Topbar merchant={merchant}>
			<Padding left right isLarge={isLarge}>
				<S.TopbarWrapper>
					<S.NavigationToggle icon={['fa', 'bars']} size={1.25} onClick={() => { toggleNavigation(); }} />
				</S.TopbarWrapper>
				<S.TopbarWrapper fullWidth>
					<LocaleLink to="/">
						<S.LogoImage alt={`${merchant.name} Logo`} title={`${merchant.name} Logo`} src={merchant.logo.startsWith('http') ? merchant.logo : `${CDNURL}/public/assets/logos/${merchant.logo}`} />
					</LocaleLink>
				</S.TopbarWrapper>
				<S.TopbarWrapper>
					{showCart && <S.NavigationToggle icon={['fa', 'bag-shopping']} size={1.25} onClick={() => { toggleCart(); }} />}
				</S.TopbarWrapper>
			</Padding>
		</S.Topbar>
	);
};


/**
 * Configuration
 */

Topbar.displayName = 'Topbar';
Topbar.propTypes = {
	toggleNavigation: PropTypes.func,
	toggleCart: PropTypes.func,
	isLarge: PropTypes.bool,
	showCart: PropTypes.bool,
	merchant: PropTypes.shape()
};
Topbar.defaultProps = {
	toggleNavigation: null,
	toggleCart: null,
	isLarge: false,
	showCart: true,
	merchant: {}
};
