/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on December 27, 2022 at 12:40 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: December 27, 2022 at 12:40 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Users Services
 */

export const getCurrentUser = (data) => api.get('/users/current', data);

export const updateNotificationPreferences = ({ preferences, token }) => api.post('/user/account/notifications', { preferences, token });

export const validateGeneralAccessAttempt = ({ token }) => api.get('/user/account/access', { params: { token } });
