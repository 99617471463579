/*
 * File: ProductCard.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 29, 2022 at 10:47 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 9:36 PM
 * Modified By: Brendan Michaelsen
 */

// Modules
import styled from 'styled-components';

// Components
import { Button as BaseButton } from '../Button';


/**
 * Styles
 */

export const Wrapper = styled.div`
	display: block;
	background-color: ${({ theme }) => theme.cardBackground};
	border-radius: 6px;
	overflow: hidden;

	a,
	a:link,
	a:visited {
		text-decoration: none;
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
	}
	h4 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ImageContainer = styled.div`
	width: 100%;
`;

export const DetailContainer = styled.div`
	padding: 20px 20px 20px;
	flex-grow: 1;
`;

export const ButtonContainer = styled.div`
	padding: 0px 20px 20px;
`;

export const Button = styled(BaseButton)`
	width: 100%;
	height: 40px;
	margin: 0px 0px 0px;
`;
