/*
 * File: CartSidebar.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 11:48 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Padding } from '../../Padding';
import { Cart } from '../../Cart';

// Styles
import * as S from './CartSidebar.styles';


/**
 * Component
 */

export const CartSidebar = ({
	toggleOverlay, merchant, className
}) => {

	// Get current cart from hook
	const cart = useSelector((state) => state.cart.value);

	// Render component
	return (
		<S.Sidebar className={className}>
			<Padding>

				{/* Mobile Navigation */}
				<S.MobileNavigation>
					<S.NavigationToggle icon={['fa', 'times']} size={1.35} onClick={() => { toggleOverlay(); }} />
				</S.MobileNavigation>

				{/* Cart */}
				<Cart data={{ cart }} isSmall merchant={merchant} emptyAction={() => { toggleOverlay(); }} />

			</Padding>
		</S.Sidebar>
	);
};


/**
 * Configuration
 */

CartSidebar.displayName = 'CartSidebar';
CartSidebar.propTypes = {
	className: PropTypes.string,
	merchant: PropTypes.shape(),
	toggleOverlay: PropTypes.func
};
CartSidebar.defaultProps = {
	className: null,
	merchant: {},
	toggleOverlay: null
};
