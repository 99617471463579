/*
 * File: index.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on July 22, 2022 at 1:44 AM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 22, 2022 at 11:35 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const submitInquiry = (data, merchant) => api(merchant).post('/inquiry', data);
