/*
 * File: Cart.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 16, 2022 at 10:38 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	margin: 30px 0 0;
`;
