/*
 * File: Confirmation.styles.js
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on June 20, 2022 at 12:32 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: July 20, 2022 at 3:58 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button as BaseButton
} from '../../../../components';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
`;

export const StepContainer = styled.div`
	button {
		width: 100%;
	}
`;

export const ConfirmationContent = styled.div`
	padding: 40px 0 0 0;
	text-align: center;

	h2 {
		margin: 10px 0 0;
	}
	h4 {
		font-size: 1.2rem !important;
	}
	p {
		font-size: 1.1rem !important;
	}
	h4,
	h5,
	p {
		margin: 20px 0 25px;
	}
`;

export const Button = styled(BaseButton)`
	margin: 30px auto 0;
	width: auto !important;
`;
