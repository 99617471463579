/*
 * File: Sidebar.jsx
 * Project: seesaw-marketplace-app
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:51 PM
 * Copyright © 2022 Seesaw Technologies, LLC. All rights reserved.
 *
 * Last Modified: January 28, 2023 at 12:29 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Padding } from '../../Padding';
import { Typography } from '../../Typography';

// Styles
import * as S from './Sidebar.styles';


/**
 * Component
 */

export const Sidebar = ({
	toggleOverlay, className, merchant
}) => (
	<S.Sidebar className={className}>
		<Padding>

			{/* Mobile Navigation */}
			<S.MobileNavigation>
				<S.NavigationToggle icon={['fa', 'times']} size={1.35} onClick={() => { toggleOverlay(); }} />
			</S.MobileNavigation>

			{/* Primary Navigation */}
			<S.SidebarSection showBorder>
				{merchant.navigation && merchant.navigation.primary.map((navigation) => (
					<S.PrimaryLink
						to={navigation.url}
						key={navigation.url}
						onClick={() => {
							if (navigation.action) {
								if (navigation.action === 'close') {
									toggleOverlay();
								}
							}
						}}
					>
						<Typography tag="h5" weight="semibold">{navigation.title}</Typography>
					</S.PrimaryLink>
				))}
			</S.SidebarSection>

			{/* Navigation */}
			<S.SidebarSection showBorder={false}>
				{merchant.navigation && merchant.navigation.secondary.map((section) => (
					<S.LinkSection key={section.id}>
						{section.items.map((navigation) => (
							<S.Link
								to={navigation.url}
								key={navigation.url}
								onClick={() => {
									if (navigation.action) {
										if (navigation.action === 'close') {
											toggleOverlay();
										}
									}
								}}
							>
								{navigation.title}
							</S.Link>
						))}
						<S.Link to="/legal/cookie-policy">Cookie Policy</S.Link>
					</S.LinkSection>
				))}
				<S.LinkSection>
					<Typography tag="p" className="fullWidth">
						Made with
						{' '}
						<FontAwesomeIcon icon={['fa', 'heart']} className="heart" />
						{' '}
						in
						{' '}
						{merchant.address?.city}
						,
						{' '}
						{merchant.address?.state}
					</Typography>
					<Typography tag="p" className="fullWidth">
						{`© ${new Date().getFullYear()} ${merchant.name}`}
					</Typography>
				</S.LinkSection>
			</S.SidebarSection>
		</Padding>
	</S.Sidebar>
);


/**
 * Configuration
 */

Sidebar.displayName = 'Sidebar';
Sidebar.propTypes = {
	className: PropTypes.string,
	toggleOverlay: PropTypes.func,
	merchant: PropTypes.shape()
};
Sidebar.defaultProps = {
	className: null,
	toggleOverlay: null,
	merchant: {}
};
